import { jsonApi } from './jsonApi';

jsonApi.define('site', {
  name: '',
  creation_date: '',
  is_active: '',
  currency: '',
  timezone: '',
  password_expiry: '',
  obfuscation_ip: '',
  free_trial_days_left: '',
  permissions: {
    jsonApi: 'hasMany',
    type: 'permission',
  },
  siteParameters: {
    jsonApi: 'hasMany',
    type: 'site-parameter',
  },
  customers: {
    jsonApi: 'hasMany',
    type: 'customer',
  },
  billings: {},
});
