export const metaFn = async (routes = [], to = {}) => {
  const promises = [];
  for (const route of routes) {
    if (route?.meta?.metaFn) {
      promises.push(
        Promise.resolve(route.meta.metaFn(route, to)).then(result => {
          if (result) {
            Object.assign(route.meta, result);
          }
        })
      );
    }
  }
  await Promise.all(promises);
};
