<template>
  <div>
    <a :href="url">
      <button type="button" class="btn btn-block btn-ca">
        {{ $t('account.login.ssoReconnect') }}
      </button>
    </a>
  </div>
</template>

<script>
import { getV7Hostname } from '~version/models/headers-handler';
import urlJoin from 'url-join';

export default {
  props: {
    idp: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      url: urlJoin(getV7Hostname(), `/saml/${this.idp}`),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/variables.scss';
a:link {
  text-decoration: none;
}
p {
  white-space: nowrap;
}
</style>
