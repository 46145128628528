import { camelCase } from 'lodash';

export const getters = {
  templatesWithAdvancedStatuses(state) {
    return state.entities.map(e => {
      if (state.modelName === 'destination-template') {
        return { ...e, camelCaseStatus: 'published' };
      }

      // destination-builder-templates
      const [major, minor] = e.version.split('.');

      if (Number(major) === 0 && Number(minor) > 0) {
        return { ...e, camelCaseStatus: 'notPublished' };
      }

      if (Number(major) > 0 && Number(minor) > 0) {
        return { ...e, camelCaseStatus: 'unpublishedChanges' };
      }

      switch (e.deployment_status) {
        case 'pending':
        case 'in progress':
        case 'error':
          return { ...e, camelCaseStatus: camelCase(e.deployment_status) };
        case 'deployed':
          return { ...e, camelCaseStatus: 'published' };
        case 'not deployed':
        default:
          return { ...e, camelCaseStatus: 'notPublished' };
      }
    });
  },

  isLocked(state) {
    return ['pending', 'in progress'].includes(state.entity.deployment_status);
  },
};
