import { jsonApi } from '../jsonApi';

jsonApi.define('integrations/cleansing-transformation', {
  label: '',
  description: '',
  order: 0,
  operation: '',
  parameters: '',
  event_count: 0,
  status: '',
  is_offered: false,
  ref_id: null,
  created: '',
  updated: '',
  deleted: '',
  sources: {
    jsonApi: 'hasMany',
    type: 'integration',
  },
});
