import { store } from '@/store';

export const passwordExpiredGuard = async function(to, from, next) {
  if (to.name === 'current-user-account-settings') {
    return next();
  }
  if (store.state.currentUser.password_expired) {
    //Since the password may have been updated outside of Vue
    //We confirm that it's still expired
    await store.dispatch('currentUser/loadUser');
    if (store.state.currentUser.password_expired) {
      store.commit('layout/progressFinish');
      return next({
        name: 'current-user-account-settings',
        params: to.params,
      });
    }
  } else {
    next();
  }
};
