import { jsonApi } from '../jsonApi';

jsonApi.define('monitoring/live_stream', {
  type: '',
  statistics: {},
  stream: {
    jsonApi: 'hasOne',
    type: 'dms/stream',
  },
});
