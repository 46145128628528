import { jsonApi } from '../jsonApi';

jsonApi.define('delivery/health', {
  connector_type: '',
  total: [],
  success: {
    immediate: {
      total: 0,
      increase_rate: 0,
    },
    retry: {
      total: 0,
      increase_rate: 0,
    },
  },
  fail: {
    rejected: {
      total: 0,
      increase_rate: 0,
    },
    error: {
      total: 0,
      increase_rate: 0,
    },
  },
  filtered: {
    total: 0,
    no_consent: 0,
    incomplete: 0,
    input_filter: 0,
  },
  trends: {
    success: { dataset: null },
    fail: { dataset: null },
  },
});
