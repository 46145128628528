<template>
  <div class="iframe-integration vh-100">
    <div class="container-fluid h-100">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="d-flex flex-column justify-content-center">
          <p class="text-center splash-message">{{ errorI18n }}</p>
          <div class="d-flex justify-content-around">
            <a class="btn btn-lg btn-secondary mr-5" @click="backToOldPlatform">
              <fa icon="chevron-left" />
              {{ $t('common.backToOldPlatform') }}
            </a>
            <a href="https://www.commandersact.com/fr/contact/" target="_blank" class="btn btn-lg btn-primary">
              {{ $t('common.contactSales') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    error: {
      type: String,
      default() {
        return 'general';
      },
    },
  },
  computed: {
    errorI18n() {
      return this.$t(`errors.page.${this.error}`);
    },
  },
  methods: {
    backToOldPlatform() {
      const host = window.location.hostname.split('.');
      if (host[1] === 'local') {
        window.location.href = 'https://local.commandersact.com/v5/web/app_dev.php';
      } else {
        window.location.href = 'https://platform.commandersact.com';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.splash-title {
  font-size: 5rem;
  font-weight: 500;
}

.splash-message {
  font-size: 2rem;
  font-weight: 400;
}
</style>
