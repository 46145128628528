import { jsonApiVuex } from '@/common/store/json-api-vuex';

export const dmsModifier = jsonApiVuex(
  'dms/modifier',
  {
    getters: {
      oneRowPerItem(state) {
        return state.entities.find(m => m.name === '1 row per item');
      },
    },
  },
  {}
);
