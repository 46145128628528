import { jsonApiVuex } from '@/common/store/json-api-vuex';

export const eventPropertyName = jsonApiVuex(
  'normalized-datalayer/event-property-name',
  {
    state: {
      entitiesByName: {},
    },
    mutations: {
      setEntitiesByName(state, value) {
        state.entitiesByName = value;
      },
    },
    actions: {
      prefetch({ dispatch, commit }, params) {
        const cacheKey = JSON.stringify(params);
        commit('_invalidFindAllCache', cacheKey);
        return dispatch('findAllCached', params);
      },
      async getEventPropertyName({ state, dispatch }, name) {
        if (state.entitiesByName[name]) {
          return state.entitiesByName[name];
        }
        const categories = ['root', 'standard', 'datalayer'];
        const declaredProperties = await dispatch('getEventPropertiesByCategories', categories);
        return declaredProperties.find(property => property.name === name);
      },
      async getEventPropertiesByCategories({ dispatch }, categories) {
        // do not apply categories filter on prefetch
        const eventProperties = await dispatch('findAllCached');
        return eventProperties.filter(e => e.categories.some(cat => categories.includes(cat)));
      },
    },
  },
  {
    hooks: {
      findAll: {
        post({ commit }, result) {
          const entitiesByName = {};
          result.data.forEach(eventPropertyName => {
            entitiesByName[eventPropertyName.name] = eventPropertyName;
          });
          commit('setEntitiesByName', entitiesByName);
        },
      },
    },
  }
);
