import Vue from 'vue';
import Vuex from 'vuex';
import * as currentUser from './current-user';
import { user } from './user';
import { site } from './site';
import { customer } from './customer';
import * as notifications from './notifications';
import { browserLanguage } from './browser-language';
import { monitoringLiveStream } from '@/store/monitoring/live-stream';
import * as sourcesDataQuality from '@/store/monitoring/sources-data-quality';
import { monitoringTaskLog } from '@/store/monitoring/task-log';
import { amsDimension } from './ams/dimension';
import { amsRedirectRule } from './ams/redirect-rules';
import { dmsUniverse } from '@/store/dms/universe';
import { dmsVariable } from '@/store/dms/variable';
import { dmsVariableCategory } from '@/store/dms/variable-category';
import { dmsFlag } from '@/store/dms/flag';
import { dmsSegment } from '@/store/dms/segment';
import { dmsStream } from '@/store/dms/stream';
import { dmsModifier } from '@/store/dms/modifier';
import { dmsConnector } from '@/store/dms/connector';
import { dmsConnectorCredential } from '@/store/dms/connector-credential';
import { dmsConnectorCredentialFtp } from '@/store/dms/connector-credential-ftp';
import { dmsConnectorCredentialS3 } from '@/store/dms/connector-credential-s3';
import { dmsConnectorCredentialGoogleCloudStorages } from '@/store/dms/connector-credential-google-cloud-storages';
import { monitoringCollectionEvent } from './monitoring/collection/event';
import { eventConfiguration } from './normalized-datalayer/event-configuration';
import { eventType } from './normalized-datalayer/event-type';
import { standardEventType } from './normalized-datalayer/standard-event-type';
import { standardEventTypeCategory } from './normalized-datalayer/standard-event-type-category';
import { eventProperty } from './normalized-datalayer/event-property';
import { standardEventProperty } from './normalized-datalayer/standard-event-property';
import { eventPropertyName } from './normalized-datalayer/event-property-name';
import { eventTypeName } from './normalized-datalayer/event-type-name';
import { fuseFuseKey } from '@/store/fuse/fuse-key';
import { tasksRequest } from '@/store/task/request';
import { privacyCookieScanner } from '@/store/privacy/cookie-scanner/cookie-scanner';
import { privacyCookieScannerCookie } from '@/store/privacy/cookie-scanner/cookie';
import { privacyCookieScannerSettingCustomField } from '@/store/privacy/cookie-scanner/setting/custom-field';
import { privacyCookieScannerCustomField } from '@/store/privacy/cookie-scanner/custom-field';
import { privacyCategoryReference } from '@/store/privacy/category-reference';
import { privacyVendorReference } from '@/store/privacy/vendor-reference';
import { healthAlert } from '@/store/common/alert/alert';
import { healthNotification } from '@/store/common/alert/notification';
import { integrationCategory } from '@/store/integration/integration-category';
import { destinationCategory } from '@/store/integration/destination-category';
import { destinationBuilderCategory } from '@/store/integration/destination-builder-category';
import { integrationRequest } from '@/store/integration/integration-request';
import { integrationEnvironment } from '@/store/integration/integration-environment';
import { integrationTemplate } from '@/store/integration/integration-template';
import { destinationTemplate } from '@/store/integration/destination-template';
import { storageSettings } from '@/store/data-governance/storage-settings';
import { destinationBuilderTemplate } from '@/store/integration/destination-builder-template';
import * as destinationTestSandbox from '@/store/integration/destination-test-sandbox';
import { integration } from '@/store/integration/integration';
import { integrationCleansingTransformation } from '@/store/integration/cleansing-transformation';
import { tmsWebContainer } from '@/store/tms/web-container';
import { eventEnrichment } from '@/store/enrichment/event';
import { integrationGoogleAdsApiUserList } from '@/store/integration/google-ads/user-list';
import { integrationAmazonAdsApiProfiles } from '@/store/integration/amazon-ads/profiles';
import { integrationAmazonAdsConversionDefinitions } from '@/store/integration/amazon-ads/conversion-definitions';
import { integrationLinkedinAdsConversionRules } from '@/store/integration/linkedin-ads/conversion-rules';

import createPersistedState from 'vuex-persistedstate';
import * as environment from './environment';
import * as contributive from './ams/contributive-analysis';
import modules from './modules';
import builtIn from './built-in';
import { eventBus } from 'shared/event-bus';
import { getModuleList } from '@/common/store/json-api-vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    ...modules,
    ...builtIn,
    currentUser,
    user,
    site,
    customer,
    notifications,
    browserLanguage,
    contributive,
    integrationCategory,
    destinationCategory,
    destinationBuilderCategory,
    integration: {
      namespaced: true,
      modules: {
        integration,
        cleansingTransformation: integrationCleansingTransformation,
        request: integrationRequest,
        environment: integrationEnvironment,
        template: integrationTemplate,
        googleAdsApiUserList: integrationGoogleAdsApiUserList,
        amazonAdsApiProfiles: integrationAmazonAdsApiProfiles,
        amazonAdsConversionDefinitions: integrationAmazonAdsConversionDefinitions,
        linkedinAdsConversionRules: integrationLinkedinAdsConversionRules,
      },
    },
    enrichment: {
      namespaced: true,
      modules: {
        eventEnrichment: eventEnrichment,
      },
    },
    ams: {
      namespaced: true,
      modules: {
        contributive: contributive,
        dimension: amsDimension,
        redirectRule: amsRedirectRule,
      },
    },
    dms: {
      namespaced: true,
      modules: {
        universe: dmsUniverse,
        variable: dmsVariable,
        variableCategory: dmsVariableCategory,
        flag: dmsFlag,
        segment: dmsSegment,
        stream: dmsStream,
        connector: dmsConnector,
        connectorCredential: dmsConnectorCredential,
        connectorCredentialFtp: dmsConnectorCredentialFtp,
        connectorCredentialS3: dmsConnectorCredentialS3,
        connectorCredentialGoogleCloudStorages: dmsConnectorCredentialGoogleCloudStorages,
        modifier: dmsModifier,
      },
    },
    destinationTemplate,
    destinationBuilderTemplate,
    destinationTestSandbox,
    dataGovernance: {
      namespaced: true,
      modules: {
        storageSettings,
      },
    },
    normalizedDatalayer: {
      namespaced: true,
      modules: {
        eventConfiguration,
        eventType: eventType,
        standardEventType: standardEventType,
        standardEventTypeCategory: standardEventTypeCategory,
        eventProperty: eventProperty,
        eventPropertyName,
        eventTypeName,
        standardEventProperty: standardEventProperty,
      },
    },
    fuse: {
      namespaced: true,
      modules: {
        fuseKey: fuseFuseKey,
      },
    },
    tasks: {
      namespaced: true,
      modules: {
        request: tasksRequest,
      },
    },
    monitoring: {
      namespaced: true,
      modules: {
        liveStream: monitoringLiveStream,
        sourcesDataQuality,
        taskLog: monitoringTaskLog,
        collection: {
          namespaced: true,
          modules: {
            event: monitoringCollectionEvent,
          },
        },
      },
    },
    tms: {
      namespaced: true,
      modules: {
        webContainer: tmsWebContainer,
      },
    },
    privacy: {
      namespaced: true,
      modules: {
        categoryReference: privacyCategoryReference,
        vendorReference: privacyVendorReference,
        cookieScanner: {
          namespaced: true,
          modules: {
            cookieScanner: privacyCookieScanner,
            cookie: privacyCookieScannerCookie,
            customField: privacyCookieScannerCustomField,
            setting: {
              namespaced: true,
              modules: {
                customField: privacyCookieScannerSettingCustomField,
              },
            },
          },
        },
      },
    },
    health: {
      namespaced: true,
      modules: {
        alert: healthAlert,
        notification: healthNotification,
      },
    },
    environment,
  },
  plugins: [
    createPersistedState({
      paths: ['monitoring.taskLog.requestAll', 'monitoring.liveStream.local'],
    }),
  ],
});

eventBus.$on('site-id-changed', () => {
  const isMutationDefined = mutationName => {
    return Object.prototype.hasOwnProperty.call(store._mutations, mutationName);
  };

  const JsonApiModules = getModuleList();
  const otherModules = ['monitoring/sourcesDataQuality', 'enrichment/eventEnrichment'];
  const modulesToReset = [...JsonApiModules, otherModules];
  for (const module of modulesToReset) {
    if (isMutationDefined(`${module}/_reset`)) {
      store.commit(`${module}/_reset`);
    }
  }
});

export { store };
