import { store } from '@/store';
import { i18n } from '@/i18n';
import { get } from 'lodash';

const buildErrorMessageFromResponse = response => {
  let errorMessage;
  const errorType = get(response, 'data.errors[0].error_type');

  // Special case for Amazon CAPI: display only the error message instead of the complete error
  if (errorType === 'PERSONALIZED_PARTNER_ERROR') {
    const errorDetailParsed = JSON.parse(response.data.errors[0].detail);
    errorMessage = errorDetailParsed.message;
  } else {
    const detail = get(response, 'data.errors[0].detail') ?? i18n.t('errors.general');
    errorMessage = i18n.t('errors.apiError', { details: `${response.status} - ${detail}` });
  }

  return errorMessage;
};

export const globalErrorHandlerMixin = {
  errorCaptured(error) {
    console.error('Vue global error handler', error);
    //Error from devour client which were not handled
    let devourError;
    for (const detail of Object.values(error)) {
      if (detail.title === 'devour-http-error') {
        devourError = detail;
        break;
      }
    }
    if (devourError) {
      const { response, request, config } = {
        ...devourError.detail.payload,
        response: { ...(devourError.detail.response || { status: 0, data: devourError.detail.message }) },
      };
      switch (response.status) {
        case 422:
          store.commit('notifications/pushWarning', i18n.t('common.notifications.validationError'));
          console.warn('API Warning', request?.responseURL, config?.model, response?.status, response.data);
          break;
        default:
          if (response.data?.exception) {
            store.commit(
              'notifications/pushDanger',
              i18n.t('errors.apiError', { details: `${response.status} - ${response.data.message}` })
            );
            console.error(
              'API Exception',
              config?.method,
              request?.responseURL,
              config?.model,
              response.status,
              response.data.message,
              response.data.exception,
              response.data.file,
              response.data.trace
            );
            break;
          }
          if (get(response, 'data.errors[0].detail')) {
            //Might be some other exception...
            const errorMessage = buildErrorMessageFromResponse(response);
            store.commit('notifications/pushDanger', errorMessage);
            console.error(
              'API Error',
              config?.method,
              request?.responseURL,
              config?.model,
              response.status,
              response.data?.errors
            );
            break;
          }
          // Default behaviour = No idea about what we got
          store.commit(
            'notifications/pushDanger',
            i18n.t('errors.apiError', { details: `${response.status} - ${response.data}` })
          );
          console.error(
            'API Error',
            config?.method,
            request?.responseURL,
            config?.model,
            response.status,
            response.data
          );
      }
    }
  },
};
