import { jsonApi } from '../jsonApi';

jsonApi.define('dms/stream', {
  type: '',
  label: '',
  occurence_type: '',
  start_date: '',
  end_date: '',
  timezone: '',
  data_range_type: '',
  data_last: null,
  data_start_date: null,
  data_end_date: null,
  last_request_at: null,
  next_request_at: null,
  last_run_at: null,
  created: '',
  updated: '',
  cron: null,
  archived: false,
  deleted_at: null,
  parameters: {
    filename: '',
    include_headers: false,
    csv_separator: '',
    csv_variable_value_separator: '',
    done_extension: '',
    extension: '',
    tradelab_segment: '',
  },
  connector: {
    jsonApi: 'hasOne',
    type: 'dms/connector',
  },
});
