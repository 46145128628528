import serialize from 'devour-client/lib/middleware/json-api/_serialize';

export const handleDataRootRequestAttribute = (jsonApi, payload) => {
  const model = jsonApi.modelFor(payload.req.model);
  const rootAttribute = {};
  for (const [attribute, settings] of Object.entries(model.attributes)) {
    if (settings?._dataRootRequestAttribute) {
      let result = payload.req.data[attribute];
      if (Array.isArray(result)) {
        result = serialize.collection.call(jsonApi, settings.type, result).map(v => ({
          data: v,
        }));
      }
      rootAttribute[attribute] = result;
    }
  }
  return rootAttribute;
};
