import JsonApi from 'devour-client';
import { getJWT, setJWT, getApiHostname, getSiteId } from '~version/models/headers-handler';
import { getLocale } from '@/i18n';
import urlJoin from 'url-join';
import reqPost from '@/models/devour-middleware/req-post';
import reqPatch from '@/models/devour-middleware/req-patch';
import { eventBus } from 'shared/event-bus';

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

const jsonApi = new JsonApi({
  logger: process.env.NODE_ENV === 'development',
  apiUrl: '',
});

const tokenRefreshRequestMiddleware = {
  name: 'token-refresh-request',
  req(payload) {
    /**
     * Most of the time we want to call the api with the v2 prefix for the route
     * but in instances where we need the API without that, we can use an absolute
     * URL.
     */
    if (!isAbsoluteURLRegex.test(payload.req.url)) {
      const siteId = getSiteId() ? `/${getSiteId()}/` : '';
      payload.req.url = urlJoin(getApiHostname(), '/v2/', siteId, payload.req.url);
    }
    payload.req.headers['Accept-Language'] = getLocale();
    payload.req.headers.authorization = `Bearer ${getJWT()}`;
    return payload;
  },
};

const tokenRefreshResponseMiddleware = {
  name: 'token-refresh-response',
  res(payload) {
    setJWT(payload.res.headers['x-refreshed-token']);
    return payload;
  },
};

const progressStartMiddleware = {
  name: 'progress-start',
  req(payload) {
    eventBus.$emit('progress-start');
    return payload;
  },
};
const progressFinishMiddleware = id => ({
  name: `progress-finish${id}`,
  res(payload) {
    eventBus.$emit('progress-finish');
    return payload;
  },
});

jsonApi.insertMiddlewareBefore('HTTP_BASIC_AUTH', progressStartMiddleware);
jsonApi.insertMiddlewareBefore('axios-request', tokenRefreshRequestMiddleware);
jsonApi.insertMiddlewareBefore('response', tokenRefreshResponseMiddleware);
jsonApi.replaceMiddleware('POST', reqPost);
jsonApi.replaceMiddleware('PATCH', reqPatch);
jsonApi.insertMiddlewareAfter('response', progressFinishMiddleware(0));
jsonApi.insertMiddlewareBefore('errors', progressFinishMiddleware(1));

export { jsonApi };
