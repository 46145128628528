import { jsonApi } from '../jsonApi';

jsonApi.define('tasks/run', {
  id: '',
  attempt: '',
  status: '',
  detail: '',
  start: '',
  end: '',
});
