import { jsonApiVuex } from '@/common/store/json-api-vuex';

export const integrationEnvironment = jsonApiVuex(
  'integration-environment',
  {
    getters: {
      productionEnvironment(state) {
        return state.keyedById[1];
      },
    },
  },
  {
    moduleName: 'integration/environment',
  }
);
