<template>
  <button class="position-relative" :type="type" @click="$emit('click')" :disabled="disabled || loading">
    <b-spinner
      class="spinner position-absolute"
      small
      :variant="variant"
      :style="{ visibility: loading ? 'visible' : 'hidden' }"
    />
    <span :style="{ visibility: loading ? 'hidden' : 'visible' }">{{ label }} </span>
  </button>
</template>
<script>
/**
 * A button that get disabled and display a loading animation when its
 * props loading is truthy
 */

export default {
  props: {
    /**
     * Label of the button
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * If true, the button is disabled and a loader is displayed
     */
    loading: {
      type: Boolean,
      required: true,
    },
    /**
     * Bootsrap variant for the [spinne](https://bootstrap-vue.org/docs/components/spinner)
     */
    variant: {
      type: String,
      default: () => 'white',
    },
    /**
     * Disable the button
     */
    disabled: {
      type: Boolean,
      default: () => false,
    },
    /**
     * Type of the button
     */
    type: {
      type: String,
      default: () => 'button',
    },
  },
};
</script>
<style lang="scss" scoped>
.spinner {
  top: 50%;
  left: 50%;
  margin: -0.5rem 0 0 -0.5rem;
}
</style>
