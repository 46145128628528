import Main from '@/components/layouts/main';
import Transient from '@/components/layouts/transient';
import { setSiteId } from '@/versions/x/models/headers-handler';
import { store } from '@/store';

export const backOffice = {
  path: '/back-office',
  name: 'backOffice',
  component: Main,
  meta: {
    breadcrumb: 'Back office',
  },
  beforeEnter(to, from, next) {
    setSiteId(null);
    if (!store.state.currentUser.back_office_access_right) {
      next({ name: 'error', params: { error: 'unauthorizedAccess' }, replace: true });
    }
    next();
  },
  children: [
    {
      path: 'template-importer',
      name: 'back-office-template-importer',
      component: Transient,
      props: {},
      meta: {
        breadcrumb: 'Template importer',
      },
      children: [
        {
          path: '/',
          name: 'back-office-template-importer-overview',
          component: () =>
            import(
              /* webpackChunkName: "template-importer-overview" */ '@/views/integration/destination/builder-overview.vue'
            ),
        },
        {
          path: 'create',
          name: 'back-office-template-importer-create',
          component: () =>
            import(
              /* webpackChunkName: "template-importer-create" */ '@/views/back-office/template-importer/template-importer-create-edit.vue'
            ),
          props(route) {
            const props = { ...route.params };
            props.create = true;
            return props;
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('builtInDestinationBuilderTemplate/setCurrentEntity', {
              trigger_type: 'event',
            });
            next();
          },
          meta: {
            breadcrumb: 'create',
          },
        },
        {
          path: ':templateId/edit',
          name: 'back-office-template-importer-edit',
          component: () =>
            import(
              /* webpackChunkName: "template-importer-create" */ '@/views/back-office/template-importer/template-importer-create-edit.vue'
            ),
          async beforeEnter(to, from, next) {
            await store.dispatch('builtInDestinationBuilderTemplate/loadCurrent', to.params.templateId);
            next();
          },
          props(route) {
            const props = { ...route.params };
            props.create = false;
            return props;
          },
          meta: {
            breadcrumb: () => ({
              label: store.state.builtInDestinationBuilderTemplate.entity.label,
            }),
          },
        },
      ],
    },
  ],
};
