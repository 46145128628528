import { axiosApi } from '@/models';
import { every } from 'lodash';
import { getSiteId } from '~version/models/headers-handler';

let reloadTimeout; // if a reload has been requested, it will use this timeout to "wait other calls"
let lastReloadFilterIndex = -1; // prevent reloading same filters twice

const makeDefaultState = () => {
  return {
    isLoadingValidationStats: false,
    isLoadingEventNames: false,
    error: null,
    stats: null,
    range: null,
    environment: null,
    sources: [],
    eventNames: [],
    globalValidationSerie: [],
  };
};

const EVENT_NAMES_CACHE_KEY = 'data-quality-event-names';

const getEventNamesFromCache = () => {
  const siteId = getSiteId();
  const namesBySite = getAllEventNamesCache();

  return namesBySite[siteId] || [];
};

const setEventNamesCache = eventNames => {
  const siteId = getSiteId();
  const namesBySite = getAllEventNamesCache();

  namesBySite[siteId] = eventNames;

  localStorage.setItem(EVENT_NAMES_CACHE_KEY, JSON.stringify(namesBySite));
};

const getAllEventNamesCache = () => {
  try {
    return JSON.parse(localStorage.getItem(EVENT_NAMES_CACHE_KEY) || '{}');
  } catch (e) {
    return {};
  }
};

export const state = {
  ...makeDefaultState(),
  filterIndex: 0,
};
export const mutations = {
  setLoadingValidationStats(state, isLoading) {
    state.isLoadingValidationStats = isLoading;
  },
  setLoadingEventNames(state, isLoading) {
    state.isLoadingEventNames = isLoading;
  },
  setEventNames(state, eventNames) {
    state.eventNames = eventNames;
    setEventNamesCache(eventNames);
  },
  setGlobalValidationSerie(state, serie) {
    state.globalValidationSerie = serie;
  },
  setSources(state, sources) {
    if (JSON.stringify(sources) === JSON.stringify(state.sources)) return;
    state.sources = sources;
    state.filterIndex++;
  },
  setRange(state, range) {
    if (JSON.stringify(range) === JSON.stringify(state.range)) return;
    state.range = range;
    state.filterIndex++;
  },
  setReport(state, stats) {
    state.stats = stats;
    state.error = null;
  },
  setError(state, error) {
    state.error = error;
  },
  setEnvironment(state, environment) {
    state.environment = environment;
  },
  _reset(state) {
    Object.assign(state, makeDefaultState());
    state.filterIndex++;
  },
};
export const actions = {
  async fetchMain({ commit, dispatch }) {
    try {
      const mainParams = {
        statuses: ['collected', 'bot'],
      };
      const res = await dispatch('fetch', mainParams);
      commit('setReport', res?.data.data.attributes);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetch({ state }, params = {}) {
    if (!state.range) return;
    const url = `/monitoring/sources-data-quality`;
    return axiosApi.get(url, {
      params: {
        ...state.range,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        sources: state.sources.map(source => source.integrationKey),
        ...params,
      },
    });
  },
  /**
   * retourne la liste des events triés, et mise en cache au besoin
   */
  async fetchEventList({ commit }) {
    if (!state.range) return;

    commit('setEventNames', getEventNamesFromCache());
    commit('setLoadingEventNames', true);
    let eventCounts = [];
    const noneOptionSelected = !state.sources?.length; // some interpretation here → relates to data-quality-filters
    if (!noneOptionSelected) {
      const url = `/monitoring/events-list`;
      const response = await axiosApi.get(url, {
        params: {
          ...state.range,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          sources: state.sources.map(source => source.integrationKey),
        },
      });
      eventCounts = response?.data?.data.attributes;
    }
    commit(
      'setEventNames',
      eventCounts.map(({ eventName }) => eventName)
    );
    commit('setLoadingEventNames', false);

    return eventCounts;
  },
  async fetchGlobalValidationStats({ commit, state }, params = {}) {
    if (!state.range) return;
    commit('setLoadingValidationStats', true);

    const url = `/monitoring/events-status`;
    const response = await axiosApi.get(url, {
      params: {
        ...state.range,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        sources: state.sources.map(source => source.integrationKey),
        ...params,
      },
    });
    const globalValidationSerie = response.data?.data.attributes;
    commit('setGlobalValidationSerie', globalValidationSerie);
    commit('setLoadingValidationStats', false);

    return globalValidationSerie;
  },
  setRange({ commit, dispatch, state }, range) {
    if (every(['rangeType', 'from', 'to'], prop => state.range && range[prop] === state.range[prop])) return;

    commit('setRange', range);
    // setStoredRange(range);
    dispatch('reload');
  },
  setSources({ commit, dispatch }, sources) {
    commit('setSources', sources);
    dispatch('reload');
  },
  setEnvironment({ commit, dispatch }, environment) {
    commit('setEnvironment', environment);
    dispatch('reload');
  },
  includeSource({ commit, dispatch, state }, sourceId) {
    if (state.sources.includes(sourceId)) return;

    commit('setSources', [...state.sources, sourceId]);
    dispatch('reload');
  },
  excludeSource({ commit, dispatch }, sourceId) {
    if (!state.sources.includes(sourceId)) return;

    commit(
      'setSources',
      state.sources.filter(id => id !== sourceId)
    );
    dispatch('reload');
  },
  reload({ dispatch, state }) {
    if (state.filterIndex === lastReloadFilterIndex) return; // don't ask reload with same parameters
    lastReloadFilterIndex = state.filterIndex;

    clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      dispatch('fetchEventList');
      dispatch('fetchGlobalValidationStats');
    }, 30);
  },
};

export const getters = {
  /**
   * Returns true if the source filter means "no source at all"
   * Returns false if at least one source should be considered :
   *   - either the list of source ids has at least one element
   *   - or both environment AND source filters specify "All"
   * @param {Object} state
   * @returns {Boolean}
   */
  isSourceFilterNone({ environment, sources }) {
    const sourceIds = (sources || []).map(s => s.id);
    const allSources = sourceIds.length === 0 || sourceIds.includes(0);
    const allEnvironments = !environment || environment.id === 0;

    if (allEnvironments && allSources) {
      return false;
    } else {
      // allSources cannot be used if environment filter has a specific value
      // so we exclude it here from the list of the sources
      return (sources || []).filter(s => s.id > 0).length === 0;
    }
  },
  isLoading({ isLoadingEventNames, isLoadingValidationStats }) {
    return isLoadingEventNames || isLoadingValidationStats;
  },
};

export const namespaced = true;
