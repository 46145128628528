import { store } from '@/store';

const containerReload = (to, from) => {
  if (to.fullPath === from.fullPath) {
    return;
  }
  if (window.tC?.container?.reload) {
    const { locale, siteId } = to.params;
    const site = store.getters['currentUser/currentSite'];

    window.tc_vars.container_id = window.tC.id_container;
    window.tc_vars.environment.work = 'PROD';
    window.tc_vars.environment.language = locale;
    window.tc_vars.site_id = siteId;
    window.tc_vars.site_name = site.name;
    window.tc_vars.features.tag_management = { active: Boolean(site.siteModules?.container_v2) };
    window.tc_vars.features.consent_management = { active: Boolean(site.siteParams?.privacy_v2) };
    window.tc_vars.features.campaign_analytics = { active: Boolean(site.siteModules?.campaign) };
    window.tc_vars.features.data_activation = { active: Boolean(site.siteModules?.engage) };
    window.tc_vars.features.identity_resolution = { active: Boolean(site.siteModules?.fuse) };
    // TODO: get from API
    window.tc_vars.customer_id = '';
    window.tc_vars.customer_name = '';
    window.tc_vars.feature_name = '';

    window.tc_vars.user.id = store.state.currentUser.id;
    window.tc_vars.user.firstname = store.state.currentUser.first_name;
    window.tc_vars.user.lastname = store.state.currentUser.last_name;
    window.tc_vars.user.email = store.state.currentUser.email;
    // FIXME: for some reason this is not properly set
    window.tc_vars.user.last_visit = store.state.currentUser.last_visit;

    window.tc_vars.page_name = to.name;
    window.tc_vars.page_breadcrumb = to.matched.map(item => item.name);
    window.tc_vars.page_type = window.tc_vars.page_breadcrumb[1] || window.tc_vars.page_breadcrumb[0] || 'other';

    window.tC.container.reload({ events: { pageview: [{}, {}] } });
  }
};

export const containerReloadGuard = (to, from, next) => {
  try {
    containerReload(to, from);
  } catch (e) {
    console.warn('container-reload-guard - Container reload fail');
    console.warn(e);
  }
  next();
};
