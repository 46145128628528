export const rightsMixin = {
  methods: {
    hasRight(rightToCheck, type) {
      const integrationType = type ?? this.integrationType;
      const rightList = {
        source: {
          view: [this.$can('view', 'Tms'), this.$can('view', 'DmsStream')],
          edit: [this.$can('edit', 'TmsContainer'), this.$can('edit', 'DmsStream')],
          create: [this.$can('edit', 'TmsContainer'), this.$can('create', 'DmsStream')],
          delete: [this.$can('edit', 'TmsContainer'), this.$can('edit', 'DmsStream')],
        },
        destination: {
          view: [this.$can('view', 'TmsTag'), this.$can('view', 'DmsStream')],
          edit: [this.$can('edit', 'TmsTag'), this.$can('edit', 'DmsStream')],
          create: [this.$can('create', 'TmsTag'), this.$can('create', 'DmsStream')],
          delete: [this.$can('create', 'TmsTag'), this.$can('edit', 'DmsStream')],
        },
      };

      if (rightList[integrationType] && rightList[integrationType][rightToCheck]) {
        return rightList[integrationType][rightToCheck].some(Boolean);
      }
      return false;
    },
  },
};
