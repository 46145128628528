import SymfonyFrame from '@/components/layouts/symfony-frame';
import Transient from '@/components/layouts/transient';
import { store } from '@/store';

export const dataGovernance = {
  path: 'data-governance',
  name: 'data-governance',
  component: Transient,
  meta: {
    breadcrumb: null,
  },
  children: [
    {
      path: 'consent-categories/',
      name: 'consent-categories',
      component: SymfonyFrame,
      meta: {
        symfonyPath: '/containers/privacy/categories',
        can: 'view.TrustCategories',
      },
    },
    {
      path: 'consent-settings/',
      name: 'consent-settings',
      component: SymfonyFrame,
      meta: {
        symfonyPath: '/containers/privacy/options',
        can: 'edit.TrustOptions',
      },
    },
    {
      path: 'vendor-settings/',
      name: 'vendor-settings',
      component: SymfonyFrame,
      meta: {
        symfonyPath: '/containers/privacy/vendors',
        can: 'view.TrustCategories',
      },
    },
    {
      path: 'tag-data-sharing/',
      name: 'tag-data-sharing',
      component: SymfonyFrame,
      meta: {
        symfonyPath: '/containers/report/tagssummary',
        can: 'edit.TmsContainer',
      },
    },
    {
      path: 'storage-settings',
      name: 'storage-settings',
      component: Transient,
      meta: {
        breadcrumb: 'storage-settings.overview.title',
      },
      children: [
        {
          path: '/',
          name: 'storage-settings-overview',
          component: () =>
            import(
              /* webpackChunkName: "storage-settings-overview" */ '@/views/data-governance/storage-settings/overview.vue'
            ),
          meta: {
            breadcrumb: null,
            authorization: [{ siteParametersAccess: 'datasaver_beta' }, { can: 'view.DataGovernanceStorageSettings' }],
          },
        },
        {
          path: 'create',
          name: 'storage-settings-create',
          component: () =>
            import(
              /* webpackChunkName: "storage-settings-create" */ '@/views/data-governance/storage-settings/edit.vue'
            ),
          props: {
            createMode: true,
          },
          meta: {
            breadcrumb: 'common.create',
            can: 'create.DataGovernanceStorageSettings',
          },
          async beforeEnter(to, from, next) {
            await store.commit('dataGovernance/storageSettings/_clearCurrentEntity');
            next();
          },
        },
        {
          path: ':id',
          name: 'storage-settings-edit',
          component: () =>
            import(/* webpackChunkName: "storage-settings-edit" */ '@/views/data-governance/storage-settings/edit.vue'),
          props: {
            createMode: false,
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('dataGovernance/storageSettings/loadCurrent', {
              id: to.params.id,
              include:
                'sources,sources.connector,sources.destinations,sources.environment,sources.model,sources.destinations.connector',
            });
            next();
          },
          meta: {
            breadcrumb: () => ({
              label: store.state.dataGovernance.storageSettings.entity.name,
            }),
            can: 'create.DataGovernanceStorageSettings',
          },
        },
      ],
    },
  ],
};
