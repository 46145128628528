export const constant = Object.freeze({
  supportEmail: 'support@commandersact.com',
  tcModules: {
    dms: 'DATA',
    ams: 'MIX',
    tms: 'TAG',
  },
  cookieScanner: {
    status: {
      COOKIE_STATUS_LIVE: 0,
      COOKIE_STATUS_ACTIVE: 1,
      COOKIE_STATUS_IGNORED: 2,
    },
    storage_type: [
      '1st-party-cookie',
      '3rd-party-cookie',
      'http-only-1st',
      'http-only-3rd',
      'local_storage',
      'session_storage',
    ],
    customField: {
      description: 1,
      vendor: 2,
      category: 3,
    },
    origin_custom: 'custom',
  },
  sessionLifetime: 1450000,
  alert: {
    channels: {
      EMAIL: { id: 'EMAIL', img: require('@/assets/images/alert-channels/icon_email.png'), enable: true },
      WEBHOOK: { id: 'WEBHOOK', img: require('@/assets/images/alert-channels/icon_webhook.png'), enable: false },
      SLACK: { id: 'SLACK', img: require('@/assets/images/alert-channels/icon_slack.png'), enable: true },
      TEAMS: { id: 'TEAMS', img: require('@/assets/images/alert-channels/icon_teams.png'), enable: true },
    },
  },
});
