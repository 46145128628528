import { jsonApi } from '../../jsonApi';

jsonApi.define('privacy/cookie-scanner/cookie', {
  name: '',
  cookie_scanner_id: null,
  vendor_url: '',
  storage_type: '',
  storage_domain: '',
  storage_duration: '',
  origin: '',
  origin_label: '',
  status: 0,
  is_live: false,
  is_compliant: false,
  is_missing: false,
  website: '',
  vendor: {
    jsonApi: 'hasOne',
    type: 'privacy/vendor-reference',
  },
  category: {
    jsonApi: 'hasOne',
    type: 'privacy/category-reference',
  },
  fields: {
    jsonApi: 'hasMany',
    type: 'privacy/cookie-scanner/custom-field',
  },
});
