import { jsonApi } from '../jsonApi';

jsonApi.define(
  'storage_connectors/google-cloud-storage',
  {
    label: '',
    type: '',
    path: '',
    data: '',
    created_at: '',
    parameters: '',
    archived: false,
    updated_at: null,
    created_by: null,
    updated_by: null,
  },
  {
    collectionPath: 'storage_connectors/google-cloud-storages',
  }
);
