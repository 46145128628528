import { jsonApi } from '../jsonApi';

jsonApi.define('billing-category', {
  code: '',
  label: '',
  color: '',
  created_at: '',
  updated_at: '',
  order: '',
});
