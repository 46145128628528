import { store } from '@/store';
let criticalSection = false;

export const checkSessionGuard = async (to, from, next) => {
  if (criticalSection) {
    store.commit('layout/progressFinish');
    return next(false);
  }
  const redirectToLogin = () => {
    // Pass the original route to the login component
    store.commit('layout/progressFinish');
    next({ name: 'login', query: { redirectFrom: to.fullPath } });
  };

  const redirectToNotAllowedAccessPlatformX = errorType => {
    store.commit('layout/progressFinish');
    next({ name: 'error-access-denied-platform', params: { error: errorType } });
  };

  const redirectToForbidden = () => {
    store.commit('layout/progressFinish');
    next({ name: 'error', params: { error: 'noSite' } });
  };

  if (to.meta?.authorizationSkip) {
    next();
    return;
  }
  const { siteId } = to.params;

  const session = await store.dispatch('currentUser/checkPlatformSession');
  if (session === '2FA') {
    store.commit('layout/progressFinish');
  } else if (session === 'Forbidden') {
    return redirectToForbidden(siteId);
  } else if (!session) {
    if (!store.state.currentUser.initialized) {
      return redirectToLogin();
    } else {
      store.commit('layout/progressFinish');
      return next(false);
    }
  }

  if (siteId && !store.state.currentUser.sitesById[siteId]?.initialized) {
    try {
      criticalSection = true;
      await store.dispatch('currentUser/loadSite', siteId);
    } catch (e) {
      if (e.message === 'No site access') {
        store.commit('layout/progressFinish');
        next({
          name: 'error',
          params: {
            error: 'noSite',
          },
          replace: true,
        });
        return;
      }
    } finally {
      criticalSection = false;
    }
  }

  if (
    !store.getters['currentUser/isStaff'] &&
    store.state.currentUser.sitesById[siteId]?.siteModules?.access_platform_x === 0
  ) {
    return redirectToNotAllowedAccessPlatformX('notActivated');
  }

  if (
    !store.getters['currentUser/isStaff'] &&
    store.state.currentUser.sitesById[siteId]?.siteModules?.access_platform_x === 1 &&
    store.state.currentUser.sitesById[siteId]?.free_trial_days_left < 0
  ) {
    return redirectToNotAllowedAccessPlatformX('trialOver');
  }

  next();
};
