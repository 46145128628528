import { jsonApiVuex } from '@/common/store/json-api-vuex';

const streamSort = (a, b) => {
  if (a.stream.label < b.stream.label) {
    return -1;
  }
  if (a.stream.label > b.stream.label) {
    return 1;
  }
  return 0;
};
export const monitoringLiveStream = jsonApiVuex('monitoring/live_stream', {
  state: {
    local: {
      searchTerm: '',
    },
  },
  mutations: {
    setLocalSearchTerm(state, value) {
      state.local.searchTerm = value;
    },
  },
  getters: {
    allSorted(state) {
      return state.entities.sort(streamSort);
    },
  },
});
