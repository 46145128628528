import { jsonApi } from './jsonApi';

jsonApi.define('site-module', {
  id: null,
  container: false,
  container_v2: false,
  cookies_sync: false,
  campaign: false,
  dedup: false,
  dedup_impressions: false,
  engage: false,
  fuse: false,
  partners: false,
  predictions: false,
  privacy: false,
  ss_data_saver: false,
  tagperf: false,
  tag_firewall: false,
  access_platform_x: false,
  serverside_v2: false,
});
