import axios from 'axios';
import { getJWT, setJWT, getApiHostname, getSiteId, getPlatformHostname } from '~version/models/headers-handler';
import { getLocale } from '@/i18n';
import urlJoin from 'url-join';
import { eventBus } from 'shared/event-bus';

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

const errorHandler = error => {
  eventBus.$emit('progress-finish');
  return Promise.reject(error);
};
export const createAxios = (options = {}) => {
  const instance = axios.create(options);

  instance.interceptors.request.use(config => {
    eventBus.$emit('progress-start');
    config.headers['Accept-Language'] = getLocale();
    const token = getJWT();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, errorHandler);

  instance.interceptors.response.use(response => {
    eventBus.$emit('progress-finish');
    if (response.headers['x-refreshed-token']) {
      setJWT(response.headers['x-refreshed-token']);
    }
    return response;
  }, errorHandler);
  return instance;
};

const instance = axios.create();
const instanceApi = createAxios();
const instancePlatform = axios.create({
  withCredentials: true,
});

instanceApi.interceptors.request.use(config => {
  eventBus.$emit('progress-start');
  /**
   * Most of the time we want to call the api with the v2 prefix for the route
   * but in instances where we need the API without that, we can use an absolute
   * URL.
   */
  if (!isAbsoluteURLRegex.test(config.url)) {
    const siteId = getSiteId() ? `/${getSiteId()}/` : '';
    config.url = urlJoin(getApiHostname() ?? '', '/v2/', siteId, config.url);
  }
  return config;
}, errorHandler);

instanceApi.interceptors.response.use(response => {
  eventBus.$emit('progress-finish');
  return response;
}, errorHandler);

instancePlatform.interceptors.request.use(config => {
  eventBus.$emit('progress-start');
  config.url = urlJoin(getPlatformHostname() ?? '', config.url);
  return config;
}, errorHandler);

instancePlatform.interceptors.response.use(response => {
  eventBus.$emit('progress-finish');
  return response;
}, errorHandler);

export { instance as axios, instanceApi as axiosApi, instancePlatform as axiosPlatform };
