import { jsonApi } from '../../jsonApi';
import { category } from '../category';

jsonApi.define('built-in/destination-builder-category', {
  ...category,
  templates: {
    jsonApi: 'hasMany',
    type: 'built-in/destination-builder-template',
  },
});
