import { jsonApi } from '../jsonApi';

jsonApi.define('dms/flag', {
  label: '',
  description: '',
  set_condition: {},
  unset_condition: {},
  unset_mode: '',
  active: true,
  deleted_at: null,
  updated_at: '',
  archived_at: null,
});
