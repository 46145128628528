import { jsonApi } from '../jsonApi';
import { category } from './category';

jsonApi.define('destination-category', {
  ...category,
  templates: {
    jsonApi: 'hasMany',
    type: 'destination-template',
  },
});
