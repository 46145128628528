import { jsonApi } from './jsonApi';

// Correspond to a polymorphic relationship in Laravel model
jsonApi.define('model', {
  id: '',
  date_last_deployment: '',
  deployed_version: '',
  latest_version: '',
  live_version: '',
  live_version_is_impacted: false,
});
