import '@babel/polyfill';
import Vue from 'vue';

import '@/plugins/vue-breadcrumb';
import '@/plugins/bootstrap-vue';
import '@/plugins/font-awesome';

import App from '@/versions/x/app.vue';
import { router } from '@/versions/x/router';
import { i18n, loadLanguageAsync } from '@/i18n';
import VueRouter from 'vue-router';
import '@/versions/x/styles/index.scss';
import { store } from '@/store';
import { abilitiesPlugin } from '@casl/vue';
import { ability } from '@/common/security/ability';

import '@/versions/x/models/register-store-middleware';
import { globalValidationMixin } from '@/common/forms/global-validation-mixin';
import Vuelidate from 'vuelidate';
import { validClass } from '@/common/forms/valid-class-directive';
import { staticDirective } from '@/common/utilities/static-directive';
import { visibilityHidden } from '@/common/forms/visibility-hidden';
import 'chartjs-adapter-moment';
import vco from 'v-click-outside';
import {
  setApiHostname,
  setPlatformHostname,
  setStaticHostname,
  setV7Hostname,
  setV4Hostname,
} from '@/versions/x/models/headers-handler';
import { axios } from '@/models';
import { sync } from 'vuex-router-sync';
import * as Sentry from '@sentry/vue';

Vue.use(vco);
Vue.use(Vuelidate);
Vue.mixin(globalValidationMixin);
Vue.directive('validClass', validClass);
Vue.directive('visibilityHidden', visibilityHidden);
Vue.directive('static', staticDirective);
Vue.use(abilitiesPlugin, ability);

sync(store, router);

axios.defaults.baseURL = window.location.origin;
const host = window.location.hostname.split('.');
let env = 'development';

if (host[1] === 'commandersdev') {
  //ROBOXU
  host[0] = host[0].replace('app', 'api');
  setApiHostname(`https://${host.join('.')}`);
  host[0] = host[0].replace('api-', '');
  setV7Hostname(`https://${host.join('.')}`);
  setV4Hostname(`https://${host.join('.')}/V4/`);
  setStaticHostname(`https://${host.join('.')}/static`);
  setPlatformHostname(`${window.location.origin.replace('app-', 'v7content-')}/v5/web/app_dev.php`);
} else if (host[1] === 'local' || window.location.port !== '') {
  //LOCAL and serve
  env = 'development';
  setApiHostname('https://api.local.commandersact.com');
  setStaticHostname(`https://local.commandersact.com/static`);
  setV7Hostname(`https://local.commandersact.com/v5/web/app_dev.php`);
  setV4Hostname(`https://local.commandersact.com/V4/`);
  setPlatformHostname('https://v7content.local.commandersact.com/v5/web/app_dev.php');
} else if (host[0] === 'app') {
  //PROD
  env = 'prod';
  setApiHostname('https://api-internal.commander1.com');
  setStaticHostname(`https://staticplatform.commandersact.com`);
  setV7Hostname(`https://platform.commandersact.com`);
  setV4Hostname(`https://v4.commandersact.com/`);
  setPlatformHostname('https://v7content.commandersact.com');
} else if (host[0] === 'appqualif') {
  //V6 QUALIF
  env = 'staging';
  setApiHostname('https://staging.api.tagcommander.dev.commandersact.com');
  setStaticHostname(`https://static_v6_qualif.commandersact.com`);
  setV7Hostname(`https://qualif.commandersact.com`);
  setV4Hostname(`https://v4.commandersact.com/`); // FIXME: use a dedicated domain for qualif
  setPlatformHostname('https://v7contentqualif.commandersact.com');
} else {
  //EVERYTHING ELSE
  env = 'staging';
  const envName = host[0].replace('app', '');
  const domain = host[1];
  setApiHostname(`https://${envName}-review.api.tagcommander.dev.commandersact.com`);
  setStaticHostname(`https://static${envName}.${domain}.com`);
  setV7Hostname(`https://${envName}.${domain}.com`);
  setV4Hostname(`https://v4.commandersact.com/`); // FIXME: use a dedicated domain for qualif
  setPlatformHostname(`https://v7content${envName}.${domain}.com`);
}

if (env === 'prod') {
  Sentry.init({
    Vue,
    dsn: 'https://3dda85644596ba7876174df0c5bdcbd7@o4506948653678592.ingest.us.sentry.io/4506948657676288',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    allowUrls: [/https:\/\/app(-next)?\.commandersact\.com/],
    environment: env,
    // Performance Monitoring
    tracesSampleRate: 0.7, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export let app;

Promise.all([loadLanguageAsync('en', false), loadLanguageAsync('fr', false)])
  .then(() => {
    Vue.use(VueRouter);
    app = new Vue({
      router,
      i18n,
      store,
      render: h => h(App),
    }).$mount('#vue-app');
  })
  .catch(e => {
    console.error('Failed to load environment', e);
  });
