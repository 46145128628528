import { jsonApi } from '../jsonApi';

jsonApi.define('dms/connector-credential', {
  label: '',
  credentialType: '',
  path: '',
  data: '',
  created_at: '',
  parameters: '',
  archived: false,
  updated_at: null,
  created_by: null,
  updated_by: null,
});
