import { jsonApi } from '../jsonApi';

jsonApi.define('events/stat', {
  id: null,
  range: {
    from: null,
    to: null,
    step: null,
  },
  normalized: {
    jsonApi: 'hasOne',
    type: 'dataset',
  },
  weather: {
    jsonApi: 'hasOne',
    type: 'events/stats/weather',
  },
  // add other stats here
});
