import { jsonApi } from '../../jsonApi';
import { template } from '../template';

jsonApi.define('built-in/destination-builder-template', {
  ...template,
  categories: {
    jsonApi: 'hasMany',
    type: 'built-in/destination-builder-category',
  },
});
