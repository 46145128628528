<template>
  <div class="page-content">
    <div class="container-fluid">
      <Breadcrumbs />
      <ul class="nav nav-tabs nav-tabs-custom mb-4" v-if="visibleChildren.length > 1">
        <li role="presentation" class="nav-item" v-for="route in visibleChildren" :key="route.name">
          <div class="d-flex align-items-baseline">
            <router-link
              v-if="route.component"
              :to="route.path"
              class="nav-link"
              :class="{ active: $route.name === route.name, 'pr-1': route.meta.tabBadge }"
              >{{ $t(route.meta.titleI18n) }}</router-link
            >
            <a v-else class="nav-link"> {{ $t(route.meta.titleI18n) }}&nbsp;<i class="bx bx-lock-alt icon"></i> </a>
            <b-badge
              v-if="route.meta.tabBadge"
              v-b-tooltip.hover
              title="Interfaces are not definitive and may evolve in the future"
              variant="info"
              class="mr-3"
              >{{ route.meta.tabBadge }}</b-badge
            >
          </div>
        </li>
      </ul>
    </div>
    <div>
      <slot>
        <router-view></router-view>
      </slot>
    </div>
  </div>
</template>
<script>
import { routeChildren } from '@/common/router/route-children';
import { metaFn } from '@/common/router/meta-fn';

const component = {
  name: 'nav-tab',
  props: {
    mode: {},
  },
  data() {
    return {
      children: [],
      visibleChildren: [],
    };
  },
  async mounted() {
    if (this.$slots.default) {
      return;
    }
    //Search in the routers' hierarchy where this nav-tab is used to get the children
    this.children = routeChildren('nav-tab', this.$router, this.$route);
    await metaFn(this.children, this.$route);
    this.visibleChildren = this.children.filter(c => !c.meta.navTabHidden);
  },
};
export default component;
</script>
