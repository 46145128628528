import { jsonApiVuex } from '@/common/store/json-api-vuex';
import { isEqual } from 'lodash';

const bulkifiers = {
  exactNames: null,
  patterns: null,
};
const MAX_EVENTS_PER_REQUEST = 30;

const delay = duration => new Promise(resolve => setTimeout(resolve, duration));

/**
 * @typedef BulkFindParams
 * @property {string} rangeType - either 'relative' or 'absolute'
 * @property {number|string} from - range start (number of seconds if 'relative', Date if 'absolute')
 * @property {number|string} to - range end (number of seconds if 'relative', Date if 'absolute')
 * @property {string} sourceKey - get weather for a specific source providing the integration_key
 *
 * @callback BulkifyCallback
 * @param {string[]} eventNames
 * @param {BulkFindParams} params
 * @returns {Promise<object>}
 */
class BulkifyByParameter {
  /**
   * @param {BulkifyCallback} callback
   */
  constructor(callback) {
    this.queues = [];
    this.callback = callback;
  }

  /**
   *
   * @param {string} eventName
   * @param {BulkFindParams} params
   * @returns
   */
  bulkSend(eventName, params = {}) {
    let queue = this.findQueue(params);
    if (!queue) {
      queue = this.createQueue(params);
    }
    if (!queue.events.includes(eventName)) {
      queue.events.push(eventName);
    }

    if (queue.events.length > MAX_EVENTS_PER_REQUEST) {
      this.removeQueue(queue); // todo: evol to force send request at this moment
    }

    return queue.promise;
  }

  createQueue(params) {
    const queue = {
      params,
      events: [],
      // promise,
    };

    const callDelayed = async () => {
      await delay(50);

      this.removeQueue(queue);

      return this.callback(queue.events, queue.params);
    };

    queue.promise = callDelayed();
    this.queues.push(queue);

    return queue;
  }

  findQueue(params) {
    for (const queue of this.queues) {
      if (isEqual(queue.params, params)) {
        return queue;
      }
    }
  }

  removeQueue(queue) {
    this.queues = this.queues.filter(q => q !== queue);
  }
}

export const monitoringCollectionEvent = jsonApiVuex(
  'monitoring/collection/event',
  {
    actions: {
      /**
       * Action to fetch source Weather for 1 event name
       *
       *   Buffering : HTTP requests are mutualized if called within 50ms
       *
       * @param {object} vuexActionParams
       * @param {string} eventName
       * @returns Promise<WeatherObject>
       */
      async bulkFindEventSourceWeather(
        { dispatch },
        { eventName, rangeType = 'relative', from = 3600, to = 0, sourceRefs = [] }
      ) {
        const bulkifierName = eventName?.includes('%') ? 'patterns' : 'exactNames';
        if (!bulkifiers[bulkifierName]) {
          bulkifiers[bulkifierName] = new BulkifyByParameter((eventNames, params) => {
            return dispatch('findAll', {
              filter: {
                ...params,
                names: eventNames,
              },
              include: ['stats.weather'],
            });
          });
        }

        const response = await bulkifiers[bulkifierName].bulkSend(eventName, {
          rangeType,
          from,
          to,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          sourceRefs,
        });

        return response.data.find(eventInfo => eventInfo.name === eventName)?.stats?.weather;
      },
    },
  },
  {
    defaultFindAllParams: {
      rangeType: 'relative',
      from: 86400 * 30,
    },
  }
);
