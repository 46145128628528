import { get } from 'lodash';
//Only used in tc-phrase-date so it may be deleted
//Use case seems to be to map vuelidate to what bootstrap-vue expect
export const globalValidationMixin = {
  methods: {
    $_validation(path) {
      const validation = get(this.$v, path);
      return validation.$dirty ? !validation.$error : null;
    },
  },
};
