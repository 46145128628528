import { jsonApi } from '../jsonApi';

jsonApi.define('dms/variable', {
  label: '',
  property_name: '',
  type: '',
  structure_type: '',
  encrypted: false,
  archived_at: null,
  deleted_at: null,
  is_system: false,
  rules: {},
  updated_at: '',
  reference_variable_id: '',
  category: {
    jsonApi: 'hasOne',
    type: 'dms/variable_category',
  },
  universe: {
    jsonApi: 'hasOne',
    type: 'dms/universe',
  },
  segments: {
    jsonApi: 'hasMany',
    type: 'dms/segment',
  },
  streams: {
    jsonApi: 'hasMany',
    type: 'dms/stream',
  },
});
