import { jsonApi } from '../jsonApi';

jsonApi.define('dms/connector', {
  label: '',
  icon: '',
  icon_64: '',
  type: '',
  flags: [],
  template: '',
  is_custom: false,
  is_configured: false,
  created: '',
  updated: null,
  parameters: null,
  supported_integration_types: {},
  has_delivery_report: false,
  has_event_inspector: false,
  categories: {
    jsonApi: 'hasMany',
    type: 'integration-category',
  },
  trigger_type: null,
  template_form: null,
  template_js_sandbox: null,
});
