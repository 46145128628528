import Transient from '@/components/layouts/transient';
import NavTab from '@/components/layouts/nav-tab';
import { store } from '@/store';
import SymfonyFrame from '@/components/layouts/symfony-frame';
import { getBasicText } from '@/common/text/text';
import { axiosApi } from '@/models';

const editTab = (tab, component, meta = {}) => {
  meta.titleI18n = `source.${tab}.title`;
  meta.breadcrumb = () => ({ label: store.state.integration.integration.entity.label, parent: 'source-overview' });
  return {
    path: tab,
    name: `source-${tab}`,
    props: route => {
      const props = { ...route.params };
      props.id = Number(props.id);
      props.step = tab;
      props.integrationId = Number(props.id);

      return props;
    },
    component: component ? component : () => import('@/views/integration/source/source.vue'),
    meta,
  };
};

export const source = {
  path: 'sources',
  name: 'sources',
  component: Transient,
  meta: {
    breadcrumb: null,
  },
  children: [
    {
      path: '/',
      name: 'source-overview',
      component: () =>
        import(/* webpackChunkName: "integrations-overview" */ '@/views/integration/integrations-overview.vue'),
      props: {
        overviewType: 'source',
      },
      meta: {
        breadcrumb: 'source.title',
        can: 'view.Tms|view.DmsStream',
      },
    },
    {
      path: 'containers/',
      name: 'containers',
      component: SymfonyFrame,
      meta: {
        symfonyPath: '/containers/overview',
      },
    },
    {
      path: 'create',
      component: Transient,
      beforeEnter(to, from, next) {
        store.commit('tms/webContainer/_clearCurrentEntity');
        store.commit('integration/integration/_clearCurrentEntity');
        next();
      },
      meta: {
        breadcrumb: null,
      },
      children: [
        {
          path: '/',
          name: 'source-catalog',
          props: {
            catalogType: 'source',
          },
          component: () => import(/* webpackChunkName: "integration-catalog" */ '@/views/integration/catalog.vue'),
          meta: {
            titleI18n: 'source.catalog.title',
            breadcrumb: { label: 'common.create', parent: 'source-overview' },
            can: 'edit.TmsContainer|create.DmsStream',
          },
        },
        {
          path: ':id(\\d+)',
          component: NavTab,
          meta: {
            breadcrumb: null,
          },
          children: [
            {
              path: 'creation',
              name: 'source-create',
              component: () => import('@/views/integration/source/creation-wizard.vue'),
              props(route) {
                const props = { ...route.params };
                props.id = Number(props.id);
                props.integrationTemplateId = Number(props.id);
                props.step = 'create';
                return props;
              },
              meta: {
                async metaFn() {
                  return {
                    navTabHidden: true,
                    breadcrumb: { label: store.state.integration.template.entity.label, parent: 'source-catalog' },
                  };
                },
              },
              async beforeEnter(routeTo, routeFrom, next) {
                if (routeTo.params.id) {
                  await store.dispatch('integration/template/loadCurrent', {
                    id: routeTo.params.id,
                    include: 'categories',
                  });
                }

                next();
              },
            },
            {
              path: 'description',
              name: 'source-description',
              component: () =>
                import(/* webpackChunkName: "source-description" */ '@/views/integration/integration-description.vue'),
              props(route) {
                const props = { ...route.params };
                props.id = Number(props.id);
                props.step = 'description';
                props.integrationType = 'source';
                return props;
              },
              meta: {
                async metaFn() {
                  return {
                    navTabHidden: true,
                    breadcrumb: { label: store.state.integration.template.entity.label, parent: 'source-catalog' },
                  };
                },
              },
              async beforeEnter(routeTo, routeFrom, next) {
                await store.dispatch('integration/template/loadCurrent', routeTo.params.id);

                try {
                  await getBasicText(`sources/${store.state.integration.template.entity.template}/description`);
                } catch (fetchError) {
                  if (fetchError.response.status === 404) {
                    next({ name: 'source-create', params: { id: routeTo.params.id } });
                    return;
                  }
                }
                next();
              },
            },
          ],
        },
      ],
    },
    {
      path: 'duplicate/:integrationId(\\d+)',
      component: NavTab,
      async beforeEnter(to, from, next) {
        store.commit('tms/webContainer/_clearCurrentEntity');
        store.commit('integration/integration/_clearCurrentEntity');
        await store.dispatch('integration/integration/loadCurrent', {
          id: to.params.integrationId,
          include:
            'environment,connector,universe,updater,destinations,destinations.connector,destinations.environment,destinations.updater,destinations.sources,destinations.sources.connector',
        });
        await store.dispatch('integration/template/loadCurrent', {
          id: store.state.integration.integration.entity.connector.id,
          include: 'categories',
        });
        next();
      },
      meta: {
        breadcrumb: null,
      },
      children: [
        {
          path: '/',
          name: 'source-duplicate',
          component: () => import('@/views/integration/source/creation-wizard.vue'),
          props(route) {
            const props = { ...route.params };
            props.step = 'duplicate';
            return props;
          },
          meta: {
            async metaFn() {
              return {
                navTabHidden: true,
                breadcrumb: { label: store.state.integration.integration.entity.label, parent: 'source-overview' },
              };
            },
          },
        },
      ],
    },
    {
      path: ':id(\\d+)',
      async beforeEnter(to, from, next) {
        await store.dispatch('integration/integration/loadCurrent', {
          id: to.params.id,
          include:
            'connector,universe,updater,creator,environment,destinations,destinations.connector,destinations.environment,destinations.updater,destinations.sources,destinations.sources.connector,sources',
        });
        next();
      },
      meta: {
        breadcrumb: null,
      },
      component: Transient,
      children: [
        {
          path: 'edit',
          meta: {
            breadcrumb: null,
          },
          component: NavTab,
          children: [
            editTab('overview-tab', () => import(`@/views/integration/source/overview-tab.vue`), {
              async metaFn() {
                return {
                  navTabHidden: false,
                  titleI18n: 'source.edit.tabs.overview',
                };
              },
            }),
            editTab('input', null, {
              metaFn() {
                return {
                  navTabHidden:
                    store.state.integration.integration.entity.connector?.template !== 'cax_data_store_reply_event',
                  titleI18n: 'source.edit.tabs.input',
                };
              },
            }),
            editTab('settings', () => import(`@/views/integration/source/source.vue`), {
              async metaFn() {
                return {
                  navTabHidden: false,
                  titleI18n: 'source.edit.tabs.settings',
                };
              },
            }),
            editTab('filters', null, {
              metaFn() {
                return {
                  navTabHidden:
                    store.state.integration.integration.entity.connector?.template !== 'cax_data_store_reply_event',
                  titleI18n: 'source.edit.tabs.filters',
                };
              },
            }),
            editTab('activation', null, {
              metaFn() {
                return {
                  navTabHidden:
                    store.state.integration.integration.entity.connector?.template !== 'cax_data_store_reply_event',
                  titleI18n: 'source.edit.tabs.activation',
                };
              },
            }),
            editTab('quality', () => import(`@/views/integration/source/quality-tab.vue`), {
              async metaFn() {
                return {
                  navTabHidden:
                    store.state.integration.integration.entity.connector?.template === 'cax_data_store_reply_event',
                  titleI18n: 'source.edit.tabs.quality',
                };
              },
            }),
            {
              path: 'inspector',
              name: `source-inspector`,
              component: SymfonyFrame,
              props: false,
              meta: {
                titleI18n: 'source.edit.tabs.inspector',
                breadcrumb: () => ({
                  label: store.state.integration.integration.entity.label,
                  parent: 'source-overview',
                }),
                async metaFn() {
                  let symfonyPath = '';
                  let navTabHidden = true;
                  try {
                    const {
                      data: { data: discover },
                    } = await axiosApi.get(`source-inspectors`, {});
                    symfonyPath = `/dashboard/load/${discover.id}/source_id:${store.state.integration.integration.entity.integration_key}/app/discover#/`;
                    navTabHidden =
                      store.state.integration.integration.entity.connector?.template === 'cax_data_store_reply_event';
                  } catch (error) {
                    console.warn('API Warning', error);
                  }
                  return {
                    symfonyPath,
                    navTabHidden,
                  };
                },
              },
            },
          ],
        },
        {
          path: 'source-code',
          component: NavTab,
          meta: {
            breadcrumb: null,
          },
          children: [
            editTab('get-code', () => import(`@/views/integration/source/source.vue`), {
              async metaFn() {
                return {
                  navTabHidden: true,
                  titleI18n: 'source.sourceCode',
                };
              },
            }),
          ],
        },
      ],
    },
    {
      path: 'privacy-banners/',
      name: 'privacy-banners',
      component: SymfonyFrame,
      meta: {
        symfonyPath: '/containers/privacy/overview',
      },
    },
    {
      path: 'sources-inspector/',
      name: 'sources-inspector',
      component: SymfonyFrame,
      props: false,
      meta: {
        breadcrumb: () => ({
          label: 'source.liveEventInspectorAllSource',
        }),
        async metaFn() {
          let symfonyPath = '';
          try {
            const {
              data: { data: discover },
            } = await axiosApi.get('source-inspectors', {});
            symfonyPath = `/dashboard/load/${discover.id}/-/app/discover#/`;
          } catch (error) {
            console.warn('API Warning', error);
          }
          return {
            symfonyPath,
            showBreadcrumb: true,
          };
        },
      },
    },
    {
      path: 'trafficking/',
      name: 'trafficking',
      component: SymfonyFrame,
      meta: {
        symfonyPath: '/campaign/trafficking',
      },
    },
  ],
};
