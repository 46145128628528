import { jsonApi } from '../jsonApi';

jsonApi.define('dms/stream_column', {
  header: '',
  order: 0,
  value: '',
  variable: {
    jsonApi: 'hasOne',
    type: 'dms/variable',
  },
  modifiers: {
    jsonApi: 'hasMany',
    type: 'dms/modifier',
  },
  parent_variable: {
    jsonApi: 'hasOne',
    type: 'dms/variable',
  },
});
