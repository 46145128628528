import { jsonApi } from '../jsonApi';

jsonApi.define('normalized-datalayer/event-configuration', {
  id: null,
  name: '',
  environment: {
    jsonApi: 'hasOne',
    type: 'integration-environment',
  },
  configuration: {},
});
