import { store } from '@/store';
import { getJWT } from '~version/models/headers-handler';
import { metaAuthorization } from '@/common/router/meta-authorization';

export const authorizationGuard = async function(to, from, next) {
  const redirectToLogin = () => {
    // Pass the original route to the login component
    store.commit('layout/progressFinish');
    next({ name: 'login', query: { redirectFrom: to.fullPath } });
  };

  if (to.meta?.authorizationSkip || to.name === 'error') {
    next();
    return;
  }

  if (!getJWT()) {
    return redirectToLogin();
  }

  try {
    await store.dispatch('currentUser/init');
  } catch (e) {
    redirectToLogin();
    return;
  }

  const currentSite = store.getters['currentUser/currentSite'];
  if (!currentSite) {
    next({
      name: 'error',
      params: {
        error: 'noSite',
      },
      replace: true,
    });
    return;
  }
  if (!currentSite.initialized) {
    await store.dispatch('currentUser/loadSite', to.params.siteId);
  }
  const isAuthorized = metaAuthorization(to.meta);
  if (!isAuthorized) {
    store.commit('layout/progressFinish');
    next({ name: 'error', params: { error: 'unauthorizedAccess' }, replace: true });
  }
  next();
};
