import { jsonApi } from '../jsonApi';

jsonApi.define('privacy/category-reference', {
  category_public: null,
  parent: null,
  label: '',
  description: '',
  default: false,
  iab_type: null,
});
