import { jsonApi } from '../jsonApi';

jsonApi.define('normalized-datalayer/standard-event-type', {
  id: null,
  name: '',
  label: '',
  created: '',
  stored: '',
  updated: '',
  description: '',
  icon: null,
  is_custom: false,
  properties: {
    jsonApi: 'hasMany',
    type: 'normalized-datalayer/standard-event-properties',
  },
});
