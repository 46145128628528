import Transient from '@/components/layouts/transient';
import NavTabWizard from '@/components/layouts/nav-tab-wizard';
import { store } from '@/store';

const createTab = (tab, component, meta = {}, prefix = '', enrichmentTypeProp) => {
  meta.titleI18n = `enrichment.tabs.${tab}.title`;
  meta.breadcrumb = null;
  meta.can = 'create.DataGovernanceEnrichment';
  return {
    path: tab,
    name: `event-enrichment-${prefix}${tab}`,
    props: route => {
      const props = { ...route.params };
      props.step = tab;
      props.formAction = props.formAction ?? 'create';
      props.enrichmentTypeProp = enrichmentTypeProp;

      return props;
    },
    component,
    meta,
  };
};

const saveEnrichment = async self => {
  try {
    await self.$store.dispatch('enrichment/eventEnrichment/saveCurrent');

    self.$store.commit('notifications/pushSuccess', {
      text: self.$t('common.notifications.saveSuccessful'),
      timeout: true,
    });

    // return to enrichment list
    self.$router.push({ name: 'event-enrichment-overview' });
  } catch (error) {
    console.warn('API Warning', error);

    self.$store.commit('notifications/pushWarning', {
      text: self.$t('common.notifications.validationError'),
      timeout: true,
    });
  }
};

export const enrichment = {
  path: 'enrich',
  name: 'enrich',
  component: Transient,
  meta: {
    breadcrumb: null,
  },
  children: [
    {
      path: 'user-attributes/',
      name: 'user-attributes',
      component: () => import(/* webpackChunkName: "dms-user-attributes" */ '@/views/dms/user-attributes.vue'),
      meta: {
        breadcrumb: 'dms.userAttributes.title',
        titleI18n: 'dms.userAttributes.title',
      },
    },
    {
      path: 'user-attributes/',
      name: 'dms-user-attributes',
      component: () => import(/* webpackChunkName: "dms-user-attributes" */ '@/views/dms/user-attributes.vue'),
      meta: {
        breadcrumb: 'dms.userAttributes.title',
        titleI18n: 'dms.userAttributes.title',
        staffOnly: true,
      },
    },
    {
      path: 'user-attributes/create',
      name: 'user-attributes-create',
      props: true,
      component: () => import(/* webpackChunkName: "dms-user-attributes" */ '@/views/dms/user-attributes-create.vue'),
      meta: {
        titleI18n: 'dms.userAttributes.children.create.title',
        breadcrumb: {
          parent: 'user-attributes',
          label: 'dms.userAttributes.children.create.title',
        },
      },
    },
    {
      path: 'user-attributes/create/flag',
      name: 'user-attributes-create-flag',
      component: () =>
        import(/* webpackChunkName: "dms-user-attributes" */ '@/views/dms/user-attributes-edit-flag.vue'),
      props(route) {
        const props = { ...route.params };
        props.create = true;
        return props;
      },
      meta: {
        titleI18n: 'dms.userAttributes.children.create.methods.flag.title',
        breadcrumb: {
          parent: 'user-attributes-create',
          label: 'dms.userAttributes.children.create.methods.flag.title',
        },
      },
    },
    {
      path: 'user-attributes/create/:kind',
      name: 'user-attributes-create-kind',
      component: () => import(/* webpackChunkName: "dms-user-attributes" */ '@/views/dms/user-attributes-edit.vue'),
      props(route) {
        const props = { ...route.params };
        props.create = true;
        return props;
      },
      meta: {
        breadcrumb: params => ({
          parent: 'user-attributes-create',
          label: `dms.userAttributes.aggregation.${params.kind}`,
        }),
      },
    },
    {
      path: 'user-attributes/edit/flag/:initialId',
      name: 'user-attributes-edit-flag',
      component: () =>
        import(/* webpackChunkName: "dms-user-attributes" */ '@/views/dms/user-attributes-edit-flag.vue'),
      props(route) {
        const props = { ...route.params };
        props.create = false;
        return props;
      },
      meta: {
        titleI18n: 'dms.userAttributes.children.edit.titleFlag',
        breadcrumb: params => ({
          parent: 'user-attributes',
          label: ['common.editThing', [params.initialId]],
        }),
      },
    },
    {
      path: 'user-attributes/edit/:kind/:initialId',
      name: 'user-attributes-edit',
      component: () => import(/* webpackChunkName: "dms-user-attributes" */ '@/views/dms/user-attributes-edit.vue'),
      props(route) {
        const props = { ...route.params };
        props.create = false;
        return props;
      },
      meta: {
        titleI18n: 'dms.userAttributes.children.edit.title',
        breadcrumb: params => ({
          parent: 'user-attributes',
          label: ['common.editThing', [params.initialId]],
        }),
      },
    },
    {
      path: 'event',
      name: 'event',
      component: Transient,
      meta: {
        breadcrumb: null,
      },
      children: [
        {
          path: '/',
          name: 'event-enrichment-overview',
          component: () => import(/* webpackChunkName: "event-enrichment" */ '@/views/enrichment/event/overview.vue'),
          meta: {
            breadcrumb: 'enrichment.title',
            can: 'view.DataGovernanceEnrichment',
          },
        },
        {
          path: 'list-types',
          name: 'event-enrichment-list-types',
          component: () => import(/* webpackChunkName: "event-enrichment" */ '@/views/enrichment/event/list-types.vue'),
          meta: {
            breadcrumb: { label: 'common.create', parent: 'event-enrichment-overview' },
            can: 'view.DataGovernanceEnrichment',
            staffOnly: true,
          },
        },
        {
          path: 'creation',
          beforeEnter(to, from, next) {
            store.commit('enrichment/eventEnrichment/_clearCurrentEntity');

            store.commit('enrichment/eventEnrichment/setParameters', {
              filter: {
                type: 'and',
                operands: [],
              },
              filter_text: '',
              mapping: [],
              api: {
                method: '',
                url: '',
                url_text: '',
                body: '',
                headers: [],
              },
            });

            next();
          },
          meta: {
            breadcrumb: null,
          },
          component: Transient,
          children: [
            {
              path: '/',
              name: 'event-enrichment-create',
              component: () => import(/* webpackChunkName: "event-enrichment" */ '@/views/enrichment/event/create.vue'),
              props: {
                formAction: 'create',
              },
              meta: {
                titleI18n: 'enrichment.title',
                breadcrumb: { label: 'enrichment.create.title', parent: 'event-enrichment-overview' },
                can: 'create.DataGovernanceEnrichment',
                staffOnly: true,
              },
            },
          ],
        },
        {
          path: 'api/:formAction/:id(\\d+)?',
          name: 'event-enrichment-api-edit',
          props: to => {
            let back, mode;
            if (to.params.formAction === 'create') {
              back = { name: 'event-enrichment-list-types' };
              mode = 'wizard';
            } else {
              back = { name: 'event-enrichment-overview' };
              mode = 'tab';
            }
            return { mode, save: saveEnrichment, back };
          },
          async beforeEnter(to, from, next) {
            if (to.params.formAction === 'create') {
              store.commit('enrichment/eventEnrichment/_clearCurrentEntity');
              store.commit('enrichment/eventEnrichment/setStatus', 'active');
              store.commit('enrichment/eventEnrichment/setParameters', {
                filter: {
                  type: 'and',
                  operands: [],
                },
                filter_text: '',
                mapping: [],
                api: {
                  method: '',
                  url: '',
                  url_text: '',
                  body: '',
                  headers: [],
                },
              });
              next();
              return;
            }
            if (to.params.id) {
              await store.dispatch('enrichment/eventEnrichment/loadCurrent', {
                id: to.params.id,
                include: 'sources,sources.connector,sources.model,sources.environment',
              });
              if (to.params.formAction === 'duplicate') {
                store.commit('enrichment/eventEnrichment/setId', null);
              }
            }

            next();
          },
          component: NavTabWizard,
          meta: {
            titleI18n: 'enrichment.title',
            breadcrumb: () => ({
              label: store.state.enrichment.eventEnrichment.entity.name,
              parent: 'event-enrichment-overview',
            }),
          },
          children: [
            createTab(
              'settings',
              () => import(`@/views/enrichment/event/settings-tab.vue`),
              {
                wizardIcons: 'bx bxs-edit-alt',
                async metaFn() {
                  return {
                    navTabHidden: false,
                  };
                },
              },
              'api-edit-',
              'api'
            ),
            createTab(
              'api-configuration',
              () => import(`@/views/enrichment/event/api-configuration-tab.vue`),
              {
                wizardIcons: 'bx bx-cloud',
                async metaFn() {
                  return {
                    navTabHidden: false,
                  };
                },
              },
              'api-edit-',
              'api'
            ),
            createTab(
              'properties',
              () => import(`@/views/enrichment/event/properties-tab.vue`),
              {
                wizardIcons: 'bx bx-list-ul',
                async metaFn() {
                  return {
                    navTabHidden: false,
                  };
                },
              },
              'api-edit-',
              'api'
            ),
          ],
        },
        {
          path: 'custom-datastore/:formAction/:id(\\d+)?',
          name: 'event-enrichment-custom-datastore-edit',
          props: to => {
            let back, mode;
            if (to.params.formAction === 'create') {
              back = { name: 'event-enrichment-list-types' };
              mode = 'wizard';
            } else {
              back = { name: 'event-enrichment-overview' };
              mode = 'tab';
            }
            return { mode, save: saveEnrichment, back };
          },
          async beforeEnter(to, from, next) {
            if (to.params.formAction === 'create') {
              store.commit('enrichment/eventEnrichment/_clearCurrentEntity');
              store.commit('enrichment/eventEnrichment/setStatus', 'active');
              store.commit('enrichment/eventEnrichment/setParameters', {
                filter: {
                  type: 'and',
                  operands: [
                    {
                      _id: Math.random(),
                      type: 'in',
                      operands: [
                        {
                          name: 'event_name',
                          type: 'property',
                        },
                        {
                          type: 'constant',
                          value: [],
                        },
                      ],
                    },
                  ],
                },
                filter_text: '',
                mapping: [],
              });
              next();
              return;
            }
            if (to.params.id) {
              await store.dispatch('enrichment/eventEnrichment/loadCurrent', {
                id: to.params.id,
                include: 'sources,sources.connector,sources.model,sources.environment,updater,creator',
              });
              if (to.params.formAction === 'duplicate') {
                store.commit('enrichment/eventEnrichment/setId', null);
              }
            }

            next();
          },
          component: NavTabWizard,
          meta: {
            titleI18n: 'enrichment.title',
            breadcrumb: params => ({
              label:
                params.formAction === 'edit'
                  ? store.state.enrichment.eventEnrichment.entity.name
                  : 'enrichment.storage_settings.title',
              parent: params.formAction === 'create' ? 'event-enrichment-list-types' : 'event-enrichment-overview',
            }),
          },
          children: [
            createTab(
              'settings',
              () => import(`@/views/enrichment/event/storage-settings-settings-tab.vue`),
              {
                wizardIcons: 'bx bxs-edit-alt',
                async metaFn() {
                  return {
                    navTabHidden: false,
                  };
                },
              },
              'custom-datastore-edit-',
              'storage_settings'
            ),
            createTab(
              'properties',
              () => import(`@/views/enrichment/event/storage-settings-properties-tab.vue`),
              {
                wizardIcons: 'bx bx-list-ul',
                async metaFn() {
                  return {
                    navTabHidden: false,
                  };
                },
              },
              'custom-datastore-edit-',
              'storage_settings'
            ),
          ],
        },
        {
          path: 'datastore/:id(\\d+)/:formAction',
          name: 'event-enrichment-datastore-edit',
          component: () => import('@/views/enrichment/event/create.vue'),
          async beforeEnter(to, from, next) {
            await store.dispatch('enrichment/eventEnrichment/loadCurrent', {
              id: to.params.id,
              include: 'store,variables,sources,sources.connector,sources.model,sources.environment',
            });
            next();
          },
          props: true,
          meta: {
            titleI18n: 'enrichment.edit.title',
            breadcrumb: () => ({
              label: store.state.enrichment.eventEnrichment.entity.name,
              parent: 'event-enrichment-overview',
            }),
            can: 'create.DataGovernanceEnrichment',
          },
        },
      ],
    },
  ],
};
