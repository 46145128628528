import { jsonApi } from '../jsonApi';

jsonApi.define('tasks/request', {
  id: '',
  label: '',
  task_type: '',
  progress: '',
  created_at: '',
  body: '',
  lastTaskRun: {
    jsonApi: 'hasOne',
    type: 'task/run',
  },
});
