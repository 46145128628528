import { jsonApi } from '../jsonApi';

jsonApi.define('data-governance/storage-setting', {
  name: '',
  description: '',
  parameters: '',
  status: '',
  stored_properties: [],
  gdpr_purpose: '',
  matching_key: '',
  retention: '',
  event_name: '',
  sources: {
    jsonApi: 'hasMany',
    type: 'integration',
  },
  created: '',
  updated: '',
  deleted: '',
  event_count: '',
});
