import { jsonApiVuex } from '@/common/store/json-api-vuex';

export const monitoringTaskLog = jsonApiVuex(
  'monitoring/task_log',
  {},
  {
    defaultFindAllParams: {
      include: ['task'],
      page: {
        size: 25,
      },
      filter: {
        status: ['pending', 'in_progress', 'failed'],
        type: ['import', 'export'],
        source: null,
      },
    },
  }
);
