import { jsonApi } from '@/models';
import { router } from '~version/router';

const IPAddressUnauthorizedText = 'Your IP is not allowed on this account';

const errorMiddleware = {
  name: 'notification-on-error',
  error: function(payload) {
    const { response } = {
      response: { ...(payload.response || { status: 0, data: payload.message }) },
    };

    response.data.errors = [...response.data.errors, { title: 'devour-http-error', detail: payload }];

    const fourZeroThree = response.data.errors.find(error => error.status === 403);
    if (fourZeroThree && fourZeroThree.detail === IPAddressUnauthorizedText) {
      return router.replace({ name: 'error-ip-not-allowed' });
    }

    return payload;
  },
};

jsonApi.insertMiddlewareBefore('errors', errorMiddleware);
