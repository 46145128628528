import { debounce } from 'lodash';
import { constant } from '@/common/constant';

export const authenticationMixin = {
  data() {
    return {
      checkSessionTimeout: null,
    };
  },
  methods: {
    checkPlatformSession: debounce(
      function() {
        this.$store.dispatch('currentUser/checkPlatformSession');

        if (this.checkSessionTimeout) {
          clearTimeout(this.checkSessionTimeout);
        }
        this.checkSessionTimeout = setTimeout(() => {
          this.$store.dispatch('currentUser/checkPlatformSession');
        }, constant.sessionLifetime);
      },
      30000,
      { maxWait: 60000 }
    ),
  },
};
