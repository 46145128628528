<template>
  <Layout>
    <template #title>
      <h5 class="text-white">{{ $t('account.resetPassword.title') }}</h5>
      <p>{{ $t('account.resetPassword.subTitle') }}</p>
    </template>
    <template>
      <div>
        <b-form class="p-2" @submit.prevent="tryUpdatePassword">
          <b-form-group
            id="input-group-1"
            label-for="input-1"
            :state="true"
            :description="$t('account.resetPassword.help')"
          >
            <template #label>
              <div class="label-container">
                <span>{{ $t('account.resetPassword.label') }}</span>
                <span v-if="isCapsLockOn" class="text-danger">{{ $t('account.resetPassword.capsLock') }}</span>
              </div>
            </template>
            <b-input-group>
              <b-form-input
                @keydown="checkCapsLock"
                v-model="password"
                :state="validationState"
                id="input-1"
                :type="showPassword ? 'text' : 'password'"
              />
              <b-input-group-append>
                <b-button class="show-btn" variant="outline-secondary" @click="showPassword = !showPassword">
                  <i class="bx bxs-show" v-if="showPassword"></i>
                  <i class="bx bxs-hide" v-else></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div class="complexity-bar">
              <div v-for="(color, index) in progressBarContent" :key="index" :class="`complexity-${color}`" />
            </div>
            <div class="status-info-container">
              <span class="text-danger">{{ apiErrorMessage }}</span>
              <span>{{ complexityLabel }}</span>
            </div>
          </b-form-group>
          <div class="mt-3">
            <tc-button-loading
              type="submit"
              :loading="loading"
              :disabled="!isComplexityGood"
              class="btn btn-block btn-ca"
              :label="$t('common.save')"
            />
          </div>
        </b-form>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/layouts/auth';
import tcButtonLoading from '@/components/buttons/tc-button-loading.vue';
import { getBitEntropy } from '@/common/utilities/string-entropy';
import { axiosApi } from '@/models';
import urlJoin from 'url-join';
import { getApiHostname } from '~version/models/headers-handler';

const COMPLEXITY_STRONG = 100;
const COMPLEXITY_GOOD = 80;
const COMPLEXITY_WEAK = 40;

export default {
  page: {
    title: 'Login',
  },
  data() {
    return {
      showPassword: false,
      password: '',
      apiErrorMessage: '',
      loading: false,
      isCapsLockOn: false,
    };
  },
  components: {
    Layout,
    tcButtonLoading,
  },
  mounted() {
    if (this.$route.query.token === undefined || this.$route.query.id === undefined) {
      this.$router.push({ name: 'login' });
    }
  },
  computed: {
    passwordEntropy() {
      return getBitEntropy(this.password);
    },
    isComplexityGood() {
      return this.passwordEntropy >= COMPLEXITY_GOOD;
    },
    complexityLabel() {
      if (this.password === '') return this.$t('account.resetPassword.strength');
      if (this.passwordEntropy >= COMPLEXITY_STRONG) return this.$t('account.resetPassword.strong');
      if (this.passwordEntropy >= COMPLEXITY_GOOD) return this.$t('account.resetPassword.good');
      if (this.passwordEntropy >= COMPLEXITY_WEAK) return this.$t('account.resetPassword.weak');
      return this.$t('account.resetPassword.veryWeak');
    },
    progressBarContent() {
      if (this.password === '') return ['grey', 'grey', 'grey', 'grey'];
      if (this.passwordEntropy >= COMPLEXITY_STRONG) return ['green', 'green', 'green', 'green'];
      if (this.passwordEntropy >= COMPLEXITY_GOOD) return ['green', 'green', 'green', 'grey'];
      if (this.passwordEntropy >= COMPLEXITY_WEAK) return ['yellow', 'yellow', 'grey', 'grey'];
      return ['red', 'grey', 'grey', 'grey'];
    },
    validationState() {
      return this.apiErrorMessage ? false : null;
    },
  },
  methods: {
    async tryUpdatePassword() {
      this.apiErrorMessage = '';
      this.loading = true;
      try {
        await axiosApi.post(urlJoin(getApiHostname(), `/v2/users/${this.$route.query.id}/reset-password`), {
          token: this.$route.query.token,
          password: this.password,
        });

        this.$store.commit('notifications/pushSuccess', {
          text: 'The password has been updated',
          timeout: true,
        });
        this.$router.push({ name: 'login' });
      } catch (error) {
        if (error?.response?.status === 400) {
          this.apiErrorMessage = this.$t('account.resetPassword.passwordAlreadyUsed');
        } else if (error?.response?.status === 422) {
          this.apiErrorMessage = error.response.data.errors[0].detail[0];
        } else {
          this.apiErrorMessage = this.$t('account.resetPassword.genericError');
        }
      }
      this.loading = false;
    },
    checkCapsLock(event) {
      this.isCapsLockOn = event?.getModifierState('CapsLock');
    },
  },
  watch: {
    password() {
      this.apiErrorMessage = '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/versions/x/styles/variables.scss';

i {
  font-size: 15px;
}

.complexity-bar {
  width: 100%;
  height: 4px;
  display: flex;
  margin-top: 7px;
  gap: 0px 3px;
}

.complexity {
  flex: 1;
  height: 100%;
  border-radius: 2px;

  &-green,
  &-yellow,
  &-red,
  &-grey {
    @extend .complexity;
  }

  &-green {
    background-color: $green;
  }
  &-yellow {
    background-color: $yellow;
  }
  &-red {
    background-color: $commandersact;
  }
  &-grey {
    background-color: $gray-400;
  }
}

.status-info-container {
  display: flex;
  justify-content: space-between;
}

.label-container {
  display: flex;
  justify-content: space-between;
}

.show-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: $gray-400;
}
</style>
