import { isObject, get } from 'lodash';
/**
 * A directive which will scan for a model, get its validation state and depending on it, add a `is-valid` or `is-invalid` CSS class
 */
const handleValidClass = function(el, { expression, value }, vnode) {
  el.classList.remove('is-valid');
  el.classList.remove('is-invalid');
  if (!expression) {
    const { expression: modelExpression } = vnode.data.directives.find(d => d.name === 'model');
    if (!modelExpression) {
      throw new Error('ValidClass directive: Missing expression or v-model');
    }
    value = get(vnode.context.$v, modelExpression);
    if (!value) {
      throw new Error(`ValidClass directive: No validation associated to v-model's expression: ${modelExpression}`);
    }
  }
  if (isObject(value)) {
    const { $dirty, $error } = value;
    if ($dirty) {
      if ($error) {
        el.classList.add('is-invalid');
      } else {
        el.classList.add('is-valid');
      }
    }
  }
  if (value === true) {
    el.classList.add('is-valid');
  }
  if (value === false) {
    el.classList.add('is-invalid');
  }
  if (value == null) {
    //nothing to do
  }
};
export const validClass = {
  bind: handleValidClass,
  update: handleValidClass,
};
