export const template = {
  label: '',
  icon: '',
  type: '',
  flags: [],
  guide_url: null,
  template: '',
  trigger_type: '',
  trigger_type_id: '',
  task_type: 'server-side-sandbox',
  parameters: [],
  is_custom: false,
  is_configured: false,
  supported_integration_types: [],
  created: '',
  updated: null,
  has_delivery_report: false,
  has_event_inspector: false,
  icon_64: '',
  template_form: null,
  template_js_sandbox: null,
  version: '',
  deployment_status: null,
  deployment_date: null,
  deployment_details: null,
  description: null,
  template_ref_id: null,
  template_ref_site_id: null,
};
