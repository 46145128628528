import { jsonApi } from '../jsonApi';

jsonApi.define('monitoring/task_log', {
  uuid: '',
  scheduled_date: '',
  start_date: '',
  end_date: '',
  status: '',
  details: {},
  task: {
    jsonApi: 'hasOne',
    type: 'monitoring/task',
  },
});
