import { axiosApi } from '@/models';

// TODO: cache requests
export const getBasicText = async path => {
  const {
    data: { content },
  } = await axiosApi.get(`/../texts/basic/${path}`);
  return content;
};

export const getIntegrationIssueText = async (integrationType, integrationTemplate, issueType, issueCode) => {
  const {
    data: { content },
  } = await axiosApi.get(
    `/../texts/integration/${integrationType}/${integrationTemplate}/issue/${issueType}/${issueCode}`
  );

  return content;
};
