import { jsonApi } from '../jsonApi';

jsonApi.define('integration', {
  id: null,
  type: '',
  label: '',
  occurence_type: '',
  start_date: '',
  end_date: '',
  timezone: '',
  data_range_type: '',
  data_last: null,
  data_start_date: null,
  data_end_date: null,
  last_request_at: null,
  next_request_at: null,
  last_run_at: null,
  export_by_conversions: null,
  created: '',
  updated: '',
  cron: null,
  archived: false,
  deleted_at: null,
  health: null,
  status: null,
  enrichments: null,
  parameters: {
    filename: '',
    include_headers: false,
    csv_separator: '',
    csv_variable_value_separator: '',
    done_extension: '',
    extension: '',
    tradelab_segment: '',
  },
  integration_key: '',
  environment: {
    jsonApi: 'hasOne',
    type: 'integration-environment',
  },
  connector: {
    jsonApi: 'hasOne',
    type: 'dms/connector',
  },
  storage_connector: {
    jsonApi: 'hasOne',
  },
  universe: {
    jsonApi: 'hasOne',
    type: 'dms/universe',
  },
  updater: {
    jsonApi: 'hasOne',
    type: 'user',
  },
  creator: {
    jsonApi: 'hasOne',
    type: 'user',
  },
  destinations: {
    jsonApi: 'hasMany',
    type: 'integration',
  },
  sources: {
    jsonApi: 'hasMany',
    type: 'integration',
  },
  model: {
    jsonApi: 'hasOne',
    type: 'model',
  },
  segments: {
    jsonApi: 'hasMany',
    type: 'dms/segment',
  },
  stream_columns: {
    jsonApi: 'hasMany',
    type: 'dms/stream_column',
    _dataRootRequestAttribute: true,
  },
  alerts: {
    jsonApi: 'hasMany',
    type: 'dms/stream_alert',
    _dataRootRequestAttribute: true,
  },
});
