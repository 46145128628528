import { jsonApiVuex } from '@/common/store/json-api-vuex';
import { groupBy } from 'lodash';
export const dmsVariable = jsonApiVuex(
  'dms/variable',
  {
    getters: {
      userAttributesOnly(state) {
        return state.entities.filter(e => e.rules != null && e.rules.kind != null && e.rules.kind !== 'flags');
      },
      listByExportableUniverseId(state) {
        return groupBy(
          state.entities.filter(v => v.universe.is_exportable),
          'universe.id'
        );
      },
      listByExportableUniverseByIdByCategory(state, getters) {
        const result = {};
        for (const [id, variables] of Object.entries(getters.listByExportableUniverseId)) {
          const group = groupBy(variables, 'category.label');
          result[id] = {
            universeId: id,
            universeName: Object.values(group)[0][0].universe.name,
            universe: Object.values(group)[0][0].universe,
            byCategory: group,
          };
        }
        return result;
      },
    },
  },
  {}
);
