import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { axios } from '@/models';

Vue.use(VueI18n);

export const getLanguages = () => {
  return [
    {
      flag: require('@/assets/images/flags/us.jpg'),
      language: 'en',
      title: 'English',
    },
    {
      flag: require('@/assets/images/flags/french.jpg'),
      language: 'fr',
      title: 'Français',
    },
  ];
};

export const getLanguagesCode = () => {
  return getLanguages().map(l => l.language);
};

//Solve cyclic dependency
// eslint-disable-next-line func-style
export function getLocale() {
  return !i18n || i18n.locale === 'aaa-aaa' ? 'en' : i18n.locale;
}

export const i18n = new VueI18n({
  //Non existing language so that we can lazy load
  locale: 'aaa-aaa',
  fallbackLocale: 'en',
  messages: {},
  pluralizationRules: {
    fr: function(choice) {
      if (choice === 0 || choice === 1) {
        return 0;
      }
      return 1;
    },
  },
});

const loadedLanguages = []; // our default language that is preloaded

const setI18nLanguage = lang => {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
};

export const loadLanguageAsync = async (lang, changeLanguage = true) => {
  if (i18n.locale !== lang && lang !== 'connect') {
    if (!loadedLanguages.includes(lang)) {
      try {
        const version = process.env.VUE_APP_COMMIT_REF ?? 'not-ci';
        const msg = await axios.get(`i18n/${lang}.json?v=${version}`);
        i18n.setLocaleMessage(lang, msg.data);
        loadedLanguages.push(lang);
        if (changeLanguage) {
          setI18nLanguage(lang);
        }
        return lang;
      } catch (e) {
        setI18nLanguage('en');
        return 'en';
      }
    }
    if (changeLanguage) {
      return setI18nLanguage(lang);
    }
  }
  return lang;
};
