import { jsonApiVuex } from '@/common/store/json-api-vuex';

export const dmsVariableCategory = jsonApiVuex(
  'dms/variable_category',
  {
    getters: {
      globalCategory(state) {
        return state.entities.find(c => c.label === 'Global');
      },
    },
  },
  {}
);
