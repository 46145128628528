import { jsonApi } from '../jsonApi';

jsonApi.define('normalized-datalayer/standard-event-type-category', {
  id: null,
  label: '',
  eventTypes: {
    jsonApi: 'hasMany',
    type: 'normalized-datalayer/standard-event-type',
  },
});
