<script>
import Vertical from './vertical';

export default {
  components: {
    Vertical,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<template>
  <div>
    <vertical>
      <router-view />
    </vertical>
  </div>
</template>
