import { detect } from 'detect-browser';
const browser = detect() || { name: 'Jest' };

export const isDevEnvironment = () => {
  const origin = window.location.origin || '';
  return /local/.test(origin);
};

export const state = {
  browser: browser.name,
  parentLocation: { href: '', origin: '', protocol: '', host: '', hostname: '', port: '', search: '', hash: '' },
  isDev: isDevEnvironment(),
};

export const mutations = {
  setParentLocation(state, value) {
    state.parentLocation = value;
  },
};

export const getters = {};

export const actions = {
  userActivity({ state }) {
    window.top.postMessage({ type: 'activity' }, state.parentLocation.origin);
  },
  changeUrl({ state }, url) {
    window.top.postMessage(
      {
        type: 'change-url',
        url,
      },
      state.parentLocation.origin
    );
  },
};

export const namespaced = true;
