<template>
  <div>
    <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

    <div v-if="notification.message" :class="'alert ' + notification.type">
      {{ $t(notification.message) }}
    </div>

    <b-form class="p-2" @submit.prevent="tryToLogIn">
      <b-form-group id="input-group-1" :label="$t('common.email')" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="email"
          type="text"
          :placeholder="$t('account.login.enterEmail')"
          :class="{ 'is-invalid': submitted && $v.email.$error }"
        ></b-form-input>
        <div v-if="submitted && $v.email.$error" class="invalid-feedback">
          <span v-if="!$v.email.required">{{ $t('forms.feedback.emailRequired') }}</span>
          <span v-if="!$v.email.email">{{ $t('forms.feedback.validEmailRequired') }}</span>
        </div>
      </b-form-group>

      <b-form-group id="input-group-2" :label="$t('account.login.password')" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="password"
          type="password"
          :placeholder="$t('account.login.enterPassword')"
          :class="{ 'is-invalid': submitted && $v.password.$error }"
        ></b-form-input>
        <div v-if="submitted && !$v.password.required" class="invalid-feedback">
          {{ $t('forms.feedback.passwordRequired') }}
        </div>
      </b-form-group>
      <div class="mt-3">
        <tc-button-loading
          type="submit"
          :loading="loader"
          class="btn btn-block btn-ca"
          :label="$t('account.login.logIn')"
        />
      </div>
      <p class="text-center mt-4">
        <router-link class="text-primary" :to="{ name: 'forgot' }">
          {{ $t('account.login.forgottenPassword') }}
        </router-link>
      </p>
    </b-form>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators';
import tcButtonLoading from '@/components/buttons/tc-button-loading.vue';

const DEVS = [
  'yassine.delhem@commandersact.com',
  'yassine.zayane@commandersact.com',
  'jean.julien.zeil@commandersact.com',
  'ludovic.boisdefer@commandersact.com',
  'pierre.panajotopoulos@commandersact.com',
  'stephane.puybareau@commandersact.com',
  'frederic.thomas@commandersact.com',
  'sarah.langlais@commandersact.com',
  'ludovic.cheng@commandersact.com',
  'abdelhakim.said@commandersact.com',
  'imen.triki@commandersact.com',
  'antoine.dubois@commandersact.com',
  'gregoire.chollet@commandersact.com',
  'bastien.pemeja@commandersact.com',
  'florent.covin@commandersact.com',
  'anthony.moyart@commandersact.com',
  'timothee.leicht@commandersact.com',
  'romain.szijj@commandersact.com',
  'audrey.marissal@commandersact.com',
  'kevin.capion@commandersact.com',
  'jean.joulia@commandersact.com',
  'max.desplanches@commandersact.com',
];
const CONSULTANTS = [
  'francois.henry@tagcommander.com',
  'christelle.maia@tagcommander.com',
  'christelle.maia@commandersact.com',
  'francois.labonne@commandersact.com',
  'francois.henry@commandersact.com',
  'grazia.corsaro@commandersact.com',
  'alessandro.gambato@commandersact.com',
  'lionel.beilin@commandersact.com',
  'nicolas.naparty@commandersact.com',
  'chloe.gaudichet@commandersact.com',
  'mathieu.defix@commandersact.com',
  'leila.felice@commandersact.com',
  'cyril.bouskila@commandersact.com',
  'stela.yoncheva@commandersact.com',
  'alexandra.rohm@commandersact.com',
  'dora.tirk@commandersact.com',
  'simon.martin@commandersact.com',
  'mehdi.benouada.chaht@commandersact.com',
];

const BETA_TESTERS = [...DEVS, ...CONSULTANTS];

export default {
  components: { tcButtonLoading },
  data() {
    return {
      email: '',
      password: '',
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      loader: false,
      notification: {
        message: '',
        type: '',
      },
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.resetNotification();
      this.submitted = true;
      this.loader = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.loader = false;
        return;
      } else {
        try {
          const user = await this.$store.dispatch('currentUser/logIn', {
            username: this.email,
            password: this.password,
          });
          if (user) {
            // REDIRECT THIS ACCOUNT TO APP-NEXT BETA TEST ENV
            const isBetaTester = BETA_TESTERS.some(testerEmail => testerEmail === user.email.toLowerCase().trim());

            if (isBetaTester) {
              const [subdomain, domain] = window.location.hostname.split('.');
              if (subdomain === 'app' && domain === 'commandersact') {
                window.location.href = `https://app-next.commandersact.com/${window.location.search}`;
                return;
              }
            }
            this.$emit('login-success');
          }
        } catch (e) {
          this.notifyWrongCredential(e.message === 'banned');
        } finally {
          this.loader = false;
        }
      }
    },
    notifyWrongCredential(isBanned) {
      if (isBanned) {
        this.notification.message = 'account.login.userBanned';
        this.notification.type = 'alert-danger';
      } else {
        this.notification.message = 'account.login.invalidLoginCredential';
        this.notification.type = 'alert-danger';
      }
    },
    resetNotification() {
      this.notification.message = '';
      this.notification.type = '';
    },
  },
};
</script>
