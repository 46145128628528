import { jsonApi } from '../jsonApi';

jsonApi.define('dms/segment', {
  public_id: '',
  label: '',
  nb_visitors: 0,
  archived: false,
  updated: '',
  deleted_at: null,
});
