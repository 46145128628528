<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in menuItems">
        <li class="menu-title" v-if="item.isTitle" :key="item.id">
          {{ $t(item.label) }}
        </li>
        <li v-if="!item.isTitle && !item.isLayout" :key="item.id" :class="{ 'mm-active': item.opened }">
          <a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
            @click="toggleOpened(item)"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>

            <!-- Collapsible for multi level -->
            <span>
              {{ splitLabel($t(item.label)).start }}
              <span class="text-nowrap">
                {{ splitLabel($t(item.label)).end }}
                <i v-if="item.accessForbidden || restrictedMenuEntry(item)" class="bx bx-lock-alt icon"></i
              ></span>
            </span>
            <span :class="`badge badge-pill badge-${item.badge.variant} float-right`" v-if="item.badge">{{
              $t(item.badge.text)
            }}</span>
          </a>
          <!-- SINGLE LEVEL -->
          <a v-if="isFullUrl(item)" :href="item.link.href" target="_blank" class="side-nav-link-ref">
            {{ $t(item.label) }}
          </a>
          <router-link
            :to="item.link"
            v-else-if="!hasItems(item)"
            class="side-nav-link-ref"
            :class="{ disabled: isLinkDisabled(item) || restrictedMenuEntry(item), 'mm-active': item.active }"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>
              {{ splitLabel($t(item.label)).start }}
              <span class="text-nowrap">
                {{ splitLabel($t(item.label)).end }}
                <i v-if="isLinkDisabled(item) || restrictedMenuEntry(item)" class="bx bx-lock-alt icon"></i>
              </span>
            </span>
            <span :class="`badge badge-pill badge-${item.badge.variant} float-right`" v-if="item.badge">
              {{ $t(item.badge.text) }}
            </span>
          </router-link>
          <!-- MULTI LEVEL -->
          <b-collapse :visible="item.opened || !sidebarDisplayedState">
            <ul v-if="hasItems(item)" class="sub-menu">
              <li v-for="(subitem, index) of item.subItems" :key="index" :class="{ 'mm-active': subitem.opened }">
                <!-- Level 2 -->
                <a v-if="isFullUrl(subitem)" :href="subitem.link.href" target="_blank" class="side-nav-link-ref">
                  {{ $t(subitem.label) }}
                </a>
                <router-link
                  :to="getLink(subitem)"
                  v-else-if="!hasItems(subitem) && !restrictedMenuEntry(subitem)"
                  class="side-nav-link-ref"
                  :class="{ disabled: isLinkDisabled(subitem), 'mm-active': subitem.active }"
                >
                  {{ splitLabel($t(subitem.label)).start }}
                  <span class="text-nowrap">
                    {{ splitLabel($t(subitem.label)).end }}
                    <i v-if="isLinkDisabled(subitem)" class="bx bx-lock-alt icon"></i>
                  </span>
                </router-link>
                <!-- Level 3 collapsible -->
                <a
                  v-if="hasItems(subitem) && !restrictedMenuEntry(subitem)"
                  class="side-nav-link-a-ref has-arrow"
                  href="javascript:void(0);"
                  @click="toggleOpened(subitem)"
                >
                  {{ $t(subitem.label) }}&nbsp;
                  <span v-if="subitem.accessForbidden"><i class="bx bx-lock-alt icon"></i></span>
                </a>
                <b-collapse :visible="subitem.opened || !sidebarDisplayedState">
                  <ul v-if="hasItems(subitem)" class="sub-menu" aria-expanded="false">
                    <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                      <!-- Level 3  -->
                      <a
                        v-if="isFullUrl(subSubitem)"
                        :href="subSubitem.link.href"
                        target="_blank"
                        class="side-nav-link-ref"
                      >
                        {{ $t(subSubitem.label) }}
                      </a>
                      <router-link
                        :to="subSubitem.link"
                        class="side-nav-link-ref"
                        v-else-if="!hasItems(subSubitem) && !restrictedMenuEntry(subSubitem)"
                        :class="{ disabled: isLinkDisabled(subSubitem), 'mm-active': subSubitem.active }"
                      >
                        {{ splitLabel($t(subSubitem.label)).start }}
                        <span class="text-nowrap">
                          {{ splitLabel($t(subSubitem.label)).end }}
                          <i v-if="isLinkDisabled(subSubitem)" class="bx bx-lock-alt icon"></i>
                        </span>
                      </router-link>
                    </li>
                  </ul>
                </b-collapse>
              </li>
            </ul>
          </b-collapse>
        </li>
      </template>
    </ul>
  </div>
  <!-- Sidebar -->
</template>

<script>
import { menuItems } from './menu';
import { mapState, mapGetters } from 'vuex';
import { metaAuthorization } from '@/common/router/meta-authorization';
/**
 * Sidenav component
 */
export default {
  data() {
    return {
      menuItems: menuItems,
      currentParent: null,
      currentItem: null,
      menu: null,
      menuByLevel: { 0: [], 1: [], 2: [] },
    };
  },
  computed: {
    ...mapState('layout', ['breadcrumbs']),
    ...mapGetters('currentUser', {
      isBackOfficeUser: 'hasBackOfficeAccessRight',
      isDeployTemplateUser: 'hasDeployTemplateRight',
      currentSite: 'currentSite',
    }),
    ...mapState('layout', ['sidebarDisplayedState']),
  },
  watch: {
    $breadcrumbs: {
      handler: 'onBreadcrumbsChange',
    },
    currentSite: {
      handler: 'refreshMenu',
    },
  },
  mounted: function() {
    const init = (level, item) => {
      this.$set(item, 'level', level);
      this.$set(item, 'active', false);
      this.$set(item, 'opened', false);
      this.menuByLevel[level].push(item);

      if (item.subItems) {
        item.subItems.forEach(init.bind(this, level + 1));
      }
    };
    this.menuItems.forEach(init.bind(this, 0));
    this.refreshMenu();
  },
  methods: {
    isLinkDisabled(item) {
      return (item.accessForbidden && !item.dontDisplayPadlockIfNoAccess) || item.link === '/todo';
    },
    getLink(item) {
      return (item.accessForbidden && item.dontDisplayPadlockIfNoAccess) || item.link === '/todo'
        ? { name: 'error-access-not-actived', query: { askedPage: item.link.name } }
        : item.link;
    },
    accessForbidden(item) {
      // beware: metaFn might not have been called at this point
      return !metaAuthorization(this.$router.resolve(item.link)?.resolved?.meta || {});
    },
    subAccessForbidden(items) {
      let every = true;
      for (const item of items) {
        let result = false;
        if (item.link) {
          result = this.accessForbidden(item);
        }
        if (item.subItems) {
          result = this.subAccessForbidden(item.subItems);
        }
        every = every && result;
        this.$set(item, 'accessForbidden', result);
      }
      return every;
    },
    isBackOfficeAccessForbidden(item) {
      return item.backOffice && !this.isBackOfficeUser;
    },
    isDeployTemplateAccessForbidden(item) {
      return item.deployTemplate && !this.isDeployTemplateUser;
    },
    isFullUrl(item) {
      return item.link && item.link.href;
    },
    restrictedMenuEntry: function(item) {
      return this.isBackOfficeAccessForbidden(item) || this.isDeployTemplateAccessForbidden(item);
    },
    splitLabel(text) {
      const splitted = text.split(' ');
      const end = splitted.pop();
      return {
        start: splitted.join(' '),
        end,
      };
    },
    /**
     * Returns true or false if given menu item has a child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    /**
     * creates menu and highlights the matching tree if exists
     *  */
    refreshMenu() {
      this.subAccessForbidden(this.menuItems);
      this.onBreadcrumbsChange(this.$breadcrumbs);
    },
    onBreadcrumbsChange(newBreadcrumbs) {
      const parents = [];
      let currentItem;
      const getCurrentItem = items => {
        for (const item of items) {
          if (item.link?.name) {
            const match = newBreadcrumbs.find(r => r.name === item.link.name);
            if (match) {
              currentItem = item;
              return true;
            }
          }
          if (item.subItems) {
            parents.push(item);
            if (getCurrentItem(item.subItems)) {
              return true;
            } else parents.pop();
          }
        }
        return false;
      };
      getCurrentItem(this.menuItems);
      this.currentParent?.forEach(i => {
        i.opened = false;
        i.active = false;
      });
      if (this.currentItem) this.currentItem.active = false;
      parents.filter(Boolean).forEach(i => {
        i.opened = true;
        i.active = true;
      });

      if (currentItem) currentItem.active = true;
      this.currentParent = parents;
      this.currentItem = currentItem;
    },
    toggleOpened(item) {
      if (!this.sidebarDisplayedState) {
        return;
      }
      if (!item.opened) {
        this.menuByLevel[item.level].forEach(i => (i.opened = false));
      }
      item.opened = !item.opened;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/versions/x/styles/variables.scss';

#sidebar-menu ul li a:hover {
  text-decoration: none;
  color: #1c1d1e;

  i {
    color: #1c1d1e;
  }
}
</style>
