import { jsonApiVuex } from '@/common/store/json-api-vuex';
import { jsonApi } from '@/models';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
const getters = {
  cloudMode(state) {
    return state.entities.filter(integration => integration?.connector?.trigger_type === 'event');
  },
  audience(state) {
    return state.entities.filter(integration => integration?.connector?.trigger_type === 'audience');
  },
  rawData(state) {
    return state.entities.filter(integration => integration?.connector?.trigger_type === 'raw-data');
  },
  deviceMode(/*state*/) {
    // TODO: change this when device mode is supported
    return [];
  },
  allIntegrations(state) {
    return state.entities;
  },
  currentScheduledSettings(state) {
    return {
      cron: state.entity.cron,
      start_date: state.entity.start_date,
      end_date: state.entity.end_date,
      occurence_type: state.entity.occurence_type,
    };
  },
  asyncLoadingStatuses(state) {
    return state.asyncLoadingStatuses;
  },
  isAsyncSaveLoading(state) {
    return state.isAsyncSaveLoading;
  },
};

const mutations = {
  setScheduledSettings(state, params) {
    state.entity.cron = params.cron;
    state.entity.start_date = params.start_date;
    state.entity.end_date = params.end_date;
    state.entity.occurence_type = params.occurence_type;
  },

  pushStreamColumn(state, streamColumn) {
    if (!state.entity.stream_columns) {
      state.entity.stream_columns = Vue.observable([]);
    }
    state.entity.stream_columns.push(streamColumn);
  },
  removeStreamColumn(state, streamColumn) {
    const index = state.entity.stream_columns?.findIndex(s => s === streamColumn);
    if (index >= 0) {
      state.entity.stream_columns.splice(index, 1);
      state.entity.stream_columns.forEach((s, i) => (s.order = i));
    }
  },
  setOrderedStreamColumns(state, streamColumns = []) {
    state.entity.stream_columns = streamColumns;
    state.entity.stream_columns.forEach((s, i) => (s.order = i));
  },
  setAsyncStatuses(state, { ids, isLoading, error }) {
    for (const id of ids) {
      state.asyncLoadingStatuses[id] = { isLoading, error };
    }
    setTimeout(() => {
      // ensure here reactivity is propagated to all components
      state.asyncLoadingStatuses = cloneDeep(state.asyncLoadingStatuses);
    });
  },
  setAsyncSaveLoading(state, isLoading) {
    state.isAsyncSaveLoading = isLoading;
  },
};

const actions = {
  async findAllWithAsyncAssociation(
    { commit, dispatch, state },
    params = { basicInclude: '', associationInclude: '', scrollSize: '' }
  ) {
    const customParams = { ...params };
    delete customParams.basicInclude;
    delete customParams.associationInclude;
    delete customParams.scrollSize;

    const scrollSize = params.scrollSize || 5;
    let filterIds = [];

    await dispatch('findAll', {
      include: params.basicInclude,
      ...customParams,
    });

    const findAndUpdate = filterIds => {
      commit('setAsyncStatuses', { ids: filterIds, isLoading: true });
      return jsonApi
        .findAll('integrations', {
          include: params.associationInclude,
          'filter[id]': filterIds.join(','),
        })
        .then(res => {
          commit('setAsyncStatuses', { ids: filterIds, isLoading: false });
          for (const { id, sources, destinations, health } of res.data) {
            params.associationInclude.includes('health') &&
              commit('_deepSetAttributeById', { id, path: 'health', value: health || null });
            params.associationInclude.includes('sources') &&
              commit('_deepSetAttributeById', { id, path: 'sources', value: sources });
            params.associationInclude.includes('destinations') &&
              commit('_deepSetAttributeById', { id, path: 'destinations', value: destinations });
          }
        })
        .catch(e => {
          commit('setAsyncStatuses', { ids: filterIds, isLoading: false, error: e });
          console.error(`Error loading async association for ${filterIds}`, e);
        });
    };

    for (const entity of state.entities) {
      filterIds.push(entity.id);
      if (filterIds.length === scrollSize) {
        findAndUpdate(filterIds);
        filterIds = [];
      }
    }
    if (filterIds.length) {
      findAndUpdate(filterIds);
    }

    return state.entities;
  },
};

export const integration = jsonApiVuex(
  'integration',
  {
    state: { asyncLoadingStatuses: {}, isAsyncSaveLoading: false },
    getters,
    mutations,
    actions,
  },
  {
    nested: { issues: 'delivery/issue', trend: 'delivery/trend', health: 'delivery/health' },
    moduleName: 'integration/integration',
  }
);
