import { jsonApiVuex } from '@/common/store/json-api-vuex';
import { actions } from '@/store/common/destination-builder/actions';
import { getters as commonGetters } from '@/store/common/destination-builder/getters';
import { mutations } from '@/store/common/destination-builder/mutations';

const getters = {
  ...commonGetters,
};

export const destinationBuilderTemplate = jsonApiVuex('destination-builder-template', { actions, getters, mutations });
