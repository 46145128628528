import { idGenerator } from '@/common/utilities/id-generator';
import { isNumber } from 'lodash';
const idIterator = idGenerator('notification');

const variantPayload = variant => (state, content) => {
  let text;
  let timeout = 5000;
  let key;
  if (typeof content === 'object') {
    text = content.text;
    if (content.timeout === false) {
      timeout = false;
    } else {
      timeout = isNumber(content.timeout) ? content.timeout : timeout;
    }
    key = content.key;
  } else {
    text = content;
  }

  const notification = {
    variant,
    content: text,
    id: idIterator.next().value,
    key,
  };
  state.list.push(notification);

  if (timeout) {
    setTimeout(() => {
      const index = state.list.findIndex(n => n === notification);
      if (index >= 0) {
        state.list.splice(index, 1);
      }
    }, timeout);
  }
};

export const state = () => ({
  list: [],
});

export const mutations = {
  push(state, payload) {
    state.list.push(payload);
  },
  pushDanger: variantPayload('danger'),
  pushInfo: variantPayload('info'),
  pushSuccess: variantPayload('success'),
  pushWarning: variantPayload('warning'),
  pushPrimary: variantPayload('primary'),
  removeByIndex(state, index) {
    state.list.splice(index, 1);
  },
  removeByKey(state, key) {
    const index = state.list.findIndex(n => n.key === key);
    if (index >= 0) {
      state.list.splice(index, 1);
    }
  },
};

export const namespaced = true;
