import { jsonApi } from '../jsonApi';

jsonApi.define('billing', {
  customer: {
    jsonApi: 'hasOne',
    type: 'customer',
  },
  sites: {
    jsonApi: 'hasMany',
    type: 'site',
  },
});
