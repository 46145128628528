<template>
  <div class="page-content">
    <div class="container-fluid">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Your Cookieless Marketing Platform</li>
      </ol>
      <img
        decoding="async"
        src="@/assets/images/home/platform-schema.svg"
        class="el-image"
        alt=""
        loading="lazy"
        width="75%"
        height="75%"
      />
      <div class="row w-75">
        <div class="col text-center">
          <router-link :to="{ name: `source-catalog` }" v-if="hasRight('create', 'source')">
            <button type="button" class="btn btn-primary font-weight-bold create-btn">
              {{ $t(`source.overview.addIntegration`) }}
            </button>
          </router-link>
        </div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col text-center">
          <router-link :to="{ name: `destination-catalog` }" v-if="hasRight('create', 'destination')">
            <button type="button" class="btn btn-primary font-weight-bold create-btn">
              {{ $t(`destination.overview.addIntegration`) }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { rightsMixin } from '@/common/integrations/rights-mixin';
export default {
  components: {},
  mixins: [rightsMixin],
};
</script>
