import { axiosApi } from '@/models';
import { getApiHostname } from '~version/models/headers-handler';
import { getSiteId } from '~version/models/headers-handler';
import { i18n } from '@/i18n';
import urlJoin from 'url-join';
import { deepSet } from '@/common/utilities/deep-set';
import { isString, isEmpty } from 'lodash';
export const state = {
  testData: {
    settings: {
      parameters: {},
    },
  },
  console: [],
};

export const mutations = {
  addToConsole(state, payload) {
    const error = isEmpty(payload.error) ? { message: payload?.detail } : payload.error;
    const status = payload.status && isString(payload.status) ? payload.status.toLowerCase() : payload.status;
    const detail = payload.detail || null;
    const logs = payload?.logs;
    let variant = null;
    switch (status) {
      case 'success':
        variant = 'success';
        break;
      case 'error':
      case 'rejected':
        variant = 'danger';
        break;
      default:
        variant = 'info';
        break;
    }
    state.console.push({ status, detail, error, logs, variant });
  },
  _clearEntityError() {
    //Nothing to do, compatibility with form generator
  },
  deepSetTestData(state, param) {
    //Used by the form generator to configure the test destination
    const path = [...(isString(param.path) ? param.path.split('.') : param.path)];
    deepSet(state.testData, path, param.value);
  },
  reset(state) {
    state.testData = {
      settings: {
        parameters: {},
      },
    };
    state.console = [];
  },
};

export const actions = {
  async run({ commit, state }, data) {
    const payload = {
      type: 'destination-builder-test',
      code: data.code,
      event: data.event,
      triggerType: data.triggerType,
      context: state.testData.settings.context,
      parameters: state.testData.settings.parameters,
      form: data.form,
    };

    const url = urlJoin(getApiHostname(), `/v2/${getSiteId()}/online-tester/run`);
    await axiosApi
      .post(url, payload)
      .then(response => {
        commit('addToConsole', response.data);
      })
      .catch(e => {
        const response = e.response;
        const messageDetails =
          response.status == 422
            ? { details: `${response.status} - ${response.data.errors[0].detail}` }
            : { details: `${response.status} - ${response.data.message}` };
        commit(
          'notifications/pushDanger',
          { text: i18n.t('errors.apiError', messageDetails), timeout: 10000 },
          { root: true }
        );
      });
  },
};

export const namespaced = true;
