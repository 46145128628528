import { jsonApi } from '../jsonApi';

jsonApi.define('billing-subscription-period', {
  id_customer: '',
  begin_at: '',
  end_at: '',
  credit: '',
  created_at: '',
  updated_at: '',
});
