import { getStaticHostname } from '@/versions/x/models/headers-handler';
export const computeStaticPath = (path, trim) => {
  if (trim && path) {
    path = path.replace(/^\/{0,2}static(.*?)\//, '');
  }
  return `${getStaticHostname()}/${path}`;
};
const handler = (el, binding) => {
  const attr = binding.arg ?? 'src';
  el[attr] = computeStaticPath(binding.value, binding.modifiers.trim);
};

export const staticDirective = {
  bind: handler,
  update: handler,
};
