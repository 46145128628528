<script>
import { router } from '~version/router';
import NavBar from '@/components/nav-bar';
import SideBar from '@/components/side-bar';
import { mapState } from 'vuex';

/**
 * Vertical layout
 */
export default {
  components: { NavBar, SideBar },
  data() {
    return {
      isMenuCondensed: false,
    };
  },
  computed: {
    ...mapState('layout', ['sidebarDisplayedState']),
  },
  created: () => {
    document.body.removeAttribute('data-layout', 'horizontal');
    document.body.removeAttribute('data-topbar', 'dark');
    document.body.removeAttribute('data-layout-size', 'boxed');
    document.body.classList.remove('auth-body-bg');
  },
  watch: {
    sidebarDisplayedState: {
      handler(newsidebarDisplayedState) {
        if (newsidebarDisplayedState === false) {
          document.body.classList.add('sidebar-enable');
          document.body.classList.add('vertical-collpsed');
        } else {
          document.body.classList.remove('sidebar-enable');
          document.body.classList.remove('vertical-collpsed');
        }
      },
      immediate: true,
    },
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable');

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable');
          document.body.classList.remove('vertical-collpsed');
        });
        document.body.classList.toggle('vertical-collpsed');
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable');
        });
        document.body.classList.remove('vertical-collpsed');
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle('right-bar-enabled');
    },
    hideRightSidebar() {
      document.body.classList.remove('right-bar-enabled');
    },
  },
  mounted() {
    if (this.loader === true) {
      document.getElementById('preloader').style.display = 'block';
      document.getElementById('status').style.display = 'block';

      setTimeout(function() {
        document.getElementById('preloader').style.display = 'none';
        document.getElementById('status').style.display = 'none';
      }, 2500);
    } else {
      document.getElementById('preloader').style.display = 'none';
      document.getElementById('status').style.display = 'none';
    }
  },
};
</script>

<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
    <div id="layout-wrapper">
      <NavBar />
      <SideBar :is-condensed="isMenuCondensed" type="light" width="fluid" />
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content">
        <!-- Start Content-->
        <slot />
      </div>
    </div>
  </div>
</template>
