import { jsonApi } from '../jsonApi';

jsonApi.define('normalized-datalayer/event-property', {
  id: null,
  name: '',
  label: '',
  type: '',
  structure_type: '',
  items_type: '',
  format: null,
  schema: {},
  created: '',
  updated: '',
  description: '',
  stored: false,
  pii: false,
  is_custom: false,
  required: false,
  reference: {
    jsonApi: 'hasOne',
    type: 'normalized-datalayer/standard-event-property',
  },
});
