import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faTimesCircle,
  faSearch,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faTrash,
  faEdit,
  faListOl,
  faHashtag,
  faFlag,
  faChartBar,
  faCopy,
  faCheck,
  faCheckCircle,
  faCircle,
  faInfoCircle,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faBars,
  faThLarge,
  faRecycle,
  faTrashRestore,
  faBan,
  faCode,
  faPlayCircle,
  faClipboard,
  faClipboardCheck,
  faPlus,
  faMinus,
  faSearchPlus,
  faSearchMinus,
  faExternalLinkAlt,
  faPlay,
  faClock,
  faTachometerAlt,
  faFilter,
  faUsers,
  faUsersCog,
  faUserCheck,
  faSync,
  faBookReader,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faTimesCircle,
  faSearch,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faTrash,
  faEdit,
  faListOl,
  faHashtag,
  faFlag,
  faChartBar,
  faCopy,
  faCheck,
  faCheckCircle,
  faCircle,
  faInfoCircle,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faBars,
  faThLarge,
  faRecycle,
  faTrashRestore,
  faBan,
  faCode,
  faPlayCircle,
  faClipboard,
  faClipboardCheck,
  faPlus,
  faMinus,
  faSearchPlus,
  faSearchMinus,
  faExternalLinkAlt,
  faPlay,
  faClock,
  faTachometerAlt,
  faFilter,
  faUsers,
  faUsersCog,
  faUserCheck,
  faSync,
  faBookReader
);
Vue.component('fa', FontAwesomeIcon);
