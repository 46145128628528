<template>
  <div id="app">
    <router-view />
    <div v-show="isLoading" class="progress-bar">
      <div class="progress-bar-value"></div>
    </div>
    <div id="app-notifications">
      <div class="row">
        <div class="col"></div>
        <div class="col"></div>
        <div class="col mr-4 mb-3">
          <b-alert
            v-for="(notification, index) in notifications"
            :key="notification.id"
            show
            fade
            dismissible
            @dismissed="dismissNotification(index)"
            :variant="notification.variant"
            >{{ notification.content }}</b-alert
          >
          <b-alert :show="displayNewVersionRefresh" variant="success">
            {{ $t('common.newVersionDeployed') }}
            <button class="btn btn-sm btn-primary" type="button" @click="refresh">
              {{ $t('common.refresh') }}
            </button>
          </b-alert>
        </div>
      </div>
    </div>
    <b-modal
      id="login-modal"
      v-model="$store.state.currentUser.timeout"
      :title="$t('account.login.title')"
      no-close-on-backdrop
      no-close-on-esc
      ok-only
      ok-title="logout"
      hide-footer
      hide-header-close
    >
      <b-alert show variant="warning">{{ $t('account.login.expiredSessionAlert') }}</b-alert>
      <tc-login-sso
        v-if="$store.state.currentUser.idp && $store.state.currentUser.idp != ''"
        :idp="$store.state.currentUser.idp"
      />
      <tc-login v-else />
    </b-modal>
    <script type="application/javascript" v-if="isProd">
      window.tc_vars ||= [];
      window.tc_vars.environment ||= {};
      window.tc_vars.user ||= {};
      window.tc_vars.features ||= {};
    </script>
    <script
      type="application/javascript"
      v-if="isProd"
      src="https://cdn.tagcommander.com/18/tc_CommandersAct_39.js"
    ></script>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import TcLogin from '@/components/forms/tc-login';
import TcLoginSso from '@/components/forms/tc-login-sso';
import { globalErrorHandlerMixin } from '@/common/global-error-handler-mixin';
import { eventBus } from 'shared/event-bus';
import { authenticationMixin } from '@/common/security/authentication-mixin';

export default {
  mixins: [globalErrorHandlerMixin, authenticationMixin],
  components: { TcLogin, TcLoginSso },
  data() {
    return {
      displayNewVersionRefresh: false,
    };
  },
  computed: {
    ...mapState('notifications', {
      notifications: state => state.list,
    }),
    ...mapGetters('layout', ['isLoading']),
    isProd() {
      // return true; // uncomment to test locally
      const host = window.location.hostname.split('.');
      return !(host[1] === 'commandersdev' || host[1] === 'local' || window.location.port !== '');
    },
  },
  watch: {
    $route(to) {
      const setTitle = title => {
        const formattedTitle = title ? `${title} - ` : '';
        const formattedSite = this.$store.getters['currentUser/currentSite']?.name
          ? `${this.$store.getters['currentUser/currentSite'].name} - `
          : '';
        document.title = `${formattedTitle}${formattedSite}${this.$t('common.companyName')}`;
      };
      if (to.meta.titleI18n && to.meta.title) {
        console.error('Too many titles!');
        return;
      }

      if (to.meta.titleI18n) {
        setTitle(this.$t(to.meta.titleI18n));
        return;
      }

      if (to.meta.title) {
        setTitle(to.meta.title);
        return;
      }
      setTitle();
    },
  },
  created() {
    this.progressStart();
  },
  mounted() {
    this.checkPlatformSession();
    document.addEventListener('click', this.checkPlatformSession);
    document.addEventListener('keydown', this.checkPlatformSession);
    this.progressFinish();
    eventBus.$on('progress-start', () => {
      this.progressStart();
    });
    eventBus.$on('progress-finish', () => {
      this.progressFinish();
    });
    eventBus.$on('new-webapp-version', () => {
      this.displayNewVersionRefresh = true;
    });
    if (this.isProd) {
      window.addEventListener('click', this.dispatchClickToContainer);
    }
  },
  methods: {
    ...mapMutations('notifications', {
      dismissNotification: 'removeByIndex',
    }),
    ...mapMutations('layout', ['progressStart', 'progressFinish']),
    dispatchClickToContainer(ev) {
      const isLinkOrButton = ev.target.closest('a', 'button') != null;
      if (isLinkOrButton && window.tC?.container?.reload) {
        window.tC.container.reload({ events: { click: [ev.target, {}] } });
      }
    },
    refresh() {
      location.reload(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/versions/x/styles/variables.scss';

#app-notifications {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.progress-bar {
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0px;
  z-index: 9999;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0), $primary, rgba(255, 0, 0, 0));
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }

  40% {
    transform: translateX(0) scaleX(0.4);
  }

  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
</style>
