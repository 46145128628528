/**
 * This file is based on:
 * https://github.com/twg/devour/blob/53195b463587cdbb2a69bedef1bd370fa1ff5778/src/middleware/json-api/req-post.js
 */

import serialize from 'devour-client/lib/middleware/json-api/_serialize';
import { handleDataRootRequestAttribute } from '@/models/devour-middleware/_handle-data-root-request-attribute';
export default {
  name: 'POST-CUSTOM',
  req: payload => {
    const jsonApi = payload.jsonApi;
    if (payload.req.method === 'POST') {
      payload.req.headers = {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      };

      if (payload.req.data.constructor === Array) {
        payload.req.data = {
          data: serialize.collection.call(jsonApi, payload.req.model, payload.req.data),
          meta: payload.req.meta,
        };
      } else {
        payload.req.data = {
          data: {
            ...serialize.resource.call(jsonApi, payload.req.model, payload.req.data),
            ...handleDataRootRequestAttribute(jsonApi, payload),
          },
          meta: payload.req.meta,
        };
      }
    }

    return payload;
  },
};
