import { jsonApi } from '../../jsonApi';

jsonApi.define('health/alert', {
  id: null,
  category: '',
  parameters: {},
  model_id: null,
  is_enable: true,
  last_check_at: null,
  last_check_value: null,
  created_at: null,
  updated_at: null,
  creator: {
    jsonApi: 'hasOne',
    type: 'user',
  },
  notifications: {
    jsonApi: 'hasMany',
    type: 'health/alert/notification',
  },
});
