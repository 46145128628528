import { jsonApi } from '../jsonApi';

jsonApi.define('monitoring/task', {
  label: '',
  type: '',
  connector: '',
  cron: '',
  occurrence_type: '',
  task_type: '',
});
