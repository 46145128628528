import { jsonApiVuex } from '@/common/store/json-api-vuex';
import { getApiHostname } from '~version/models/headers-handler';
import { jsonApi } from '@/models';
import urlJoin from 'url-join';

export const customer = jsonApiVuex(
  'customer',
  {
    actions: {
      async findCustomerWithBillingMetrics() {
        const url = urlJoin(getApiHostname(), `/v2/customers/${this.customerId}`);
        const { data } = await jsonApi.request(url, 'GET', {
          include: 'sites',
        });
        return data;
      },
    },
  },
  {}
);
