import { jsonApi } from '../jsonApi';

// aka "Weather Report"
jsonApi.define('events/stats/weather', {
  id: null,
  range: {
    from: null,
    to: null,
  },
  activity: {
    today: 0,
    yesterday: 0,
    lastWeek: 0,
    lastMonth: 0,
  },
  total: 0,
  success: 0,
  filter: 0,
  warn: 0,
  fail: 0,
});
