import { jsonApi } from '../../jsonApi';

jsonApi.define('health/notification', {
  id: null,
  alert_id: null,
  frequency: null,
  channel: '',
  channel_settings: {},
  last_notified_at: null,
});
