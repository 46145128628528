import Vue from 'vue';
import Router from 'vue-router';
import HomePage from '@/views/home.vue';
import ErrorPage from '@/views/error';
import ResetPassword from '@/views/account/reset-password';
import ErrorAccessDinedPlatform from '@/views/error-access-denied-platform';
import { authorizationGuard } from '@/common/router/authorization-guard';
import { metaFnGuard } from '@/common/router/meta-fn-guard';
import { store } from '@/store';
import { checkSessionGuard } from '@/common/router/check-session-guard';
import { passwordExpiredGuard } from '@/common/router/password-expired-guard';
import { containerReloadGuard } from '@/common/router/container-reload-guard';
import { autoCacheFlushGuard } from '@/common/router/auto-cache-flush-guard';

import Main from '@/components/layouts/main';
import Transient from '@/components/layouts/transient';
import SymfonyFrame from '@/components/layouts/symfony-frame';
import { destination } from '@/versions/x/router/destination';
import { source } from '@/versions/x/router/source';
import { dataGovernance } from '@/versions/x/router/data-governance';
import { normalizedDatalayer, normalizedDatalayerCatalogue } from '@/versions/x/router/normalized-datalayer';
import { enrichment } from '@/versions/x/router/enrichment';
import { backOffice } from '@/versions/x/router/back-office';
import { loadLanguageAsync } from '@/i18n';
import { setSiteId, getV4Url } from '@/versions/x/models/headers-handler';

Vue.use(Router);

export const router = new Router({
  //base,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/error/:error',
      name: 'error',
      component: ErrorPage,
      props: true,
      meta: {
        authorizationSkip: true,
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      props: true,
      meta: {
        authorizationSkip: true,
      },
    },
    {
      path: '/',
      alias: '/login',
      name: 'login',
      component: () => import('@/views/account/login'),
      meta: {
        authorizationSkip: true,
        titleI18n: 'account.login.title',
      },
      async beforeEnter(routeTo, routeFrom, next) {
        const session = await store.dispatch('currentUser/checkPlatformSession');
        // If the user is already logged in
        if (session === '2FA') {
          store.commit('layout/routerProgressFinish');
          next(false);
        } else if (session && session !== 'Forbidden') {
          store.commit('layout/routerProgressFinish');
          next(false);
          store.dispatch('currentUser/navigateToSitePrefix');
        } else {
          // Continue to the login page
          next();
        }
      },
    },
    {
      path: '/sso/:idp',
      name: 'sso',
      component: () => import('@/views/account/sso.vue'),
      props: true,
      meta: {
        authorizationSkip: true,
        titleI18n: 'account.login.ssoReconnect',
      },
      async beforeEnter(routeTo, routeFrom, next) {
        const session = await store.dispatch('currentUser/checkPlatformSession');
        // If the user is already logged in
        if (session && session !== 'Forbidden') {
          store.commit('layout/routerProgressFinish');
          next(false);
          store.dispatch('currentUser/navigateToSitePrefix');
        } else {
          // Continue to the login page
          next();
        }
      },
    },
    {
      path: '/two-factor',
      name: 'two-factor',
      component: () => import('@/views/account/two-factor'),
      meta: {
        authorizationSkip: true,
        titleI18n: 'account.login.title',
      },
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import('@/views/account/forgot'),
      meta: {
        authorizationSkip: true,
        titleI18n: 'account.forgot.title',
      },
    },
    {
      path: '/error-access-denied-platform/:error',
      name: 'error-access-denied-platform',
      component: ErrorAccessDinedPlatform,
      props: true,
      meta: {
        authorizationSkip: true,
      },
    },
    {
      path: '/:product/monitoring/',
      name: 'dms-monitoring',
      component: () => import(/* webpackChunkName: "dms-monitoring" */ '@/views/monitoring.vue'),
      props: true,
      meta: {
        staffOnly: true,
      },
    },
    {
      path: 'site-manager/',
      name: 'site-manager',
      beforeEnter(to, from, next) {
        window.open(getV4Url({ section: 'options-manager', page: 'sitesmanager' }), '_blank');
        next(false);
      },
    },
    {
      path: 'superusers-bo/',
      name: 'superusers-bo',
      beforeEnter(to, from, next) {
        window.open(getV4Url({ section: 'options-superusers', page: 'tagtemplates' }), '_blank');
        next(false);
      },
    },
    backOffice,
    {
      path: '/:locale/:siteId',
      name: 'sitePrefix',
      component: Main,
      beforeEnter(to, from, next) {
        const { locale } = to.params;
        loadLanguageAsync(locale);
        next();
      },
      meta: {
        breadcrumb: null,
      },
      children: [
        {
          path: '',
          name: 'homepage',
          component: HomePage,
        },
        source,
        destination,
        enrichment,
        {
          path: 'error-access-not-actived/',
          name: 'error-access-not-actived',
          component: () =>
            import(/* webpackChunkName: "trust-cookie-scanner" */ '@/views/error-access-not-actived.vue'),
        },
        {
          path: 'error-ip-not-allowed/',
          name: 'error-ip-not-allowed',
          component: () => import(/* webpackChunkName: "error-ip-not-allowed" */ '@/views/error-ip-not-allowed.vue'),
        },
        {
          path: 'health/',
          name: 'health',
          component: Transient,
          meta: {
            breadcrumb: 'menuItems.integrations.health.title',
            staffOnly: true,
          },
          children: [
            {
              path: 'sources-data-quality/',
              name: 'sources-data-quality',
              component: () =>
                import(
                  /* webpackChunkName: "integrations-overview" */ '@/views/integration/monitoring/sources-data-quality.page.vue'
                ),
              props: {
                overviewType: 'source',
              },
              meta: {
                breadcrumb: 'menuItems.integrations.health.list.sourcesDataQuality',
                can: 'view.Tms|view.DmsStream',
              },
              async beforeEnter(to, from, next) {
                await store.dispatch('integration/environment/findAllCached', {});
                await store.dispatch('integration/integration/findAllWithAsyncAssociation', {
                  basicInclude: 'connector, environment',
                  associationInclude: 'sources, sources.connector',
                  scrollSize: 5,
                  filter: {
                    types: 'source',
                  },
                });
                next();
              },
            },
            {
              path: 'dms/monitoring/',
              name: 'integrations-monitoring',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/data/monitoring',
                can: 'view.DmsStream',
              },
            },
            {
              path: 'cookie-scanner/',
              name: 'trust-cookie-scanner-edit',
              component: () =>
                import(
                  /* webpackChunkName: "trust-cookie-scanner" */ '@/views/trust/cookie-scanner/cookie-scanner-edit.vue'
                ),
              children: [
                {
                  meta: {
                    showModal: true,
                  },
                  path: ':create/',
                  name: 'trust-cookie-scanner-create-modal',
                  component: () =>
                    import(
                      /* webpackChunkName: "trust-cookie-scanner" */ '@/views/trust/cookie-scanner/cookie-edit-modal.vue'
                    ),
                  props: true,
                  pathToRegexpOptions: { strict: true },
                },
                {
                  meta: {
                    showModal: true,
                  },
                  path: ':cookieId/:langId',
                  name: 'trust-cookie-scanner-edit-modal',
                  component: () =>
                    import(
                      /* webpackChunkName: "trust-cookie-scanner" */ '@/views/trust/cookie-scanner/cookie-edit-modal.vue'
                    ),
                  props: true,
                  pathToRegexpOptions: { strict: true },
                },
              ],
            },
            {
              path: 'cookie-scanner/deploy',
              name: 'trust-cookie-scanner-deploy',
              component: () =>
                import(
                  /* webpackChunkName: "trust-cookie-scanner" */ '@/views/trust/cookie-scanner/cookie-scanner-deploy.vue'
                ),
              meta: {
                breadcrumb: null,
              },
            },
            {
              path: 'tagperf/',
              name: 'tags-performance',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/containers/tagperf',
              },
            },
            {
              path: 'piggybacking/',
              name: 'piggybacking',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/containers/tagperf/tags-hierarchy',
              },
            },
            {
              path: 'web-container-stats/',
              name: 'web-container-stats',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/containers/summary',
              },
            },
            {
              path: 'deduplication-by-conversions/',
              name: 'deduplication-by-conversions',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/containers/deduplication/reportbyconversions',
              },
            },
            {
              path: 'deduplication-by-tags/',
              name: 'deduplication-by-tags',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/containers/deduplication/byTag',
              },
            },
          ],
        },
        {
          path: 'customers/',
          name: 'customers',
          component: Transient,
          meta: {
            breadcrumb: null,
            staffOnly: true,
          },
          children: [
            {
              path: 'segments/',
              name: 'segments',
              meta: {
                titleI18n: 'dms.segments.title',
                symfonyPath: '/data/segmentations',
              },
              component: SymfonyFrame,
            },
            {
              path: 'segment-stats/',
              name: 'segment-stats',
              meta: {
                titleI18n: 'dms.segmentsStats.title',
                symfonyPath: '/data/statistics',
              },
              component: SymfonyFrame,
            },
            {
              path: 'segment-overlap',
              name: 'segment-overlap',
              meta: {
                titleI18n: 'dms.audienceOverlap.title',
              },
              component: () =>
                import(/* webpackChunkName: "dms-audience-overlap" */ '@/views/dms/audience-overlap.vue'),
              props: true,
            },
            {
              path: 'segment-dashboard',
              name: 'segment-dashboard',
              meta: {
                symfonyPath: '/data/analytics',
              },
              component: SymfonyFrame,
            },
            {
              path: 'identity-resolution',
              name: 'fuse',
              component: Transient,
              children: [
                {
                  path: 'activation',
                  name: 'fuse-activation',
                  component: () => import(/* webpackChunkName: "fuse" */ '@/views/fuse/activation.vue'),
                  props: true,
                  meta: {
                    staffOnly: true,
                    authorization: [{ siteParametersAccess: 'fuse2' }, { can: 'activate.Fuse' }],
                  },
                },
                {
                  path: 'migration',
                  name: 'fuse-migration',
                  component: () => import(/* webpackChunkName: "fuse" */ '@/views/fuse/migration.vue'),
                  props: true,
                  meta: {
                    staffOnly: true,
                    authorization: [{ siteParametersAccess: 'fuse2' }, { can: 'activate.Fuse' }],
                  },
                },
                {
                  path: 'statistics',
                  name: 'fuse-statistics',
                  meta: {
                    symfonyPath: '/fuse/statistics',
                    staffOnly: true,
                    authorization: [{ siteParametersAccess: 'fuse2' }],
                  },
                  component: SymfonyFrame,
                },
              ],
            },
          ],
        },
        {
          path: 'campaign-analysis/',
          name: 'campaign-analysis',
          component: Transient,
          meta: {
            breadcrumb: null,
          },
          children: [
            {
              path: 'campaign-performance/:streamId?',
              name: 'campaign-performance-dashboard',
              meta: {
                titleI18n: 'dms.campaignPerformance.title',
                breadcrumb: null,
                siteParametersAccess: 'dms_has_campaign_performance',
              },
              component: () =>
                import(/* webpackChunkName: "campaign-performance" */ '@/views/dms/campaign-performance-dashboard.vue'),
              props(route) {
                const props = { ...route.params };
                props.streamId = Number(props.streamId);
                return props;
              },
            },
            {
              path: 'live-report-builder/',
              name: 'live-report-builder',
              meta: {
                symfonyPath: '/campaign/live-report-builder/reports',
              },
              component: SymfonyFrame,
            },
            {
              path: 'sunburst/',
              name: 'sunburst',
              meta: {
                symfonyPath: '/campaign/sunburst',
              },
              component: SymfonyFrame,
            },
            {
              path: 'exports/',
              name: 'exports',
              meta: {
                symfonyPath: '/export/report',
              },
              component: SymfonyFrame,
            },
            {
              path: 'raw-data/',
              name: 'raw-data',
              meta: {
                symfonyPath: '/campaign/export/raw',
              },
              component: SymfonyFrame,
            },
            {
              path: 'conversions/',
              name: 'conversions',
              component: Transient,
              meta: {
                breadcrumb: null,
              },
              children: [
                {
                  path: 'reports/',
                  name: 'conversion-details',
                  meta: {
                    symfonyPath: '/campaign/conversions/report',
                  },
                  component: SymfonyFrame,
                },
                {
                  path: 'customer-journey/',
                  name: 'customer-journey',
                  meta: {
                    symfonyPath: '/campaign/customer/journey',
                  },
                  component: SymfonyFrame,
                },

                {
                  path: 'campaign-overlap/',
                  name: 'campaign-overlap',
                  meta: {
                    symfonyPath: '/campaign/overlap',
                  },
                  component: SymfonyFrame,
                },
              ],
            },
            {
              path: 'contributive-analysis/',
              name: 'contributive-analysis',
              meta: {
                symfonyPath: '/campaign/contributive-analysis',
              },
              component: SymfonyFrame,
            },
          ],
        },
        {
          path: 'consent-analysis',
          name: 'consent-dashboard',
          meta: {
            symfonyPath: '/containers/privacy/dashboard',
          },
          component: SymfonyFrame,
        },
        {
          path: 'data-management',
          name: 'data-management',
          component: Transient,
          meta: {
            breadcrumb: null,
          },
          children: [
            normalizedDatalayer,
            normalizedDatalayerCatalogue,
            {
              path: 'data-cleansing',
              name: 'data-cleansing',
              component: Transient,
              meta: {
                breadcrumb: 'cleansing.overview.breadcrumb',
              },
              children: [
                {
                  path: '/',
                  name: 'cleansing-transformation-overview',
                  component: () =>
                    import(
                      /* webpackChunkName: "cleansing-transformation-overview" */ '@/views/data-management/cleansing-transformation/overview.vue'
                    ),
                  props: true,
                  meta: {
                    breadcrumb: null,
                  },
                },
                {
                  path: 'new-transformation',
                  name: 'new-transformation',
                  component: Transient,
                  props: true,
                  meta: {
                    breadcrumb: 'cleansing.new-transformation.breadcrumb',
                  },
                  children: [
                    {
                      path: '/',
                      name: 'new-cleansing-transformation',
                      component: () =>
                        import(
                          /* webpackChunkName: "new-cleansing-transformation" */ '@/views/data-management/cleansing-transformation/new-transformation.vue'
                        ),
                      props: true,
                      meta: {
                        breadcrumb: null,
                      },
                    },
                    {
                      path: 'modify-properties',
                      name: 'cleansing-transformation-modify-properties',
                      component: () =>
                        import(
                          /* webpackChunkName: "edit-cleansing-transformation" */ '@/views/data-management/cleansing-transformation/edit.vue'
                        ),
                      props: { createMode: true, transformationType: 'modify_properties' },
                      meta: {
                        breadcrumb: 'cleansing.modify_properties.breadcrumb',
                      },
                      async beforeEnter(to, from, next) {
                        await store.commit('integration/cleansingTransformation/_clearCurrentEntity');
                        next();
                      },
                    },
                    {
                      path: 'rename-event',
                      name: 'cleansing-transformation-rename-event',
                      component: () =>
                        import(
                          /* webpackChunkName: "edit-cleansing-transformations" */ '@/views/data-management/cleansing-transformation/edit.vue'
                        ),
                      props: { createMode: true, transformationType: 'rename_event', matchingConditionButton: true },
                      meta: {
                        breadcrumb: 'cleansing.rename_event.breadcrumb',
                      },
                      async beforeEnter(to, from, next) {
                        await store.commit('integration/cleansingTransformation/_clearCurrentEntity');
                        next();
                      },
                    },
                  ],
                },
                {
                  path: ':id(\\d+)',
                  name: 'edit',
                  async beforeEnter(to, from, next) {
                    await store.dispatch('integration/cleansingTransformation/loadCurrent', {
                      id: to.params.id,
                      include:
                        'sources,sources.connector,sources.destinations,sources.environment,sources.model,sources.destinations.connector',
                      rangeType: 'relative',
                      from: 86400,
                      to: 0,
                      eventCount: to.params.event_count,
                    });
                    next();
                  },
                  meta: {
                    breadcrumb: () => ({
                      label: store.state.integration.cleansingTransformation.entity.label,
                    }),
                  },
                  props: route => {
                    return {
                      createMode: false,
                      transformationType: route.params.transformationType,
                    };
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "edit-cleansing-transformation" */ '@/views/data-management/cleansing-transformation/edit.vue'
                    ),
                },
              ],
            },
            {
              path: 'web-datalayer/',
              name: 'web-datalayer',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/containers/options',
              },
            },
            {
              path: 'events-collection/',
              name: 'events-collection',
              component: () =>
                import(/* webpackChunkName: "events-collection" */ '@/views/data-management/events-collection.vue'),
              meta: {
                breadcrumb: 'events-collection.title',
                titleI18n: 'events-collection.title',
              },
            },
            {
              path: 'web-deduplication/',
              name: 'web-deduplication',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/containers/deduplication//channels-and-sources-definition',
              },
            },
            {
              path: 'campaign-settings/',
              name: 'campaign-settings',
              component: Transient,
              meta: {
                breadcrumb: null,
              },
              children: [
                {
                  path: 'channel-identification/',
                  name: 'channel-identification',
                  meta: {
                    symfonyPath: '/campaign/options/channel/identification',
                  },
                  component: SymfonyFrame,
                },
                {
                  path: 'ip/',
                  name: 'ip',
                  meta: {
                    symfonyPath: '/campaign/options/ip',
                  },
                  component: SymfonyFrame,
                },
                {
                  path: 'dimensions/',
                  name: 'dimensions',
                  meta: {
                    symfonyPath: '/campaign/options/entities',
                  },
                  component: SymfonyFrame,
                },
                {
                  path: 'cost/',
                  name: 'cost',
                  meta: {
                    symfonyPath: '/campaign/options/cost',
                  },
                  component: SymfonyFrame,
                },
                {
                  path: 'transform-entities/',
                  name: 'transform-entities',
                  meta: {
                    symfonyPath: '/campaign/options/transform-entities',
                  },
                  component: SymfonyFrame,
                },
                {
                  path: 'customer-journey-options/',
                  name: 'customer-journey-options',
                  meta: {
                    symfonyPath: '/campaign/options/customer-journey',
                  },
                  component: SymfonyFrame,
                },
                {
                  path: 'redirect-rules/',
                  name: 'redirect-rules',
                  component: () =>
                    import(/* webpackChunkName: "ams-redirect-rules" */ '@/views/ams/redirect-rules.vue'),
                  props: { createMode: true },
                  meta: {
                    breadcrumb: 'Domain whitelist',
                  },
                },
                {
                  path: 'attribution-models/',
                  name: 'attribution-models',
                  meta: {
                    symfonyPath: '/campaign/options/attributions',
                  },
                  component: SymfonyFrame,
                },
                {
                  path: 'segmentation/',
                  name: 'segmentation',
                  meta: {
                    symfonyPath: '/campaign/options/segmentation',
                  },
                  component: SymfonyFrame,
                },
                {
                  path: 'exchange-rate/',
                  name: 'exchange-rate',
                  meta: {
                    symfonyPath: '/campaign/options/rates',
                  },
                  component: SymfonyFrame,
                },
              ],
            },
          ],
        },
        dataGovernance,
        {
          path: 'administration/',
          name: 'administration',
          meta: {
            breadcrumb: {
              label: 'Administration',
              parent: null,
            },
          },
          component: Transient,
          children: [
            {
              path: 'modification-history/',
              name: 'modification-history',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/containers/modification-history',
              },
            },
            {
              path: 'user-management/',
              name: 'user-management',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/options/usermanagement',
              },
            },
            {
              path: 'profile-management/',
              name: 'profile-management',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/option/profilemanagement',
              },
            },
            {
              path: 'connector-management/',
              name: 'connector-management',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/options/connector/management',
              },
            },
            {
              path: 'domain-management/',
              name: 'domain-management',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/options/domain/configuration',
              },
            },
            {
              path: 'copy-management/',
              name: 'copy-management',
              component: SymfonyFrame,
              meta: {
                symfonyPath: '/options/copy/management',
              },
            },
            {
              path: 'credit-usage/',
              name: 'credit-usage',
              component: () => import('@/views/administration/billing/billing-information.vue'),
              props: true,
              meta: {
                breadcrumb: 'billing.breadcrumb',
                can: 'view.CreditUsage',
                staffOnly: true,
              },
            },
            {
              path: 'library-management/',
              name: 'library-management',
              component: SymfonyFrame,
              meta: {
                can: 'view.LibraryManagement',
                symfonyPath: '/options/library/management',
              },
            },
          ],
        },
        /**
         * CONTAINER V7 EDIT
         */
        {
          path: 'containers/selection/:id/web',
          name: 'container-web-edit-v7',
          component: SymfonyFrame,
        },
        {
          path: 'options/accountsettings',
          name: 'current-user-account-settings',
          component: SymfonyFrame,
        },
      ],
      /**
       * END OF SITE PREFIX
       */
    },
    {
      path: '/:locale',
      name: 'locale',
      beforeEnter(to, from, next) {
        next(false);
        store.dispatch('currentUser/navigateToSitePrefix');
      },
    },
    /**
     * TRUST
     */
    {
      path: '/containers/privacy/cookie-scanner/deploy',
      name: 'trust-cookie-scanner-deploy',
      component: () =>
        import(/* webpackChunkName: "trust-cookie-scanner" */ '@/views/trust/cookie-scanner/cookie-scanner-deploy.vue'),
    },
    {
      path: '/containers/quality/pages',
      name: 'tag-quality-pages',
      component: () => import(/* webpackChunkName: "tag-quality" */ '@/views/tms/tag-quality-pages.vue'),
      props: true,
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          path: 'listing-rules',
          name: 'tq-rules',
          component: () => import(/* webpackChunkName: "tag-quality" */ '@/views/tms/tag-quality-rules.vue'),
          children: [
            {
              // UserProfile will be rendered inside User's <router-view>
              path: 'view',
              name: 'tq-rule',
              component: () => import(/* webpackChunkName: "tag-quality" */ '@/views/tms/tag-quality-rule.vue'),
            },
          ],
        },
      ],
    },

    {
      path: '/containers/quality/pages/rules/:id',
      name: 'tag-quality-rule',
      component: () => import(/* webpackChunkName: "tag-quality" */ '@/views/tms/tag-quality-rule.vue'),
      props: true,
    },
    {
      path: '/containers/quality/pages/rules',
      name: 'tag-quality-rules',
      component: () => import(/* webpackChunkName: "tag-quality" */ '@/views/tms/tag-quality-rules.vue'),
    },
    {
      path: '/campaign/contributive-analysis',
      name: 'campaign-contributive-analysis',
      component: () =>
        import(/* webpackChunkName: "ams-contributive-analysis" */ '@/views/ams/contributive-analysis.vue'),
    },
    {
      path: '/campaign/redirect-rules',
      name: 'campaign-redirect-rules',
      component: () => import(/* webpackChunkName: "ams-redirect-rules" */ '@/views/ams/redirect-rules.vue'),
    },
    {
      path: '/todo',
      name: 'todo',
      meta: {
        staffOnly: true,
      },
    },
    {
      path: '*',
      name: 'standard-error',
      component: ErrorPage,
      props: { error: '404' },
      meta: {
        authorizationSkip: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.commit('layout/routerProgressStart');
  next();
});

router.beforeEach((to, from, next) => {
  if (to.params.siteId !== from.params.siteId) {
    setSiteId(to.params.siteId);
  }
  next();
});

router.beforeEach(checkSessionGuard);

router.beforeEach(passwordExpiredGuard);

router.beforeEach(authorizationGuard);

router.beforeEach(autoCacheFlushGuard);

router.beforeEach(containerReloadGuard);

router.beforeResolve(metaFnGuard);

router.afterEach(() => {
  store.commit('layout/routerProgressFinish');
});

router.onError(() => {
  store.commit('layout/routerProgressFinish');
});
