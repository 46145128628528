import { jsonApi } from '../jsonApi';

jsonApi.define('ams/dimension', {
  label: '',
  short_name: '',
  type: '',
  ref_id: null,
  channel_id: null,
  input_type: '',
  possible_values: '',
  is_hidden: false,
});
