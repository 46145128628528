export const getBitEntropy = password => {
  const nbChars = password.length;

  let alphabetLen = 0;

  // check if the password contain a lower case letter
  if (/[a-z]/.test(password)) {
    alphabetLen += 26;
  }

  // check if the password contain an upper case letter
  if (/[A-Z]/.test(password)) {
    alphabetLen += 26;
  }

  // check if the password contain a number
  if (/\d/.test(password)) {
    alphabetLen += 10;
  }

  // check if the password contain a special character
  if (/[^A-Za-z0-9]/.test(password)) {
    alphabetLen += 40;
  }

  const entropy = Math.floor(nbChars * Math.log2(alphabetLen));

  return entropy;
};
