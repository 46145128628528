<template>
  <div class="iframe-integration vh-100">
    <div class="container-fluid h-100">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="d-flex flex-column justify-content-center">
          <h1 class="text-center splash-title">{{ $t('errors.page.oops') }}</h1>
          <p class="text-center splash-message">{{ errorI18n }}</p>
          <div class="d-flex justify-content-around">
            <a class="btn btn-lg btn-secondary mr-5" @click="backToHome">
              <fa icon="chevron-left" />&nbsp;
              {{ $t('common.backToHome') }}
            </a>
            <a :href="mailtoSupport" class="btn btn-lg btn-primary">{{ $t('common.contactSupport') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { constant } from '@/common/constant';

export default {
  components: {},
  props: {
    error: {
      type: String,
      default() {
        return 'general';
      },
    },
  },

  computed: {
    errorI18n() {
      return this.$t(`errors.page.${this.error}`);
    },
    mailtoSupport() {
      return `mailto:${constant.supportEmail}`;
    },
  },
  methods: {
    backToHome() {
      this.$store.dispatch('currentUser/navigateToSitePrefix');
    },
  },
};
</script>
<style lang="scss" scoped>
.splash-title {
  font-size: 5rem;
  font-weight: 500;
}

.splash-message {
  font-size: 2rem;
  font-weight: 400;
}
</style>
