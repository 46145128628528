import { jsonApiVuex } from '@/common/store/json-api-vuex';
import { get } from 'lodash';

export const dmsStream = jsonApiVuex(
  'dms/stream',
  {
    getters: {
      controlGroupOnly(state) {
        return state.entities.filter(e => get(e, 'parameters.control_group'));
      },
    },
  },
  {}
);
