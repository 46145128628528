import { getApiHostname } from '~version/models/headers-handler';
import { axiosApi } from '@/models';
import urlJoin from 'url-join';
import { join } from 'lodash';

export const actions = {
  async publish({ commit, state }, params = { sites: [], isRequestForPublishing: false }) {
    if (!Array.isArray(params.sites) || !params.sites.length) {
      throw new Error('sites are required with an array form for publishing');
    }

    if (!state.entity.id) {
      throw new Error('Template should be saved before publishing');
    }
    const data = {
      type: state.modelName,
      attributes: {
        to_site: params.sites,
      },
    };

    let url;
    if (state.modelName === 'destination-builder-template') {
      url = `destination-builder-templates/${state.entity.id}/deploy`;
      if (params.isRequestForPublishing) {
        data.attributes.request_for_deployment = true;
      }
    } else {
      url = urlJoin(getApiHostname(), `/v2/built-in/destination-builder-templates/${state.entity.id}/deploy`);
    }

    const resp = await axiosApi.post(url, { data });
    const template = resp.data?.data?.attributes;

    if (template) {
      template.id = resp.data?.data?.id;
      commit('_setCurrentEntity', template);
    }
  },

  async lastRequestedSitesForPublishing({ commit, state }) {
    const deploymentDetails = state.entity.deployment_details;
    const ids = deploymentDetails?.requested_sites ?? deploymentDetails?.sites_deployed;

    if (!ids || ids.length === 0) {
      commit('setLastRequestedSitesForPublishing', []);
      return;
    }
    const result = [];
    const {
      data: { data: sites },
    } = await axiosApi.get(
      urlJoin(getApiHostname(), `/v2/sites?fields=name&filter[destination-builder]=true,&filter[id]=${join(ids)}`)
    );
    for (const site of sites) {
      result.push({ id: site.id, name: site.attributes.name });
    }
    commit('setLastRequestedSitesForPublishing', result);
  },
};
