import { jsonApi } from '../../jsonApi';

jsonApi.define('monitoring/collection/event', {
  id: null,
  name: '',
  sources: {
    jsonApi: 'hasMany',
    type: 'integration',
  },
  destinations: {
    jsonApi: 'hasMany',
    type: 'integration',
  },
  stats: {
    jsonApi: 'hasOne',
    type: 'events/stat',
  },
});
