<template>
  <div>
    <div v-if="mode === 'tab'" class="page-content">
      <div :class="containerClass">
        <div>
          <Breadcrumbs />
          <ul class="nav nav-tabs nav-tabs-custom mb-4" v-if="visibleChildren.length > 1">
            <li role="presentation" class="nav-item" v-for="route in visibleChildren" :key="route.name">
              <router-link :to="route.path" class="nav-link" :class="{ active: $route.name === route.name }">{{
                $t(route.meta.titleI18n)
              }}</router-link>
            </li>
          </ul>
        </div>
        <div>
          <div>
            <div class="card">
              <div class="card-body">
                <router-view></router-view>
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <div>
                    <router-link class="link-card" :to="back">
                      <b-btn class="wizard-footer-left">{{ $t('common.back') }}</b-btn>
                    </router-link>
                  </div>
                  <div>
                    <b-btn variant="primary" @click="saveHandler" class="wizard-footer-right">
                      {{ $t('common.save') }}</b-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="mode === 'wizard'" class=" page-content">
      <div :class="containerClass">
        <Breadcrumbs />
        <div class="card">
          <div class="card-body">
            <form-wizard :color="cssVariables.commandersAct" ref="wizard">
              <tab-content
                v-for="route in visibleChildren"
                :key="route.name"
                :route="route.path"
                :title="$t(route.meta.titleI18n)"
                :icon="route.meta.wizardIcons"
              >
              </tab-content>
              <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition>
              <template slot="footer" slot-scope="props">
                <div class="d-flex justify-content-between">
                  <div>
                    <b-btn v-if="props.activeTabIndex > 0" class="wizard-footer-left" @click="props.prevTab()">{{
                      $t('common.back')
                    }}</b-btn>
                    <router-link v-else-if="props.activeTabIndex == 0 && back" class="link-card" :to="back">
                      <b-btn class="wizard-footer-left">{{ $t('common.back') }}</b-btn>
                    </router-link>
                  </div>
                  <div>
                    <b-btn
                      v-if="!props.isLastStep"
                      variant="primary"
                      class="wizard-footer-right"
                      @click="wizardNextStepHandler"
                      >{{ $t('common.next') }}</b-btn
                    >

                    <b-btn v-else variant="primary" @click="saveHandler" class="wizard-footer-right">
                      {{ $t('common.save') }}</b-btn
                    >
                  </div>
                </div>
              </template>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { routeChildren } from '@/common/router/route-children';
import { metaFn } from '@/common/router/meta-fn';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import cssVariables from '@/versions/x/styles/variables.module.scss';

const component = {
  name: 'nav-tab-wizard',
  components: {
    FormWizard,
    TabContent,
  },
  props: {
    mode: {
      type: String,
      default: 'tab',
    },
    containerClass: {
      type: String,
      default: 'container-left',
    },
    save: {
      type: Function,
      default: () => {},
    },
    back: {},
  },
  provide() {
    return {
      navTabWizard: {
        mode: this.mode,
        vm: this,
        registerStepValidations: (index, validations) => {
          this.validations[index] = validations;
        },
      },
    };
  },
  data() {
    return {
      cssVariables,
      children: [],
      validations: {},
    };
  },
  computed: {
    config() {
      return this.$route.meta;
    },
    visibleChildren() {
      if (this.mode === 'tab') {
        return this.children.filter(c => !c.meta.navTabHidden);
      }
      //wizard
      return this.children.filter(c => !c.meta.wizardHidden);
    },
    wizardStep() {
      return this.visibleChildren;
    },
    wizardInfo() {
      return {
        activeTabIndex: this.$refs.wizard.activeTabIndex,
        isLastStep: this.$refs.wizard.isLastStep,
      };
    },
  },
  async mounted() {
    if (this.$slots.default) {
      return;
    }
    //Search in the routers' hierarchy where this nav-tab is used to get the children
    this.children = routeChildren('nav-tab-wizard', this.$router, this.$route);
    await metaFn(this.children, this.$route);
  },
  methods: {
    wizardNextStepHandler() {
      const event = new CustomEvent('wizard-next-step', {
        cancelable: true,
        detail: this.wizardInfo,
      });
      this.$emit('wizard-next-step', event);
      if (this.validations[this.wizardInfo.activeTabIndex]?.$invalid) {
        this.validations[this.wizardInfo.activeTabIndex].$touch();
        this.$store.commit('notifications/pushWarning', this.$t('common.notifications.validationError'));
        event.preventDefault();
      }
      if (!event.defaultPrevented) {
        this.$refs.wizard.nextTab();
      }
    },
    saveHandler() {
      const event = new CustomEvent('save', {
        cancelable: true,
      });
      this.$emit('save', event);
      if (!event.defaultPrevented) {
        this.save(this);
      }
    },
  },
};
export default component;
</script>
