import Vue from 'vue';
import { isString } from 'lodash';

export const deepSet = (obj, props, value) => {
  if (isString(props)) {
    props = props.split('.');
  }
  const prop = props.shift();
  if (!obj[prop]) {
    Vue.set(obj, prop, {});
  }
  if (!props.length) {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      obj[prop] = { ...obj[prop], ...value };
    } else {
      obj[prop] = value;
    }
    return;
  }
  deepSet(obj[prop], props, value);
};
