import NavTab from '@/components/layouts/nav-tab';
import { store } from '@/store';

const editTab = (tab, component, meta = {}) => {
  meta.titleI18n = `normalized-datalayer.tabs.${tab}`;
  meta.breadcrumb = () => ({ label: null, parent: null });
  return {
    path: tab,
    name: `normalized-datalayer-${tab}`,
    props: () => {
      return {
        tab,
      };
    },
    component,
    meta,
  };
};

const MultiTabs = Object.assign({}, NavTab); // clone navtab to isolate the two components

export const normalizedDatalayer = {
  path: 'normalized-datalayer',
  name: 'normalized-datalayer',
  meta: {
    breadcrumb: {
      label: 'normalized-datalayer.title',
      parent: null,
    },
  },
  redirect: 'normalized-datalayer/events',
  component: MultiTabs,
  children: [
    editTab(
      'events',
      () =>
        import(
          /* webpackChunkName: "normalized-datalayer" */ '@/views/data-management/normalized-datalayer-events.vue'
        ),
      {
        staffOnly: true,
        siteParametersAccess: ['ndl_enabled'],
        async metaFn() {
          return {
            navTabHidden: false,
            titleI18n: 'normalized-datalayer.tabs.events',
          };
        },
      }
    ),
    editTab('dataStore', null, {
      titleI18n: 'normalized-datalayer.tabs.dataStore',
    }),
    editTab(
      'properties',
      () =>
        import(
          /* webpackChunkName: "normalized-datalayer" */ '@/views/data-management/normalized-datalayer-properties.vue'
        ),
      {
        async metaFn() {
          return {
            navTabHidden: false,
            titleI18n: 'normalized-datalayer.tabs.properties',
          };
        },
      }
    ),

    editTab(
      'settings',
      () =>
        import(
          /* webpackChunkName: "normalized-datalayer" */ '@/views/data-management/normalized-datalayer-settings.vue'
        ),
      {
        async metaFn() {
          return {
            navTabHidden: false,
            titleI18n: 'normalized-datalayer.tabs.settings',
          };
        },
      }
    ),
  ],
};

export const normalizedDatalayerCatalogue = {
  path: 'normalized-datalayer-catalogue',
  name: 'normalized-datalayer-catalogue',
  redirect: 'normalized-datalayer/events',
  meta: {
    breadcrumb: {
      label: 'normalized-datalayer.title',
      parent: null,
    },
  },
  component: NavTab,
  children: [
    {
      path: 'create',
      name: 'datalayer-add-from-catalog',
      meta: {
        breadcrumb: {
          label: 'normalized-datalayer.eventCatalog',
          parent: null,
        },
        navTabHidden: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "normalized-datalayer" */ '@/views/data-management/normalized-datalayer-events-catalog.vue'
        ),
    },
    {
      path: 'add-standard-specification/:id',
      name: 'datalayer-add-standard-specification',
      async beforeEnter(to, from, next) {
        store.commit('normalizedDatalayer/eventType/_clearCurrentEntity');
        await store.dispatch('normalizedDatalayer/standardEventType/loadCurrent', to.params.id);
        next();
      },
      meta: {
        breadcrumb: {
          label: 'normalized-datalayer.buttons.addCustom',
          parent: null,
        },
        navTabHidden: true,
      },
      component: () => import('@/views/data-management/normalized-datalayer-event-edit.vue'),
    },
    {
      path: 'add-custom-specification',
      name: 'datalayer-add-custom-specification',
      async beforeEnter(to, from, next) {
        store.commit('normalizedDatalayer/eventType/_clearCurrentEntity');
        store.commit('normalizedDatalayer/standardEventType/_clearCurrentEntity');
        next();
      },
      meta: {
        breadcrumb: {
          label: 'normalized-datalayer.buttons.addCustom',
          parent: null,
        },
        navTabHidden: true,
      },
      component: () => import('@/views/data-management/normalized-datalayer-event-edit.vue'),
    },

    {
      path: 'edit-specification/:id(\\d+)',
      name: 'datalayer-edit-specification',
      async beforeEnter(to, from, next) {
        store.commit('normalizedDatalayer/standardEventType/_clearCurrentEntity');
        store.commit('normalizedDatalayer/eventType/_clearCurrentEntity');
        await store.dispatch('normalizedDatalayer/eventType/loadCurrent', {
          id: to.params.id,
          include: 'properties',
        });
        next();
      },
      meta: {
        breadcrumb: {
          label: 'normalized-datalayer.titleEditEvent',
          parent: null,
        },
        navTabHidden: true,
      },
      component: () => import('@/views/data-management/normalized-datalayer-event-edit.vue'),
    },
  ],
};
