export const routeChildren = (currentComponentName, $router, $route) => {
  let routes = $router.options.routes;
  let navRoute;
  let previousPath = '';
  for (const match of $route.matched) {
    navRoute = routes.find(r => {
      // FIXME: support matching routes with a final "/"
      return `${previousPath}${r.path}` === match.path;
    });
    if (!navRoute || navRoute.component.name === currentComponentName) {
      break;
    }
    previousPath = `${previousPath}${navRoute.path}/`;
    routes = navRoute.children;
  }

  return navRoute?.children ?? [];
};
