import { jsonApi } from '../jsonApi';

jsonApi.define('billing-feature', {
  code: '',
  label: '',
  nature: '',
  category_id: 0,
  field_name: '',
  is_offered: 0,
  is_active: 0,
  created_at: '',
  updated_at: '',
  order: '',
  function: '',
  billingWeight: {
    jsonApi: 'hasMany',
    type: 'billing/billing-weight',
  },
});
