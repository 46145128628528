export const state = () => ({
  loading: 0,
  routerLoading: false,
  breadcrumbs: [],
  sidebarDisplayedState: true,
});

export const getters = {
  isLoading(state) {
    // Before RUN-1842 (Dissociate global loading from xhr after page load)
    // We did -> return state.loading || state.routerLoading;
    return state.routerLoading;
  },
};

export const mutations = {
  progressStart(state) {
    state.loading++;
  },
  progressFinish(state) {
    if (state.loading) state.loading--;
  },
  routerProgressStart(state) {
    state.routerLoading = true;
  },
  routerProgressFinish(state) {
    state.routerLoading = false;
  },
  setBreadcrumbs(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },
  hideSidebar(state) {
    state.sidebarDisplayedState = false;
  },
  showSidebar(state) {
    state.sidebarDisplayedState = true;
  },
  toggleSidebar(state) {
    state.sidebarDisplayedState = !state.sidebarDisplayedState;
  },
};
