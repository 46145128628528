import { jsonApiVuex } from '@/common/store/json-api-vuex';

export const eventProperty = jsonApiVuex('normalized-datalayer/event-property', {
  getters: {
    getPropertyByName(state) {
      return propertyName => {
        if (!propertyName) {
          return null;
        }
        const properties = state.entities || [];
        return properties.find(p => p.name === propertyName);
      };
    },
  },
});
