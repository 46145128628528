import { jsonApi } from '../jsonApi';

jsonApi.define('enrichment/event', {
  id: null,
  name: '',
  description: '',
  status: 'inactive',
  matching_key: '',
  path: '',
  path_custom: '',
  event_count: 0,
  created_at: null,
  updated_at: null,
  enrichment_type: '',
  parameters: '',
  variables: {
    jsonApi: 'hasMany',
    type: 'dms/variable',
  },
  store: {
    jsonApi: 'hasOne',
    type: 'dms/universe',
  },
  sources: {
    jsonApi: 'hasMany',
    type: 'integration',
  },
  updater: {
    jsonApi: 'hasOne',
    type: 'user',
  },
  creator: {
    jsonApi: 'hasOne',
    type: 'user',
  },
});
