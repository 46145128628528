<script>
export default {
  components: {},
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="welcome-bg">
                <div class="row">
                  <div class="col-7">
                    <div class="text-white py-4 pl-4 pr-3">
                      <slot name="title" />
                    </div>
                  </div>
                  <div class="col-5 align-self-end">
                    <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div>
                  <router-link tag="a" to="/">
                    <div class="avatar-md profile-user-wid">
                      <span class="avatar-title rounded-circle" style="background-color: #eff2f724 !important">
                        <img src="@/assets/images/logo-icon.svg" alt height="54" />
                      </span>
                    </div>
                  </router-link>
                </div>
                <slot />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '~styles/variables.scss';

.welcome-bg {
  background: linear-gradient(90deg, #021f82 5%, #1b46d4 35%, #021f82 100%);
}
</style>
