import { jsonApi } from '../../jsonApi';

jsonApi.define('privacy/cookie-scanner', {
  version: '',
  comment: '',
  new: '',
  ignored: '',
  languages: [],
  filename: '',
  is_deployed: 0,
  created: null,
  updated: null,
  deployed: null,
});
