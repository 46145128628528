import { jsonApiVuex } from '@/common/store/json-api-vuex';
import { axiosApi } from '@/models';

const mutations = {
  async updateSorting(state, data) {
    data.forEach(transformation => {
      transformation.type = 'integrations/cleansing-transformations';
    });
    const url = 'integrations/cleansing-transformations/sort';
    await axiosApi.patch(url, { data });
  },
};

export const integrationCleansingTransformation = jsonApiVuex(
  'integrations/cleansing-transformation',
  { mutations },
  {}
);
