import { jsonApi } from '../jsonApi';

jsonApi.define('normalized-datalayer/event-type', {
  id: null,
  name: '',
  label: '',
  created: '',
  updated: '',
  description: '',
  stored: false,
  is_custom: false,
  required_properties: [],
  references: {
    jsonApi: 'hasMany',
    type: 'normalized-datalayer/standard-event-type',
  },
  properties: {
    jsonApi: 'hasMany',
    type: 'normalized-datalayer/event-properties',
  },
});
