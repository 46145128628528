<template>
  <div>
    <nav-tab v-if="this.$route.meta.showBreadcrumb === true">
      <div :key="change">
        <iframe class="iframe-integration" :src="url" frameborder="0" @load="postInit()" ref="iframe"></iframe>
      </div>
    </nav-tab>
    <div :key="change" v-else>
      <iframe class="iframe-integration" :src="url" frameborder="0" @load="postInit()" ref="iframe"></iframe>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { getPlatformHostname } from '~version/models/headers-handler';
import { authenticationMixin } from '@/common/security/authentication-mixin';
import NavTab from '@/components/layouts/nav-tab';

export default {
  mixins: [authenticationMixin],
  components: { NavTab },
  props: {
    locale: {
      type: String,
    },
    siteId: {
      type: Number,
    },
  },
  data() {
    return {
      change: 0,
      url: '',
      lastPathname: '',
    };
  },
  mounted() {
    if (this.$route.query.p) {
      this.lastPathname = this.$route.query.p;
      this.computeUrl(this.$route.query.p);
    } else {
      this.computeUrl();
    }
    this.messageListener = event => {
      const { type } = event.data;
      let canonicPath;
      switch (type) {
        case 'refreshLocation':
          canonicPath = this.trimSlashes(event.data.pathname);
          if (this.lastPathname !== canonicPath) {
            this.lastPathname = canonicPath;
            this.$router.replace({ query: { p: this.lastPathname } });
          }
          break;
        case 'timeout':
          this.$store.dispatch('currentUser/timeout');
          break;
        case 'hideSidebar':
          this.hideSidebar();
          break;
        case 'newUserAdded':
          this.$store.dispatch('currentUser/loadUser');
          break;
        case 'pushNewRoute':
          this.$router.push({ name: event.data.routeName });
          break;
      }
    };
    window.addEventListener('message', this.messageListener);
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.computeUrl();
        return;
      }
      if (to.query.p !== this.lastPathname) {
        this.lastPathname = to.query.p;
        this.computeUrl(to.query.p);
      }
    },
  },
  methods: {
    ...mapMutations('layout', ['hideSidebar']),
    computeUrl(path) {
      let queryParams = '';
      if (!path) {
        const prefix = `/${this.$route.params.locale}/${this.$route.params.siteId}`;
        path = this.$route.meta.symfonyPath ? `${prefix}${this.$route.meta.symfonyPath}` : `${this.$route.fullPath}`;
      }
      if (this.$route.query) {
        const searchParams = new URLSearchParams(this.$route.query);
        queryParams = `?${searchParams.toString()}`;
      }
      path = `/${path}`;
      this.url = `${getPlatformHostname()}${this.trimSlashes(path)}${queryParams}`;

      this.change++;
    },
    trimSlashes(url) {
      return url.replace(/\/+/g, '/');
    },
    postInit() {
      this.$refs.iframe.contentWindow.postMessage(
        {
          type: 'init',
          origin: window.location.origin,
        },
        new URL(getPlatformHostname()).origin
      );

      window.addEventListener('message', event => {
        // On LOCAL: '/v5/web/app_dev.php' is added to hostname
        const isSameOrigin =
          event.origin === getPlatformHostname() || `${event.origin}/v5/web/app_dev.php` === getPlatformHostname();

        if (event.data === 'activity-on-iframe' && isSameOrigin) {
          this.checkPlatformSession();
        }
      });
    },
  },
  destroyed() {
    window.removeEventListener('message', this.messageListener);
  },
};
</script>
<style lang="scss" scoped>
.iframe-integration {
  padding-top: 10px;
  height: calc(100vh - 5px);
  overflow: hidden;
  width: 100%;
}
</style>
