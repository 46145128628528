export const menuItems = [
  {
    id: 1,
    label: 'menuItems.integrations.title',
    isTitle: true,
  },
  {
    id: 2,
    label: 'menuItems.integrations.sources.title',
    icon: 'bx-log-in-circle',
    subItems: [
      {
        id: 20,
        label: 'menuItems.integrations.sources.list.overview',
        link: { name: 'source-overview' },
        parentId: 2,
      },
      {
        id: 21,
        label: 'menuItems.integrations.sources.list.containers',
        link: { name: 'containers' },
        parentId: 2,
      },
      {
        id: 25,
        label: 'menuItems.integrations.sources.list.privacy',
        link: { name: 'privacy-banners' },
        parentId: 2,
      },
      {
        id: 27,
        label: 'menuItems.integrations.sources.list.inspector',
        link: { name: 'sources-inspector' },
        parentId: 2,
      },
      {
        id: 28,
        label: 'menuItems.integrations.sources.list.adTrafficking',
        link: { name: 'trafficking' },
        parentId: 2,
      },
    ],
  },
  {
    id: 3,
    label: 'menuItems.integrations.enrichments.title',
    icon: 'bx-layer-plus',
    subItems: [
      {
        id: 30,
        label: 'menuItems.integrations.enrichments.list.event.title',
        link: { name: 'event-enrichment-overview' },
        parentId: 3,
      },
      {
        id: 31,
        label: 'menuItems.integrations.enrichments.list.user',
        link: { name: 'user-attributes' },
        parentId: 3,
      },
    ],
  },
  {
    id: 4,
    label: 'menuItems.integrations.destinations.title',
    icon: 'bx-log-out-circle bx-flip-horizontal',
    subItems: [
      {
        id: 40,
        label: 'menuItems.integrations.destinations.list.overview',
        link: { name: 'destination-overview' },
        parentId: 4,
      },
      {
        id: 41,
        label: 'menuItems.integrations.destinations.list.catalog',
        link: { name: 'destination-catalog' },
        parentId: 4,
      },
      {
        id: 42,
        label: 'menuItems.integrations.destinations.list.campaignExport',
        link: { name: 'exports' },
        parentId: 4,
      },
    ],
  },
  {
    id: 5,
    label: 'menuItems.integrations.health.title',
    icon: 'bx-pulse',
    subItems: [
      {
        id: 50,
        label: 'menuItems.integrations.health.list.sourcesDataQuality',
        link: { name: 'sources-data-quality' },
        parentId: 5,
      },
      {
        id: 51,
        label: 'menuItems.integrations.health.list.destinationsMonitoring',
        link: { name: 'integrations-monitoring' },
        parentId: 5,
      },
      {
        id: 52,
        label: 'menuItems.integrations.health.list.websiteMonitoring',
        parentId: 5,
        subItems: [
          {
            id: 520,
            label: 'menuItems.integrations.health.list.datalayer',
            link: '/todo',
            parentId: 52,
          },
          {
            id: 521,
            label: 'menuItems.integrations.health.list.cookieScanner',
            link: { name: 'trust-cookie-scanner-edit' },
            parentId: 52,
          },
          {
            id: 522,
            label: 'menuItems.integrations.health.list.tagsPerf',
            link: { name: 'tags-performance' },
            parentId: 52,
          },
          {
            id: 523,
            label: 'menuItems.integrations.health.list.piggybacking',
            link: { name: 'piggybacking' },
            parentId: 52,
          },
          {
            id: 524,
            label: 'menuItems.integrations.health.list.containerStats',
            link: { name: 'web-container-stats' },
            parentId: 52,
          },
          {
            id: 525,
            label: 'menuItems.integrations.health.list.dedupConversions',
            link: { name: 'deduplication-by-conversions' },
            parentId: 52,
          },
          {
            id: 526,
            label: 'menuItems.integrations.health.list.dedupTags',
            link: { name: 'deduplication-by-tags' },
            parentId: 52,
          },
        ],
      },
      {
        id: 53,
        label: 'menuItems.integrations.health.list.alerts',
        link: '/todo',
        parentId: 5,
      },
    ],
  },
  {
    id: 6,
    label: 'menuItems.customers.title',
    isTitle: true,
  },
  {
    id: 7,
    label: 'menuItems.customers.segments.title',
    icon: 'bx-bullseye',
    subItems: [
      {
        id: 70,
        label: 'menuItems.customers.segments.list.overview',
        link: { name: 'segments' },
        parentId: 7,
      },
      {
        id: 71,
        label: 'menuItems.customers.segments.list.stats',
        link: { name: 'segment-stats' },
        parentId: 7,
      },
      {
        id: 72,
        label: 'menuItems.customers.segments.list.overlap',
        link: { name: 'segment-overlap' },
        parentId: 7,
      },
      {
        id: 73,
        label: 'menuItems.customers.segments.list.dashboard',
        link: { name: 'segment-dashboard' },
        parentId: 7,
      },
    ],
  },
  {
    id: 8,
    label: 'menuItems.customers.identities.title',
    link: { name: 'fuse-activation' },
    icon: 'bx-fingerprint',
    subItems: [
      {
        id: 73,
        label: 'menuItems.customers.identities.activation',
        link: { name: 'fuse-activation' },
        parentId: 8,
      },
      {
        id: 73,
        label: 'menuItems.customers.identities.migration',
        link: { name: 'fuse-migration' },
        parentId: 8,
      },
      {
        id: 74,
        label: 'menuItems.customers.identities.statistics',
        link: { name: 'fuse-statistics' },
        parentId: 8,
      },
    ],
    // badge: {
    //   variant: 'success',
    //   text: 'menuItems.badge.new',
    // },
  },
  {
    id: 9,
    label: 'menuItems.explore.title',
    isTitle: true,
  },
  {
    id: 10,
    label: 'menuItems.explore.insights.list.campaign.title',
    icon: 'bx-bar-chart',
    subItems: [
      {
        id: 100,
        label: 'menuItems.explore.insights.list.campaign.list.controlgroup',
        link: { name: 'campaign-performance-dashboard' },
        parentId: 10,
        dontDisplayPadlockIfNoAccess: true,
      },
      {
        id: 101,
        label: 'menuItems.explore.insights.list.campaign.list.lrb',
        link: { name: 'live-report-builder' },
        parentId: 10,
      },
      {
        id: 102,
        label: 'menuItems.explore.insights.list.campaign.list.contributive',
        link: { name: 'contributive-analysis' },
        parentId: 10,
      },
      {
        id: 103,
        label: 'menuItems.explore.insights.list.campaign.list.conversions.title',
        parentId: 10,
        subItems: [
          {
            id: 1031,
            label: 'menuItems.explore.insights.list.campaign.list.conversions.conversionDetails',
            link: { name: 'conversion-details' },
            parentId: 103,
          },
          {
            id: 1033,
            label: 'menuItems.explore.insights.list.campaign.list.conversions.customerJourney',
            link: { name: 'customer-journey' },
            parentId: 103,
          },
          {
            id: 1032,
            label: 'menuItems.explore.insights.list.campaign.list.conversions.overlap',
            link: { name: 'campaign-overlap' },
            parentId: 103,
          },
        ],
      },
      {
        id: 104,
        label: 'menuItems.explore.insights.list.campaign.list.sunburst',
        link: { name: 'sunburst' },
        parentId: 10,
      },
    ],
  },
  {
    id: 11,
    label: 'menuItems.explore.consent',
    icon: 'bx-check-shield',
    link: { name: 'consent-dashboard' },
  },
  {
    id: 12,
    label: 'menuItems.configure.title',
    isTitle: true,
  },
  {
    id: 13,
    label: 'menuItems.configure.data.title',
    icon: 'bx-layer',
    subItems: [
      {
        id: 130,
        label: 'menuItems.configure.data.list.eventsCollection',
        link: { name: 'events-collection' },
        parentId: 13,
      },
      {
        id: 131,
        label: 'menuItems.configure.data.list.dataCleansing',
        link: { name: 'cleansing-transformation-overview' },
        parentId: 13,
      },
      {
        id: 132,
        label: 'menuItems.configure.data.list.normalized',
        link: { name: 'normalized-datalayer' },
        parentId: 13,
      },
      {
        id: 133,
        label: 'menuItems.configure.data.list.web',
        link: { name: 'web-datalayer' },
        parentId: 13,
      },
      {
        id: 134,
        label: 'menuItems.configure.data.list.pii',
        link: '/todo',
        parentId: 13,
      },
      {
        id: 135,
        label: 'menuItems.configure.data.list.campaignSettings.title',
        parentId: 13,
        subItems: [
          {
            id: 1351,
            label: 'menuItems.configure.data.list.campaignSettings.channelIdentification',
            link: { name: 'channel-identification' },
            parentId: 135,
          },
          {
            id: 1352,
            label: 'menuItems.configure.data.list.campaignSettings.ip',
            link: { name: 'ip' },
            parentId: 135,
          },
          {
            id: 1353,
            label: 'menuItems.configure.data.list.campaignSettings.dimensions',
            link: { name: 'dimensions' },
            parentId: 135,
          },
          {
            id: 1354,
            label: 'menuItems.configure.data.list.campaignSettings.cost',
            link: { name: 'cost' },
            parentId: 135,
          },
          {
            id: 1355,
            label: 'menuItems.configure.data.list.campaignSettings.transformEntities',
            link: { name: 'transform-entities' },
            parentId: 135,
          },
          {
            id: 1356,
            label: 'menuItems.configure.data.list.campaignSettings.customerJourney',
            link: { name: 'customer-journey-options' },
            parentId: 135,
          },
          {
            id: 1357,
            label: 'menuItems.configure.data.list.campaignSettings.redirectRules',
            link: { name: 'redirect-rules' },
            parentId: 135,
          },
          {
            id: 1358,
            label: 'menuItems.configure.data.list.campaignSettings.attribution',
            link: { name: 'attribution-models' },
            parentId: 135,
          },
          {
            id: 1359,
            label: 'menuItems.configure.data.list.campaignSettings.segmentation',
            link: { name: 'segmentation' },
            parentId: 135,
          },
          {
            id: 13510,
            label: 'menuItems.configure.data.list.campaignSettings.exchangeRate',
            link: { name: 'exchange-rate' },
            parentId: 135,
          },
        ],
      },
    ],
  },
  {
    id: 15,
    label: 'menuItems.configure.dataGovernance.title',
    icon: 'bx-shield-quarter',
    subItems: [
      {
        id: 150,
        label: 'menuItems.configure.dataGovernance.list.piiData',
        link: '/todo',
        parentId: 15,
      },
      {
        id: 151,
        label: 'menuItems.configure.dataGovernance.list.consent.title',
        parentId: 15,
        subItems: [
          {
            id: 1510,
            label: 'menuItems.configure.dataGovernance.list.consent.list.categories',
            link: { name: 'consent-categories' },
            parentId: 151,
          },
          {
            id: 1511,
            label: 'menuItems.configure.dataGovernance.list.consent.list.vendors',
            link: { name: 'vendor-settings' },
            parentId: 151,
          },
          {
            id: 1512,
            label: 'menuItems.configure.dataGovernance.list.consent.list.settings',
            link: { name: 'consent-settings' },
            parentId: 151,
          },
        ],
      },
      {
        id: 152,
        label: 'menuItems.configure.dataGovernance.list.storageSettings',
        link: { name: 'storage-settings-overview' },
        parentId: 15,
      },
      {
        id: 153,
        label: 'menuItems.configure.dataGovernance.list.storageInspector',
        link: '/todo',
        parentId: 15,
      },
      {
        id: 153,
        label: 'menuItems.configure.dataGovernance.list.tagDataSharing',
        link: { name: 'tag-data-sharing' },
        parentId: 15,
      },
    ],
  },
  {
    id: 14,
    label: 'menuItems.configure.admin.title',
    icon: 'bx-wrench',
    subItems: [
      {
        id: 140,
        label: 'menuItems.configure.admin.list.history',
        link: { name: 'modification-history' },
        parentId: 14,
      },
      {
        id: 141,
        label: 'menuItems.configure.admin.list.user',
        link: { name: 'user-management' },
        parentId: 14,
      },
      {
        id: 142,
        label: 'menuItems.configure.admin.list.profile',
        link: { name: 'profile-management' },
        parentId: 14,
      },
      {
        id: 143,
        label: 'menuItems.configure.admin.list.connector',
        link: { name: 'connector-management' },
        parentId: 14,
      },
      {
        id: 144,
        label: 'menuItems.configure.admin.list.domain',
        link: { name: 'domain-management' },
        parentId: 14,
      },
      {
        id: 145,
        label: 'menuItems.configure.admin.list.copy',
        link: { name: 'copy-management' },
        parentId: 14,
      },
      {
        id: 146,
        label: 'menuItems.configure.admin.list.billing',
        link: { name: 'credit-usage' },
        parentId: 14,
      },
      {
        id: 148,
        label: 'menuItems.configure.admin.list.library',
        link: { name: 'library-management' },
        parentId: 14,
      },
      {
        id: 149,
        label: 'menuItems.configure.admin.list.disclaimer',
        link: { href: 'https://doc.commandersact.com/configure/disclaimer' },
        parentId: 14,
      },
      {
        id: 147,
        label: 'menuItems.configure.admin.list.backoffice.title',
        parentId: 14,
        icon: 'bx-wrench',
        backOffice: true,
        subItems: [
          {
            id: 1470,
            label: 'menuItems.configure.admin.list.backoffice.list.siteManager',
            link: { name: 'site-manager' },
            parentId: 147,
            backOffice: true,
          },
          {
            id: 1470,
            label: 'menuItems.configure.admin.list.backoffice.list.superusersBo',
            link: { name: 'superusers-bo' },
            parentId: 147,
            backOffice: true,
          },
          {
            id: 1471,
            label: 'menuItems.configure.admin.list.backoffice.list.templateImporter',
            link: { name: 'back-office-template-importer-overview' },
            parentId: 147,
            deployTemplate: true,
          },
          {
            id: 1472,
            label: 'menuItems.configure.admin.list.backoffice.list.campaignExportRaw',
            link: { name: 'raw-data' },
            parentId: 147,
            deployTemplate: true,
          },
        ],
      },
    ],
  },
];
