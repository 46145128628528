import { flushCache } from '@/common/store/json-api-vuex';

const getCacheSession = route => {
  return route.matched.find(m => m.meta?.cacheSession)?.meta.cacheSession;
};
export const autoCacheFlushGuard = function(to, from, next) {
  const fromCacheSession = getCacheSession(from);
  const toCacheSession = getCacheSession(to);

  if (fromCacheSession && toCacheSession && fromCacheSession === toCacheSession) {
    return next();
  }

  flushCache();
  next();
};
