import { axiosApi } from '@/models';

export const state = {
  topData: {},
  channelsData: {},
  pickedPeriod: [],
  pickedFilters: {},
};

export const mutations = {
  setTopData(state, value) {
    state.topData = value;
  },
  setChannelsData(state, value) {
    state.channelsData = value;
  },
  setPickedPeriod(state, value) {
    state.pickedPeriod = value;
  },
  setPickedFilters(state, value) {
    state.pickedFilters = value;
  },
};

export const getters = {
  formattedTopData(state) {
    const formattedTopData = {
      initiator: [],
      passer: [],
      scorer: [],
    };
    state.topData.data.attributes.rows.forEach(cards => {
      cards.forEach(card => {
        for (const metric in formattedTopData) {
          if (metric in card) {
            formattedTopData[metric].push({
              topChannelName: card.channel_name.value,
              value: card[metric].value,
            });
          }
        }
      });
    });
    return formattedTopData;
  },
  formattedChannelsData(state) {
    const formattedChannelsData = [];
    state.channelsData.data.attributes.rows.forEach(row => {
      const formattedRow = {};
      for (const rowKey in row) {
        formattedRow[rowKey] = row[rowKey].value;
      }
      formattedChannelsData.push(formattedRow);
    });
    return formattedChannelsData;
  },
  getUrlParams(state) {
    const startDate = state.pickedPeriod[0] ? `date[start]=${state.pickedPeriod[0].toISOString()}` : '';
    const endDate = state.pickedPeriod[1] ? `&date[end]=${state.pickedPeriod[1].toISOString()}` : '';
    const filterParams = state.pickedFilters.reduce((acc, filter) => {
      return `${acc}&filters[${filter.dimension}]=${filter.value}`;
    });

    return `${startDate}${endDate}${filterParams}`;
  },
  getAxiosConfig(state) {
    const filterParams = {};
    for (const dimensionFilter in state.pickedFilters) {
      filterParams[`filters[${dimensionFilter}]`] = state.pickedFilters[dimensionFilter].map(filter => filter.value);
    }

    return {
      params: {
        'date[start]': state.pickedPeriod[0]?.toISOString() ?? '',
        'date[end]': state.pickedPeriod[1]?.toISOString() ?? '',
        ...filterParams,
      },
    };
  },
};

export const actions = {
  async getTopData({ commit, getters }) {
    try {
      const { data } = await axiosApi.get(`ams/contributive-analysis/top-channels`, getters.getAxiosConfig);
      commit('setTopData', data);
    } catch (e) {
      commit('setTopData', []);
      throw new Error(e);
    }
  },
  async getChannelsData({ commit, getters }) {
    try {
      const { data } = await axiosApi.get(`ams/contributive-analysis/channels-data`, getters.getAxiosConfig);
      commit('setChannelsData', data);
    } catch (e) {
      commit('setChannelsData', []);
      throw new Error(e);
    }
  },
  updatePeriod({ commit }, pickedPeriod) {
    commit('setPickedPeriod', pickedPeriod);
  },
  updateFilters({ commit }, pickedFilters) {
    commit('setPickedFilters', pickedFilters);
  },
};

export const namespaced = true;
