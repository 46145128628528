import { jsonApiVuex } from '@/common/store/json-api-vuex';
import { clone, keyBy, isString, groupBy } from 'lodash';

const getters = {
  userUniverse(state) {
    return state.entities.find(universe => universe.type_es === 'user');
  },
  conversionUniverse(state) {
    return state.entities.find(universe => universe.type_es === 'conversion');
  },

  conversionItemVariable(state) {
    const universe = getters.conversionUniverse(state);
    return universe ? universe.variables.find(v => v.property_name === 'conversion_items') : null;
  },

  withRelation(state) {
    const result = state.entities.map(clone);
    for (const universe of result) {
      let originalVariables = clone(universe.variables);
      if (universe.type_es === 'dms') {
        const userUniverse = getters.userUniverse(state);
        if (userUniverse) {
          const userVariables = userUniverse.variables.filter(v => v.property_name.startsWith('user'));
          originalVariables = originalVariables.concat(userVariables);
        }
      }
      universe.variablesByCategory = {};
      universe.variablesById = {};
      for (let variable of originalVariables) {
        const categoryLabel = variable.category.label;
        universe.variablesByCategory[categoryLabel] = universe.variablesByCategory[categoryLabel] || [];

        const [id, reference_variable_id] = isString(variable.id)
          ? variable.id.split('-')
          : [variable.id, variable.reference_variable_id];
        if (reference_variable_id) {
          variable = { ...variable, id: parseInt(id), reference_variable_id: parseInt(reference_variable_id) };
        } else {
          variable.id = parseInt(id);
        }
        universe.variablesByCategory[categoryLabel].push(variable);
        universe.variablesById[variable.id] = variable;
      }
    }
    return result;
  },
  listByExportableUniverseByIdByCategory(state, getters) {
    const result = {};
    for (const universe of getters.exportableUniverse) {
      result[universe.id] = {
        universeId: universe.id,
        universeName: universe.name,
        universe,
        byCategory: groupBy(universe.variables, 'category.label'),
      };
    }
    return result;
  },

  exportableUniverse(state) {
    return state.entities.filter(u => u.is_exportable);
  },

  keyedByIdWithRelation(state) {
    const universes = getters.withRelation(state);
    return keyBy(universes, 'id');
  },
};
export const dmsUniverse = jsonApiVuex(
  'dms/universe',
  {
    getters,
  },
  {
    entityFilter: e => {
      e.id = parseInt(e.id);
    },
    entityDescription: {
      label: 'name',
    },
  }
);
