import Vue from 'vue';
import VueBreadcrumbs from 'vue-2-breadcrumbs';
Vue.use(VueBreadcrumbs, {
  // Modified version of the original render function provided by the library so that
  // webapp can be built without embedding the compiler and support i18n
  // https://github.com/Scrum/vue-2-breadcrumbs/blob/master/src/index.ts
  render(createElement) {
    const outputLabel = label => {
      if (Array.isArray(label)) {
        return this.$t(...label);
      }
      if (this.$te(label) || this.$te(label, this.$i18n._vm.fallbackLocale)) {
        return typeof this.$t(label) === 'object' ? label : this.$t(label);
      }
      return label;
    };
    const breadcrumb = this.$breadcrumbs.filter(r => r?.meta?.breadcrumb);
    if (breadcrumb.length) {
      return createElement(
        'ol',
        {
          class: {
            breadcrumb: true,
          },
        },
        breadcrumb.map((crumb, index) => {
          const label = this.getBreadcrumb(crumb.meta.breadcrumb);
          if (label?.length > 0) {
            return createElement(
              'li',
              {
                class: {
                  'breadcrumb-item': true,
                },
                props: {
                  key: index,
                },
              },
              [
                createElement(
                  'router-link',
                  {
                    props: {
                      to: this.$router.resolve({ name: crumb.name, params: this.$route.params }).href,
                      tag: index !== this.$breadcrumbs.length - 1 ? 'a' : 'span',
                    },
                  },
                  ` ${outputLabel(label)}`
                ),
                generateBadgeElement(crumb.meta.breadcrumbBadge, createElement),
              ]
            );
          }
          return createElement();
        })
      );
    }
    return createElement();
  },
});

const badgeTitles = {
  lab: 'interfaces are not definitive and may evolve in the future',
};

const generateBadgeElement = (badgeName, createElement) => {
  return (
    badgeName &&
    createElement(
      'span',
      {
        props: {
          tag: 'span',
        },
        attrs: {
          title: badgeTitles[badgeName],
        },
        class: `${badgeName} badge`,
      },
      badgeName
    )
  );
};
