import { store } from '@/store';
import { router } from '~version/router';

// For the day we need to store a token client side
//const ID_TOKEN_KEY = 'CA_JWT';
let jwt;
let apiHostname;
let platformHostname;
let v7Hostname;
let v4Hostname;
let jwtExpiration;
let staticHostname;
let siteId;

/**
 * Get the JWT
 * @returns jwt
 */
export const getJWT = () => {
  return jwt;
};

/**
 * Remove the JWT
 */
export const removeJWT = () => {
  jwt = null;
};

/**
 * Get the JSON payload of a JWT
 * @param {String} token the token from which the payload is extracted. If not specified, use the token from headers received
 *  from setJWT
 * @returns payload
 */
export const getJWTPayload = token => {
  if (token == null) {
    token = getJWT();
  }
  const split = token.split('.');
  try {
    return JSON.parse(atob(split[1]));
  } catch (e) {
    console.error('Cannot parse token, removing it', token);
    console.error(e);
    removeJWT();
  }
};

/**
 * Set a timeout function based on the expiration date of the given token. Timeout is reset between calls of this function
 * to the expiration time of the newly given token
 * @param {String} token a JWT
 */
const handleExpiration = token => {
  if (jwtExpiration) {
    clearTimeout(jwtExpiration);
  }
  const expiration = getJWTPayload(token).exp;
  const current = Math.round(Date.now() / 1000);
  const timeout = (expiration - current) * 1000;
  if (timeout <= 0) {
    //On page load if token is already expired, we can't use the vuex store as it's
    // not yet available because of cyclic dependency
    console.warn('JWT expired');
    removeJWT();
  } else {
    jwtExpiration = setTimeout(() => {
      store.dispatch('currentUser/timeout');
    }, timeout);
  }
};

if (getJWT()) {
  handleExpiration(getJWT());
}

/**
 * Set the JWT
 */
export const setJWT = async token => {
  jwt = token;
  handleExpiration(token);
};

/**
 * Get the site id from what was previously set with setSiteId. If not, it will fallback to the router params, or startOptions of the user.
 * This is the preffered way to getId in the application because while the information is in the URL, the route params are not
 * updated fast enough for our use. Incidentally this also mean that if the site id change, setSiteId MUST be used.
 * @returns Site ID
 */
export const getSiteId = () => {
  if (siteId === null) return;
  return siteId || router?.app?.$route?.params.siteId || store.getters['currentUser/startOptions'].siteId || '';
};

/**
 * Set the site id. MUST be called when the site id change.
 * @param {String} id
 */
export const setSiteId = id => {
  //Set from beforeEach router guard, update ASAP the siteId as the getSiteId
  //may be called during guards before the route params and URL are updated
  siteId = id;
};

/**
 * Below is just getterst and setters for different URL, so that they cah be changed depending on the environment
 * API: Laravel API, api-internal.commander1.com
 * Platform: Symfony, with menu platform.commandersact.com
 * v7: Symfony, without menu to be included as iframe  v7content.commandersact.com
 * Static: static content from the static folder, on the CDN that was used for v7, staticplatform.commandersact.com
 */

export const setApiHostname = api => {
  apiHostname = api;
};

export const setApiHostnameWithSiteId = (apiHostname, siteId) => {
  setApiHostname(apiHostname);
  setSiteId(siteId);
};

export const getApiHostname = () => apiHostname;

export const getPlatformHostname = () => {
  return platformHostname;
};

export const setPlatformHostname = platform => {
  platformHostname = platform;
};

export const getV7Hostname = () => {
  return v7Hostname;
};

export const setV7Hostname = platform => {
  v7Hostname = platform;
};

export const getV4Hostname = () => {
  return v4Hostname;
};

export const getV4Url = ({ section, page }) => {
  const host = getV4Hostname();
  const siteId = getSiteId();
  return `${host}?s=${siteId}&a=superusers&a2=${section}&a3=${page}&l=en&c=-1&gs=-1`;
};

export const setV4Hostname = host => {
  v4Hostname = host;
};

export const getStaticHostname = () => {
  return staticHostname;
};

export const setStaticHostname = value => {
  staticHostname = value;
};

export const getAppVersion = () => 'X';
