import Transient from '@/components/layouts/transient';
import NavTab from '@/components/layouts/nav-tab';
import SymfonyFrame from '@/components/layouts/symfony-frame';
import { store } from '@/store';
import { getBasicText } from '@/common/text/text';
import { axiosApi } from '@/models';

const editTab = (tab, meta = {}) => {
  meta.titleI18n = `destination.${tab}.title`;
  meta.breadcrumb = () => ({ label: store.state.integration.integration.entity.label, parent: 'destination-overview' });
  meta.parent = 'destination-overview';
  return {
    path: tab,
    name: `destination-${tab}`,
    props: route => {
      return {
        integrationId: route.params.id,
        tab,
      };
    },
    component: () => import('@/views/integration/destination/edit.vue'),
    meta,
  };
};

export const destination = {
  path: 'destinations',
  name: 'destinations',
  component: Transient,
  meta: {
    breadcrumb: null,
  },
  children: [
    {
      path: '/',
      name: 'destination-overview',
      component: () =>
        import(/* webpackChunkName: "destinations-overview" */ '@/views/integration/integrations-overview.vue'),
      props: {
        overviewType: 'destination',
      },
      meta: {
        breadcrumb: 'destination.title',
        can: 'view.TmsTag|view.DmsStream',
      },
    },
    {
      path: 'create/',
      name: 'destination-catalog',
      props: {
        catalogType: 'destination',
      },
      component: () => import(/* webpackChunkName: "destination-creation" */ '@/views/integration/catalog.vue'),
      meta: {
        titleI18n: 'destination.catalog.title',
        breadcrumb: { label: 'common.create', parent: 'destination-overview' },
        can: 'create.TmsTag|create.DmsStream',
      },
    },
    {
      path: 'create/',
      meta: {
        breadcrumb: null,
      },
      component: NavTab,
      children: [
        {
          path: ':id(\\d+)/description',
          name: 'destination-description',
          component: () => import('@/views/integration/integration-description.vue'),
          props(route) {
            const props = { ...route.params };
            props.id = Number(props.id);
            props.step = 'description';
            props.integrationType = 'destination';
            return props;
          },
          meta: {
            async metaFn() {
              return {
                breadcrumb: { label: store.state.integration.template.entity.label, parent: 'destination-catalog' },
              };
            },
          },
          async beforeEnter(routeTo, routeFrom, next) {
            await store.dispatch('integration/template/loadCurrent', {
              id: routeTo.params.id,
              include: 'categories',
            });
            try {
              await getBasicText(`destinations/${store.state.integration.template.entity.template}/description`);
            } catch (fetchError) {
              if (fetchError.response.status === 404) {
                next({ name: 'destination-wizard', params: { integrationTemplateId: routeTo.params.id } });
                return;
              }
            }
            next();
          },
        },
        {
          path: ':integrationTemplateId(\\d+)/wizard',
          name: 'destination-wizard',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "destination-creation" */ '@/views/integration/destination/creation-wizard.vue'
            ),
          async beforeEnter(to, from, next) {
            await store.dispatch('integration/template/loadCurrent', {
              id: to.params.integrationTemplateId,
              include: 'categories',
            });
            next();
          },
          meta: {
            titleI18n: 'destination.settings.title',
            breadcrumb: () => ({ label: store.state.integration.template.entity.label, parent: 'destination-catalog' }),
          },
        },
      ],
    },
    {
      path: 'builder',
      name: 'destination-builder',
      component: Transient,
      meta: {
        breadcrumb: null,
      },
      children: [
        {
          path: '/',
          name: 'destination-builder-overview',
          component: () =>
            import(
              /* webpackChunkName: "destinations-builder-overview" */ '@/views/integration/destination/builder-overview.vue'
            ),
          props: {},
          meta: {
            breadcrumb: { label: 'destination.builder.overview.destinationTemplates' },
          },
        },

        {
          path: 'create/',
          name: 'destination-builder-create',
          component: () =>
            import(
              /* webpackChunkName: "destinations-builder-create-js-sandbox" */ '@/views/integration/destination/builder-create.vue'
            ),
          props: {},
          meta: {
            can: 'create.DestinationTemplateBuilder',
            breadcrumb: { label: 'common.create', parent: 'destination-builder-overview' },
          },
        },

        {
          path: 'create/js-sandbox',
          name: 'destination-builder-create-js-sandbox',
          component: () =>
            import(
              /* webpackChunkName: "destinations-builder-create-js-sandbox" */ '@/views/integration/destination/builder-create-js-sandbox.vue'
            ),
          async beforeEnter(to, from, next) {
            store.commit(`destinationBuilderTemplate/_setCurrentEntity`, {
              template_form: '',
              template_js_sandbox: '',
            });
            next();
          },
          props: {},
          meta: {
            can: 'create.DestinationTemplateBuilder',
            breadcrumb: { label: 'destination.builder.create.jsSandbox.title', parent: 'destination-builder-create' },
          },
        },

        {
          path: 'js-sandbox/:templateId/edit',
          name: 'destination-builder-edit-js-sandbox',
          component: () =>
            import(
              /* webpackChunkName: "destinations-builder-create" */ '@/views/integration/destination/builder-create-js-sandbox.vue'
            ),
          async beforeEnter(to, from, next) {
            await store.dispatch('destinationBuilderTemplate/loadCurrent', to.params.templateId);
            next();
          },
          props: {
            create: false,
            model: 'destinationBuilderTemplate', // draft connectors are editable
          },
          meta: {
            can: 'create.DestinationTemplateBuilder',
            breadcrumb: () => ({
              label: store.state.destinationBuilderTemplate.entity.label,
              parent: 'destination-builder-overview',
            }),
          },
        },
        {
          name: 'destination-builder-view-js-sandbox',
          path: 'js-sandbox/:templateId/view',
          component: () =>
            import(
              /* webpackChunkName: "destinations-builder-create" */ '@/views/integration/destination/builder-create-js-sandbox.vue'
            ),
          async beforeEnter(to, from, next) {
            await store.dispatch('destinationBuilderTemplate/loadCurrent', to.params.templateId);
            next();
          },
          props: {
            create: false,
            model: 'destinationBuilderTemplate', // connectors from the draft table
            readonly: true,
          },
          meta: {
            breadcrumb: () => ({
              label: store.state.destinationBuilderTemplate.entity.label,
              parent: 'destination-builder-overview',
            }),
          },
        },
        {
          name: 'destination-builder-published-js-sandbox',
          path: 'js-sandbox/:templateId/published',
          component: () =>
            import(
              /* webpackChunkName: "destinations-builder-create" */ '@/views/integration/destination/builder-create-js-sandbox.vue'
            ),
          async beforeEnter(to, from, next) {
            await store.dispatch('destinationTemplate/loadCurrent', to.params.templateId);
            if (!store.state.destinationTemplate.entity.template_ref_id) {
              store.commit('destinationTemplate/_reset');
              // TODO: check if we need to redirect to destination-builder-overview
              next({ name: 'error', params: { error: 'unauthorizedAccess' }, replace: false });
            }

            next();
          },
          props: {
            create: false,
            model: 'destinationTemplate', // published connectors (on dms_connectors table) are not editable,
            readonly: true,
          },
          meta: {
            breadcrumb: () => ({
              label: store.state.destinationTemplate.entity.label,
              parent: 'destination-builder-overview',
            }),
          },
        },
      ],
    },
    {
      path: 'duplicate/',
      meta: {
        breadcrumb: null,
      },
      component: NavTab,
      children: [
        {
          path: ':integrationId(\\d+)',
          name: 'destination-duplicate',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "destination-creation" */ '@/views/integration/destination/creation-wizard.vue'
            ),
          async beforeEnter(to, from, next) {
            await store.dispatch('integration/integration/loadCurrent', {
              id: to.params.integrationId,
              include:
                'health,connector,connector.categories,universe,environment,storage_connector,segments,stream_columns,stream_columns.variable,sources,sources.connector,sources.environment,sources.model,sources.destinations,sources.destinations.connector',
            });
            next();
          },
          meta: {
            titleI18n: 'destination.settings.title',
            breadcrumb: () => ({
              label: store.state.integration.integration.entity.label,
              parent: 'destination-overview',
            }),
          },
        },
      ],
    },
    {
      path: ':id(\\d+)',
      async beforeEnter(to, from, next) {
        await store.dispatch('integration/integration/loadCurrent', {
          id: to.params.id,
          include:
            'health,connector,connector.categories,universe,updater,creator,environment,storage_connector,segments,stream_columns,stream_columns.variable,stream_columns.modifiers,sources,sources.connector,sources.environment,sources.model,sources.destinations,sources.destinations.connector,alerts',
        });
        store.dispatch('normalizedDatalayer/eventPropertyName/findAllCached');
        next();
      },
      meta: {
        breadcrumb: null,
        cacheSession: 'destination-edit',
      },
      component: NavTab,
      children: [
        editTab('settings'),
        editTab('input', {
          metaFn() {
            let titleI18n;
            let navTabHidden = false;
            switch (store.state.integration.integration.entity.connector.trigger_type) {
              case 'audience':
              case 'raw-data':
                titleI18n = 'destination.audience';
                break;
              case 'event':
                titleI18n = 'destination.dataSource';
                break;
              default:
                navTabHidden = true;
                break;
            }
            return { titleI18n, navTabHidden };
          },
        }),
        editTab('filters', {
          metaFn() {
            let navTabHidden;
            switch (store.state.integration.integration.entity.connector.trigger_type) {
              case 'event':
                navTabHidden = false;
                break;
              case 'audience':
              case 'raw-data':
              default:
                navTabHidden = true;
                break;
            }
            return { navTabHidden };
          },
        }),
        editTab('activation'),
        {
          path: 'delivery',
          name: 'destination-delivery',
          component: () =>
            import(/* webpackChunkName: "destination-delivery" */ '@/views/integration/destination/delivery.vue'),
          props: true,
          meta: {
            metaFn() {
              return {
                navTabHidden: store.state.integration.integration.entity.connector.has_delivery_report !== true,
              };
            },
            titleI18n: 'destination.delivery.title',
            breadcrumb: () => ({
              label: store.state.integration.integration.entity.label,
              parent: 'destination-overview',
            }),
          },
        },
        {
          path: 'inspector',
          name: 'destination-inspector',
          component: SymfonyFrame,
          meta: {
            titleI18n: 'destination.inspector.title',
            breadcrumb: () => ({
              label: store.state.integration.integration.entity.label,
              parent: 'destination-overview',
            }),
            async metaFn(route, to) {
              let symfonyPath = '';
              try {
                const {
                  data: { data: discover },
                } = await axiosApi.get('destination-inspectors', {});
                symfonyPath = `/dashboard/load/${discover.id}/stream_id:${to.params.id},@type:stream_hit/app/discover#/`;
              } catch (error) {
                console.warn('API Warning', error);
              }
              return {
                navTabHidden: store.state.integration.integration.entity.connector.has_event_inspector !== true,
                symfonyPath,
              };
            },
          },
          props: false,
        },
        {
          path: 'performance',
          name: 'performance',
          component: () =>
            import(/* webpackChunkName: "destination-delivery" */ '@/views/integration/destination/performance.vue'),
          props: true,
          meta: {
            metaFn() {
              return {
                navTabHidden:
                  store.state.integration.integration.entity.connector.label !== 'Facebook - Conversions API',
              };
            },
            tabBadge: 'lab',
            titleI18n: 'destination.emq-performance.title',
            breadcrumb: () => ({
              label: store.state.integration.integration.entity.label,
              parent: 'destination-overview',
            }),
          },
        },
      ],
    },
    {
      path: 'campaign/',
      name: 'campaign',
      component: Transient,
      meta: {
        breadcrumb: null,
      },
      children: [
        {
          path: 'exports/',
          name: 'exports',
          meta: {
            symfonyPath: '/campaign/export/report',
          },
          component: SymfonyFrame,
        },
        {
          path: 'exports-raw/',
          name: 'exports-raw',
          meta: {
            symfonyPath: '/campaign/export/raw',
          },
          component: SymfonyFrame,
        },
      ],
    },
  ],
};
