import { jsonApi } from '../jsonApi';

jsonApi.define('fuse/fuse_key', {
  priority: '',
  validate: '',
  exclude: '',
  variable: {
    jsonApi: 'hasOne',
    type: 'dms/variable',
  },
});
