import { jsonApi } from '../jsonApi';

jsonApi.define('normalized-datalayer/event-property-name', {
  id: null,
  name: '',
  label: '',
  description: '',
  type: '',
  datalayer_id: null,
  standard_id: null,
  categories: [],
});
