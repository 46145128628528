import { jsonApi } from '../jsonApi';
import { template } from './template';

jsonApi.define('integration-template', {
  ...template,
  categories: {
    jsonApi: 'hasMany',
    type: 'integration-category',
  },
});
