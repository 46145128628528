import { jsonApi } from './jsonApi';

jsonApi.define('user', {
  first_name: '',
  last_name: '',
  email: '',
  last_visit: '',
  role_name: '',
  company: '',
  position: '',
  current_site_id: '',
  language: '',
  password_expired: false,
  can_deploy_template: false,
  back_office_access_right: false,
  idp: '',
  type: '',
  permissions: {
    jsonApi: 'hasMany',
    type: 'permission',
  },
  startOptions: {
    jsonApi: 'hasOne',
    type: 'start-option',
  },
  sites: {
    jsonApi: 'hasMany',
    type: 'site',
  },
});
