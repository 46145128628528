import { jsonApi } from '../jsonApi';

jsonApi.define('dms/universe', {
  name: '',
  type_es: '',
  is_exportable: '',
  is_segmentable: '',
  is_manageable: '',
  reconciliate: '',
  order: '',
  conditions: [],
  variables: {
    jsonApi: 'hasMany',
    type: 'dms/variable',
  },
  parent: {
    jsonApi: 'hasOne',
    type: 'dms/universe',
  },
  base: {
    jsonApi: 'hasOne',
    type: 'dms/universe',
  },
});
