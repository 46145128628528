import { jsonApi } from '@/models';
import { idGenerator } from '@/common/utilities/id-generator';

import {
  keyBy,
  upperFirst,
  clone,
  mapValues,
  defaults,
  defaultsDeep,
  isArray,
  some,
  pick,
  isEqual,
  isEmpty,
  forEach,
  cloneDeep,
  camelCase,
  isString,
} from 'lodash';
import { deepSet } from '@/common/utilities/deep-set';
import Vue from 'vue';

const idIterator = idGenerator('save');

const EXPIRE_TIME_MS = 180000;

//A list of Vuex module
const moduleList = [];

export const getModuleList = () => [...moduleList];

const cacheFlushFn = [];

export const flushCache = () => {
  while (cacheFlushFn.length) {
    cacheFlushFn.pop()();
  }
};

/**
 * Helper function that generates mutations for properties the current entity
 * @param {String} key property of an entity
 */
const curryEntitySetter = key => (state, value) => {
  state.entity[key] = value;
  state.isEntityDirty = true;
};

/**
 * Helper function that generates mutations for deep properties the current entity
 * @param {String} key path to the deep property of the entity
 */
const curryDeepEntitySetter = key => (state, param) => {
  const path = [key, ...(isString(param.path) ? param.path.split('.') : param.path)];
  deepSet(state.entity, path, param.value);
  state.isEntityDirty = true;
};

/**
 *
 * @param {String} key Nested path
 * @returns
 */
const curryNestedSetter = key => (state, value) => {
  state.entity[key] = value;
};

/**
 *
 * @param {String} model The JSONAPI type
 * @param {Object={}} moduleMerge Standard vuex module which will be merged with aht the function generate
 * @param {Object={}} options Options
 * @returns
 */
export const jsonApiVuex = function(model, moduleMerge = {}, options = {}) {
  //Populate the vuex module based on JSONAPI type. Since it's conventation based it may not be perfect hence the `moduleName` options
  moduleList.push(
    options.moduleName ||
      model
        .split('/')
        .map(camelCase)
        .join('/')
  );
  //default options merging
  const { idKey, defaultFindAllParams, findAllParamsFilter, entityFilter, nested } = defaultsDeep(options, {
    idKey: 'id',
    entityDescription: {
      label: 'label',
    },
    defaultFindAllParams: {},
    findAllParamsFilter: (params = {}) => {
      if (params.filter) {
        params.filter = mapValues(params.filter, array => (isArray(array) ? array.join(',') : array));
      }
      if (params.include) {
        params.include = isArray(params.include) ? params.include.join(',') : params.include;
      }
      return params;
    },
    entityFilter: e => e,
    nested: {},
    hooks: {},
  });
  //init of the current entity
  const emptyEntity = {
    [idKey]: null,
  };
  //set the id mutations for the current entity
  const entityMutations = {
    [`set${upperFirst(idKey)}`]: (state, value) => {
      if (state.entities[value] && value !== state.entity[idKey]) {
        throw new Error(`${state.servicePath} set${upperFirst(idKey)}: Can't set
         id ${value} to current entity as an existing entity already has that id`);
      }
      curryEntitySetter(idKey)(state, value);
      state.currentEntityId = value;
    },
  };

  const apiErrorToEntityError = errors => {
    const result = {};
    forEach(errors, (error, key) => {
      //note that modelFor is a private function, it may not worh with updates of devour client
      // but basically we get every properties set for a JSONAPI type from devour
      if (key.split('.')[0] in jsonApi.modelFor(model).attributes) {
        result[key] = error.detail.join('\n');
      }
    });
    return result;
  };
  //now, a mutation per property, as well as a deepset
  for (const attribute of Object.keys(jsonApi.modelFor(model).attributes)) {
    emptyEntity[attribute] = null;
    entityMutations[`set${upperFirst(attribute)}`] = curryEntitySetter(attribute);
    entityMutations[`deepSet${upperFirst(attribute)}`] = curryDeepEntitySetter(attribute);
  }
  //nested are for composite path like /entity/id/nestednStuff
  for (const nested of Object.keys(nested)) {
    emptyEntity[nested] = {};
    entityMutations[`set${upperFirst(nested)}`] = curryNestedSetter(nested);
  }

  //finally the state is created
  Object.freeze(emptyEntity);
  const originalState = {
    ...moduleMerge.state,
    entities: [],
    entity: clone(emptyEntity),
    keyedById: {},
    modelName: model,
    currentEntityId: null,
    entityError: clone(emptyEntity),
    isEntityDirty: false,
    isEntitySubmitted: false,
    loading: {
      list: 0,
      create: {},
      read: {},
      update: {},
      delete: {},
    },
    pagination: {
      count: 0,
      current_page: 0,
      per_page: 0,
      total: 0,
      total_pages: 0,
      ids: [],
    },
    requestAll: defaultsDeep({}, defaultFindAllParams, {
      page: {
        number: 1,
        size: 10,
      },
      filter: {},
      include: {},
      sort: {
        by: options.entityDescription.label,
        desc: false,
      },
    }),
    findAllCache: {},
    entityDescription: {
      ...options.entityDescription,
      nested,
      idKey,
    },
  };
  //and the vuex module. for the mutations/actions use, check the readme
  const vuexModule = {
    state: cloneDeep(originalState),
    mutations: {
      ...moduleMerge.mutations,
      ...entityMutations,
      _setEntities(state, entities) {
        entities.forEach(entityFilter);
        state.entities = entities;
        state.keyedById = keyBy(entities, idKey);
      },
      _clearEntities(state) {
        vuexModule.mutations._clearCurrentEntity(state);
        state.entities = [];
        state.keyedById = {};
      },
      _clearCurrentEntity(state) {
        Object.assign(state.entity, emptyEntity);
        state.isEntityDirty = false;
        state.isEntitySubmitted = false;
        state.currentEntityId = null;
      },
      _addEntity(state, entity) {
        entityFilter(entity);
        const id = entity[idKey];
        if (id != null) {
          if (state.keyedById[id]) {
            Object.assign(state.keyedById[id], entity);
          } else {
            entity = Vue.observable(entity);
            state.entities.push(entity);
            state.keyedById[id] = entity;
          }
        } else {
          console.error(`${state.servicePath} _addEntity: Can't add entity without entity key: ${idKey}`, entity);
          throw new Error(`${state.servicePath} _addEntity: Can't add entity without entity key`);
        }
      },
      _addNested(state, { id, key, nested }) {
        state.keyedById[id][key] = nested;
        if (state.currentEntityId === id) {
          state.entity[key] = nested;
        }
      },
      _deepSetAttributeById(state, { id, path, value }) {
        deepSet(state.keyedById[id], path, value);
      },
      _removeEntity(state, entity) {
        entityFilter(entity);
        vuexModule.mutations._removeEntityById(state, entity[idKey]);
      },
      _removeEntityById(state, id) {
        const entity = state.keyedById[id];
        const index = state.entities.indexOf(entity);

        if (index === -1) {
          throw new Error(`${state.servicePath} _removeEntityById: Can't remove unknown entity with id: ${id}`);
        }
        delete state.keyedById[id];
        state.entities.splice(index, 1);
        if (state.currentEntityId === id) {
          vuexModule.mutations._clearCurrentEntity(state);
        }
        for (const cache of Object.values(state.findAllCache)) {
          const index = cache.entities.indexOf(entity);
          if (index !== -1) {
            cache.entities.splice(index, 1);
          }
        }
      },
      _setCurrentEntity(state, entity) {
        entityFilter(entity);
        vuexModule.mutations._clearCurrentEntity(state);
        vuexModule.mutations._clearEntityError(state);
        const id = entity[idKey];
        if (id != null) {
          try {
            vuexModule.mutations._switchCurrentEntityById(state, id);
          } catch (e) {
            vuexModule.mutations._addEntity(state, entity);
          }
          Object.assign(state.entity, entity);
          state.currentEntityId = id;
        } else {
          vuexModule.mutations._clearCurrentEntity(state);
          state.currentEntityId = null;
          Object.assign(state.entity, entity);
        }
      },
      _switchCurrentEntityById(state, id) {
        vuexModule.mutations._clearCurrentEntity(state);
        vuexModule.mutations._clearEntityError(state);
        if (!state.keyedById[id]) {
          throw new Error(
            `${state.servicePath} _setCurrentEntity: Can't switch current entity to unknown entity with id: ${id}`
          );
        }
        Object.assign(state.entity, state.keyedById[id]);
        state.currentEntityId = id;
      },
      _setEntityError(state, errors) {
        state.entityError = mapValues(state.entityError, () => false);
        for (const [key, value] of Object.entries(errors)) {
          deepSet(state.entityError, key.split('.'), value);
        }
        Object.assign(state.entityError, errors);
      },
      _setPropertyError(state, error) {
        const path = isString(error.path) ? error.path.split('.') : error.path;
        deepSet(state.entityError, path, error.value);
      },
      _clearEntityError(state, attribute) {
        if (attribute) {
          const path = isString(attribute) ? attribute.split('.') : attribute;
          deepSet(state.entityError, path, null);
        } else {
          Object.assign(state.entityError, emptyEntity);
        }
      },
      _setIsEntityDirty(state, value) {
        state.isEntityDirty = value;
      },
      _setIsEntitySubmitted(state, value) {
        state.isEntitySubmitted = value;
      },
      _updatePagination(state, response) {
        if (response.meta && response.meta.pagination) {
          Object.assign(state.pagination, response.meta.pagination);
          const ids = response.data.map(entity => entity[idKey]);
          state.pagination.ids = ids;
        }
      },
      _startLoading(state, { key, id, entity = {} }) {
        if (key === 'list') {
          state.loading.list++;
          return;
        }
        Vue.set(state.loading[key], entity[idKey] || id, entity || true);
      },
      _endLoading(state, { key, id, entity = {} }) {
        if (key === 'list') {
          state.loading.list--;
          return;
        }
        if (state.loading[key]) {
          Vue.delete(state.loading[key], entity[idKey] || id);
        }
      },
      _updateRequestAll(state, params) {
        deepSet(state.requestAll, params.path, params.value);
      },
      _setRequestAllFilter(state, filter) {
        state.requestAll.filter = filter;
      },
      _reset(state) {
        Object.assign(state, cloneDeep(originalState));
      },
      _addFindAllCache(state, { key, value }) {
        Vue.set(state.findAllCache, key, value);
      },
      _cleanAllFindAllCache(state) {
        for (const key of Object.keys(state.findAllCache)) {
          vuexModule.mutations._cleanFindAllCache(state, key);
        }
      },
      _cleanFindAllCache(state, key) {
        Vue.delete(state.findAllCache, key);
      },
      _invalidAllFindAllCache(state) {
        for (const key of Object.keys(state.findAllCache)) {
          vuexModule.mutations._invalidFindAllCache(state, key);
        }
      },
      _invalidFindAllCache(state, key) {
        const cache = state.findAllCache[key];
        if (cache) {
          cache.status = 'invalid';
        }
      },
      _updateFindAllCache(state, { key, timestamp, entities, status, subscribers, subscriber, promise, error = null }) {
        const cache = state.findAllCache[key];
        if (cache) {
          Object.assign(
            cache,
            timestamp && { timestamp },
            promise && { promise },
            status && { status },
            subscribers && { subscribers },
            { error }
          );
          if (entities) {
            while (cache.entities.length) {
              cache.entities.pop();
            }
            while (entities.length) {
              cache.entities.unshift(entities.pop());
            }
          }
          if (subscriber) {
            cache.subscribers.push(subscriber);
          }
        }
      },
    },
    getters: {
      ...moduleMerge.getters,
      isLoadingByType(state) {
        const map = mapValues(pick(state.loading, ['create', 'read', 'update', 'delete']), v => !isEmpty(v));
        return {
          list: state.loading.list > 0,
          ...map,
        };
      },
      isLoading(state) {
        return some(vuexModule.getters.isLoadingByType(state), Boolean);
      },
      entityDescription(state) {
        return state.entityDescription;
      },
      paginated(state) {
        return state.pagination.ids.map(id => state.keyedById[id]);
      },
      requestAll(state) {
        return state.requestAll;
      },
      pagination(state) {
        return state.pagination;
      },
      isCurrentEntityValid(state) {
        const deepEvery = collection => {
          return collection.every(v => {
            if (typeof v !== 'object' || v == null) {
              return !v;
            }
            return deepEvery(Object.values(v));
          });
        };
        return deepEvery(Object.values(state.entityError));
      },
    },
    actions: {
      ...moduleMerge.actions,
      async findAll(context, params) {
        const { commit, state } = context;
        const loading = { key: 'list' };
        try {
          commit('_startLoading', loading);
          let finalParams = {};
          defaults(finalParams, params, defaultFindAllParams);
          finalParams = findAllParamsFilter(finalParams);
          const result = await jsonApi.findAll(state.modelName, finalParams);
          result.data.forEach(entity => commit('_addEntity', entity));
          commit('_updatePagination', result);
          options.hooks?.findAll?.post(context, result);
          return result;
        } finally {
          commit('_endLoading', loading);
        }
      },

      findAllCached({ dispatch, state, commit }, params) {
        const cacheKey = JSON.stringify(params) ?? 'noParams';
        let findAllPromise;
        let cache = state.findAllCache[cacheKey];
        //Get cache
        if (cache) {
          if (cache.timestamp < Date.now() - EXPIRE_TIME_MS) {
            cache.status = 'invalid';
          }
        } else {
          cache = Vue.observable({
            timestamp: null,
            entities: [],
            subscribers: [],
            promise: null,
            error: null,
            params: cloneDeep(params),
            status: 'invalid',
          });
          cacheFlushFn.push(() => {
            commit('_cleanFindAllCache', cacheKey);
          });
          commit('_addFindAllCache', { key: cacheKey, value: cache });
        }
        if (cache.status === 'invalid') {
          //Request
          findAllPromise = dispatch('findAll', params)
            .then(findAllResult => {
              const entities = findAllResult.data.map(entity => state.keyedById[entity[idKey]]);
              for (const subscriber of cache.subscribers) {
                subscriber.resolve(cache.entities);
              }
              commit('_updateFindAllCache', {
                key: cacheKey,
                status: 'success',
                entities,
                subscribers: [],
              });
            })
            .catch(error => {
              for (const subscriber of cache.subscribers) {
                subscriber.reject(cache.error);
              }
              commit('_updateFindAllCache', {
                key: cacheKey,
                status: 'error',
                subscribers: [],
                error,
              });
            });
          commit('_updateFindAllCache', {
            key: cacheKey,
            timestamp: Date.now(),
            status: 'pending',
            promise: findAllPromise,
          });
        }
        //Prepare returned promise
        return new Promise((resolve, reject) => {
          switch (cache.status) {
            case 'pending':
              commit('_updateFindAllCache', {
                key: cacheKey,
                subscriber: { resolve, reject },
              });
              break;
            case 'success':
              resolve(cache.entities);
              break;
            case 'error':
              reject(cache.error);
          }
        });
      },
      async requestAll({ dispatch, state }) {
        return dispatch('findAll', state.requestAll);
      },

      async load({ commit, state }, params) {
        let id;
        if (params instanceof Object) {
          id = params.id;
          params = { ...params };
          delete params.id;
        } else {
          id = params;
          params = {};
        }
        const loading = { key: 'read', id };
        try {
          commit('_startLoading', loading);
          const result = await jsonApi.find(state.modelName, id, params);
          commit('_addEntity', result.data);
          return result.data;
        } finally {
          commit('_endLoading', loading);
        }
      },
      async loadNested({ commit, state, dispatch }, { id, nested, params = {} }) {
        const loading = { key: 'read', id: id + nested };
        try {
          commit('_startLoading', loading);
          let entity = state.keyedById[id];
          if (!entity) {
            entity = await dispatch('load', id);
          }
          const result = await jsonApi
            .one(state.modelName, id)
            .all(state.entityDescription.nested[nested])
            .get(params);
          commit('_addNested', { id, key: nested, nested: result.data });
          return result.data;
        } finally {
          commit('_endLoading', loading);
        }
      },
      async loadAllNested({ dispatch, state }, { id, params = {} }) {
        for (const nested of Object.key(state.entityDescription.nested)) {
          await dispatch('loadNested', { id, nested, params });
        }
      },
      async loadCurrent({ commit, dispatch }, id) {
        const result = await dispatch('load', id);
        commit('_setCurrentEntity', result);
      },
      async save({ state, commit }, entity) {
        let result;
        const requestId = idIterator.next().value;
        let mode;
        try {
          if (entity[idKey] != null) {
            mode = 'update';
            commit('_startLoading', { key: 'update', entity });
            result = await jsonApi.update(state.modelName, entity);
          } else {
            const isStillLoading = some(state.loading.create, value => {
              return isEqual(value, entity);
            });
            if (!isStillLoading) {
              mode = 'create';
              commit('_startLoading', { key: 'create', id: requestId, entity: entity });
              result = await jsonApi.create(state.modelName, entity);
            }
          }
          commit('_addEntity', result.data);
        } finally {
          commit('_endLoading', { key: mode, id: requestId, entity });
        }
        return result.data;
      },
      async saveCurrent({ state, dispatch, commit }) {
        try {
          const result = await dispatch('save', state.entity);
          commit('_setCurrentEntity', result);
          return result;
        } catch (e) {
          commit('_clearEntityError');
          commit('_setEntityError', apiErrorToEntityError(e));
          throw e;
        } finally {
          commit('_setIsEntitySubmitted', true);
        }
      },
      async delete({ state, commit }, id) {
        const loading = { key: 'delete', id };
        try {
          commit('_startLoading', loading);
          await jsonApi.destroy(state.modelName, id);
          commit('_removeEntityById', id);
        } finally {
          commit('_endLoading', loading);
        }
      },
      async deleteCurrent({ state, dispatch }) {
        await dispatch('delete', state.currentEntityId);
      },
      setCurrentEntity({ commit }, entity) {
        commit('_clearCurrentEntity');
        commit('_setCurrentEntity', entity);
      },
      clearCurrentEntity({ commit }) {
        commit('_clearCurrentEntity');
      },
    },
    namespaced: true,
  };
  return vuexModule;
};
