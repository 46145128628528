import { jsonApi } from './jsonApi';

jsonApi.define('customer', {
  short_reference_customer: '',
  reference_customer: '',
  label_customer: '',
  date_create: '',
  flag_active: '',
  url_logo: '',
  contact: '',
  job: '',
  address: '',
  zip_code: '',
  city: '',
  country: '',
  tva: '',
  sites: {
    jsonApi: 'hasMany',
    type: 'site',
  },
});
