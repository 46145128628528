<template>
  <b-input-group :class="{ 'tc-search-input': true, 'tc-search-input-prepend': position === 'prepend' }">
    <b-form-input
      :id="$attrs.id + '-input'"
      size=""
      :placeholder="placeholder || $t('common.searchPlaceholder')"
      ref="form_input"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <b-input-group-text :slot="position">
      <span class="bx bx-search-alt" />
    </b-input-group-text>
  </b-input-group>
</template>
<script>
import { BInputGroup } from 'bootstrap-vue';
import { BFormInput } from 'bootstrap-vue';
export default {
  components: { BInputGroup, BFormInput },
  props: {
    position: {
      type: String,
      default: () => 'prepend',
    },
    placeholder: String,
  },
  methods: {
    focus() {
      this.$refs.form_input.$el.focus();
    },
  },
};
</script>
