import { ability } from '@/common/security/ability';
import { store } from '@/store';

/**
 * metaAuthorization is a function which given an authorization object, returns whether the current user pass the requirement. Requirement are combined with a OR by default, and AND within an authorization array
 * @param {Object} param Needed authorization
 * @param {String} param.can List the rights needed. Check th readme or the code for format.
 * @param {Boolean} param.staffOnly Limit the access to staff
 * @param {String} param.siteParameterAccess Require a site parameter to access
 * @param {Array<Authorization>=[]} param.authorization A list of authorization which will be combined with the `authorizationLogicalOperator`. Each element will have the metaAuthorization function called on them thus the structure is recursive.
 * @param {String='AND'} param.authorizationLogicalOperator How authorization are combined within the array
 * @returns Whether current user is authorized
 */
export const metaAuthorization = ({
  can,
  staffOnly,
  siteParametersAccess,
  authorization = [],
  authorizationLogicalOperator = 'AND',
}) => {
  let hasNoRestriction = true;
  let isCanAuthorized = false;
  let isStaffAuthorized = false;
  let isSiteParametersAuthorized = false;
  let isSubAuthorized = false;
  if (authorization.length) {
    hasNoRestriction = false;
    if (authorizationLogicalOperator === 'OR') {
      isSubAuthorized = authorization.some(a => metaAuthorization(a));
    }
    if (authorizationLogicalOperator === 'AND') {
      isSubAuthorized = authorization.every(a => metaAuthorization(a));
    }
  }

  if (can) {
    hasNoRestriction = false;
    //Separators are:
    // `|` -> For or.
    // `,` -> For and. As there is no support for brackets for operation order
    //     all members should be considered as being in a group.
    // '.' -> Separate action and subject
    // Example: create.Stream, update.Stream | all.Data
    const or = can
      .split('|')
      .map(andMember => andMember.split(',').map(permission => permission.split('.').map(s => s.trim())));
    isCanAuthorized = or.some(andMember => {
      return andMember.every(([action, subject]) => {
        const result = ability.can(action, subject);
        return result;
      });
    });
  }
  if (staffOnly) {
    hasNoRestriction = false;
    isStaffAuthorized = store.getters['currentUser/isStaff'];
  }
  if (siteParametersAccess) {
    hasNoRestriction = false;
    isSiteParametersAuthorized = Boolean(store.getters['currentUser/currentSiteParameters'][siteParametersAccess]);
  }

  return hasNoRestriction || isSubAuthorized || isCanAuthorized || isStaffAuthorized || isSiteParametersAuthorized;
};
