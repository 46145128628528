import { jsonApi } from '../jsonApi';
import { template } from './template';

jsonApi.define('destination-template', {
  ...template,
  template_ref_id: null,
  template_ref_site_id: null,
  categories: {
    jsonApi: 'hasMany',
    type: 'destination-category',
  },
});
