<script>
import { i18n, getLanguages } from '../i18n';

//import simplebar from 'simplebar-vue';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { upperCase, upperFirst } from 'lodash';
import { eventBus } from 'shared/event-bus';
import TcSearchInput from '@/components/forms/tc-search-input';

/**
 * Nav-bar Component
 */
export default {
  components: {
    TcSearchInput,
  },
  filters: {
    upperCase,
    upperFirst,
  },
  data() {
    return {
      avatarUrl: '',
      languages: getLanguages(),
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      siteSearchTerm: '',
      fuzzySearch: null,
    };
  },
  //components: { simplebar },
  computed: {
    ...mapState('currentUser', ['first_name', 'last_name', 'email']),
    ...mapGetters('currentUser', ['allowedSites', 'currentSite', 'allowedSitesByCustomer']),
    filteredSites() {
      const result = this.allowedSites.filter(
        site => site.name.match(new RegExp(this.siteSearchTerm, 'i')) || site.id.match(new RegExp(this.siteSearchTerm))
      );
      return this.siteSearchTerm ? result : this.allowedSites;
    },
    filteredSitesByCustomer() {
      const result = this.allowedSitesByCustomer
        .map(customer => {
          const filtered = customer.sites.filter(site => {
            return (
              site.name.match(new RegExp(this.siteSearchTerm, 'i')) || site.id.match(new RegExp(this.siteSearchTerm))
            );
          });
          const cust = {
            id: customer.id,
            label: customer.label,
            sites: filtered,
          };
          return cust;
        })
        .filter(filtered => filtered.sites.length > 0);
      return this.siteSearchTerm ? result : this.allowedSitesByCustomer;
    },
    nameInitials() {
      const firstNameInitial = this.first_name?.[0] ?? '';
      const lastNameInitial = this.last_name?.[0] ?? '';

      if (!firstNameInitial && !lastNameInitial) {
        return this.email?.slice(0, 2) ?? '';
      }

      return `${firstNameInitial}${lastNameInitial}`;
    },
  },
  mounted() {
    const value = this.languages.find(x => x.language === this.$store.state.currentUser.language) || this.languages[0];
    this.text = value.title;
    this.flag = value.flag;
  },
  methods: {
    ...mapMutations('layout', ['toggleSidebar']),
    toggleMenu() {
      this.toggleSidebar();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable');
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    selectFirstSite() {
      if (this.filteredSitesByCustomer.length) {
        this.changeSite(this.filteredSitesByCustomer[0].sites[0].id);
        this.siteSearchTerm = '';
        this.$refs.siteDropdown.hide();
      }
    },
    changeSite(siteId) {
      eventBus.$emit('site-id-changed', siteId);
      const splitted = this.$route.path.split('/');
      splitted[2] = siteId;
      const path = splitted.slice(0, 4).join('/');
      if (this.$router.resolve({ path })?.resolved?.name !== 'standard-error') {
        this.$router.push({ path, params: { ...this.$route.params } }).catch(() => {});
        return;
      }
      this.$router.push({ path: splitted.slice(0, 3).join('/'), params: { ...this.$route.params } });
    },
    clearSearchInput() {
      this.siteSearchTerm = '';
    },
    focusInput() {
      this.$refs.siteSearch.focus();
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" :to="{ name: 'homepage' }" class="logo logo-dark">
            <span class="logo-sm">
              <!-- <img src="@/assets/images/logo-icon.svg" alt height="36" /> -->
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-full-dark.svg" alt height="17" />
            </span>
          </router-link>

          <router-link tag="a" :to="{ name: 'homepage' }" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-icon.svg" alt height="36" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-full-light.svg" alt height="46" />
            </span>
          </router-link>
        </div>

        <button id="vertical-menu-btn" type="button" class="btn btn-sm font-size-16 header-item" @click="toggleMenu">
          <i class="bx bx-dock-left"></i>
        </button>

        <b-dropdown
          variant="white"
          toggle-class="header-item"
          ref="siteDropdown"
          :text="currentSite.name"
          @shown="
            clearSearchInput();
            focusInput();
          "
        >
          <template v-slot:button-content> {{ currentSite.name }}<i class="bx bx-chevron-down"></i> </template>
          <b-dropdown-form @submit.stop.prevent>
            <tc-search-input
              id="site-picker"
              ref="siteSearch"
              v-model="siteSearchTerm"
              @keyup.enter="selectFirstSite"
              autocomplete="off"
            />
          </b-dropdown-form>
          <div class="scrollable">
            <b-dropdown-group
              v-for="customer in filteredSitesByCustomer"
              :key="customer.id"
              :id="customer.id"
              :header="customer.label"
              class="customer-block"
            >
              <b-dropdown-item-button
                class="notify-item"
                v-for="site in customer.sites"
                :key="site.id"
                :value="site.name"
                @click="changeSite(site.id)"
                :disabled="site.id === currentSite.id"
              >
                <span class="align-middle" :class="{ 'selected-site': site.id === currentSite.id }">{{
                  site.name
                }}</span>
              </b-dropdown-item-button>
            </b-dropdown-group>
          </div>
        </b-dropdown>
        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" :placeholder="$t('navbar.search.text')" />
            <span class="bx bx-search-alt"></span>
          </div>
        </form> -->

        <!-- <b-dropdown
          variant="black"
          class="dropdown-mega d-none d-lg-block ml-2"
          toggle-class="header-item"
          menu-class="dropdown-megamenu"
        >
          <template v-slot:button-content>
             {{ $t('navbar.dropdown.megamenu.text') }}
            <i class="mdi mdi-chevron-down"></i>
          </template>

          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.uicontent.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.lightbox') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.rangeslider') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.sweetalert') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.rating') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.forms') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.tables') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.charts') }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.application.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.ecommerce') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.calendar') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.email') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.projects') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.tasks') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.contacts') }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.extrapages.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        $t('navbar.dropdown.megamenu.extrapages.list.lightsidebar')
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t('navbar.dropdown.megamenu.extrapages.list.compactsidebar')
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t('navbar.dropdown.megamenu.extrapages.list.horizontallayout')
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.maintenance') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.comingsoon') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.timeline') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.faqs') }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.uicontent.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.lightbox') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.rangeslider') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.sweetalert') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.rating') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.forms') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.tables') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.charts') }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img src="@/assets/images/megamenu-img.png" alt class="img-fluid mx-auto d-block" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-dropdown> -->
      </div>

      <div class="d-flex">
        <!-- <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div> -->

        <b-dropdown right menu-class="dropdown-menu-lg p-0" toggle-class="header-item noti-icon" variant="black">
          <template v-slot:button-content>
            <i class="bx bx-bell"></i>
            <!--<span class="badge badge-danger badge-pill">1</span>-->
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t('navbar.dropdown.noNotification.text') }}
                </h6>
              </div>
              <!--<div class="col-auto">
                <a href="#" class="small">{{ $t('navbar.dropdown.notification.subtext') }}</a>
              </div> !-->
            </div>
          </div>
          <!--<simplebar style="max-height: 230px">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t('navbar.dropdown.notification.order.title') }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t('navbar.dropdown.notification.order.text') }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.order.time') }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t('navbar.dropdown.notification.james.title') }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t('navbar.dropdown.notification.james.text') }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.james.time') }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t('navbar.dropdown.notification.item.title') }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t('navbar.dropdown.notification.item.text') }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.item.time') }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t('navbar.dropdown.notification.salena.title') }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t('navbar.dropdown.notification.salena.text') }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.salena.time') }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-down-circle mr-1"></i>
              {{ $t('navbar.dropdown.notification.button') }}
            </a>
          </div>-->
        </b-dropdown>

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <div class="d-flex justify-content-center align-items-center">
              <div class="d-flex justify-content-center align-items-center rounded-circle header-profile-user mr-2">
                <img v-if="avatarUrl" :src="avatarUrl" :alt="$t('navbar.userMenu.avatar')" />
                <span v-else :title="$t('navbar.userMenu.avatar')">
                  {{ nameInitials | upperCase }}
                </span>
              </div>

              <span>{{ first_name | upperFirst }}</span>
              <i class="mdi mdi-chevron-down"></i>
            </div>
          </template>
          <!-- item-->
          <b-dropdown-item>
            <router-link tag="span" :to="{ name: 'current-user-account-settings' }">
              <i class="bx bx-user font-size-16 align-middle  mr-1"></i>
              {{ $t('navbar.userMenu.profile') }}
            </router-link>
          </b-dropdown-item>
          <!--
          <b-dropdown-item class="d-block" href="javascript: void(0);">
            <span class="badge badge-success float-right">11</span>
            <i class="bx bx-wrench font-size-16 align-middle mr-1"></i>
            {{ $t('navbar.userMenu.settings') }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider> -->
          <b-dropdown-item
            class="d-block text-danger"
            @click="$store.dispatch('currentUser/logOut')"
            href="javascript: void(0);"
          >
            <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            {{ $t('navbar.userMenu.logOut') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
<style lang="scss" scoped>
@import '@/versions/x/styles/variables.scss';
.scrollable {
  max-height: 500px;
  overflow-y: auto;
}

.dropdown-item {
  padding-left: 4px;
}
.customer-block {
  .dropdown-header {
    font-weight: bold;
  }
  .list-unstyled {
    span {
      padding-left: 4px;
    }
  }
}
.selected-site {
  color: $commandersact;
}
</style>
