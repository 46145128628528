import { jsonApi } from '@/models';
import { ability } from '@/common/security/ability';
import { permissionMapping } from 'shared/authorization/permission-mapping';
import { i18n } from '@/i18n';
import { axiosPlatform } from '@/models';
import { setJWT, removeJWT, getJWT, getSiteId, getApiHostname, getV7Hostname } from '~version/models/headers-handler';
import { router } from '~version/router';
import { cloneDeep, omit, isEmpty } from 'lodash';
import { eventBus } from 'shared/event-bus';
import { getAppVersion } from '~version/models/headers-handler';

import urlJoin from 'url-join';

/**
 * current user has all the method pertaining to the currently logged user and the lifecycle of his sesseion on the webapp
 * that is authentication, authorization
 */

// TODO: open feature to everyone
const SITE_WITH_CONTROL_GROUP = [
  7, // local, ref
  13, // demo
  29, // demo
  2300, // demo
  3440, // local, QA
  5014, // ID Group
  3565, // EF
];

const getOriginalState = () => {
  return cloneDeep({
    first_name: null,
    last_name: null,
    email: null,
    last_visit: null,
    role_name: null,
    language: null,
    startOptions: {
      default_site_id: null,
      default_site_group_id: null,
      default_language_id: null,
      currency: null,
      csv_separator: null,
    },
    sites: [],
    sitesById: {},
    hasSession: false, //Whether we currently have a session
    initialized: false, //User nas been initialized with a /me
    timeout: false, //If we have no session, whether if it's due to timeout.
    can_deploy_template: false,
    back_office_access_right: false,
    idp: '',
    siteParameters: {}, //For backward compatibility with v7 only; use the currentSiteParameters getter
    version: null,
    permissions: [],
  });
};

export const state = getOriginalState();

export const mutations = {
  setUser(state, user) {
    Object.assign(state, user);
    state.sitesById = {};
    for (const site of user.sites) {
      state.sitesById[site.id] = site;
    }
    state.initialized = true;
  },
  removeUser(state) {
    Object.assign(state, getOriginalState());
  },
  setTimeout(state, value) {
    state.timeout = value;
  },
  setHasSession(state, value) {
    state.hasSession = value;
  },
  updateSite(state, site) {
    const toBeUpdated = state.sitesById[site.id];
    if (!toBeUpdated) {
      throw new Error('currentUser - Updating site which was not associated to the user', site.id);
    }
    Object.assign(toBeUpdated, site);
  },
  setSiteParameters(state, value) {
    state.siteParameters = value;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
};

const mappedToAbility = (permissions = []) => {
  return permissions.map(({ id }) => permissionMapping[id]).filter(Boolean);
};

export const getters = {
  allowedSitesByCustomer(state) {
    const sitesByCustomer = [];
    state.sites?.forEach(site => {
      if (!site.customers) {
        site.customers = [{ id: '0', label_customer: 'Others' }];
      }
      const currentCustomer = site.customers[0];
      let customer = sitesByCustomer.find(s => s.id === currentCustomer.id);
      if (!customer) {
        customer = {
          id: currentCustomer.id,
          label: currentCustomer.label_customer,
          sites: [],
        };
        customer.sites = customer.sites ?? [];
        customer.sites.push(site);
        sitesByCustomer.push(customer);
      } else {
        customer.sites = customer.sites ?? [];
        customer.sites.push(site);
      }
    });
    return sitesByCustomer.sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
  },
  allowedSites(state) {
    return state.sites;
  },
  currentSite(state, getters, rootState) {
    //getSiteId will be faster to be updated than the routeParams
    //However the following expression is needed so as to keep this getters reactive
    //On site id change
    rootState.route.params.siteId;
    return state.sitesById[getSiteId() || rootState.route.params.siteId] ?? {};
  },
  isStaff(state) {
    return /@(tagcommander|commandersact)\.com$/.test(state.email);
  },
  hasBackOfficeAccessRight(state) {
    return state.back_office_access_right;
  },
  hasDeployTemplateRight(state) {
    return state.can_deploy_template;
  },
  startOptions(state) {
    return {
      siteId:
        state?.startOptions && state?.startOptions.default_site_id !== -1
          ? state.startOptions.default_site_id
          : state.sites[0]?.id,
      locale: state.language || 'en',
    };
  },
  currentSiteParameters(state, getters) {
    const result = { ...state.siteParameters };
    for (const siteParameter of getters.currentSite.siteParameters ?? []) {
      result[siteParameter.key] = siteParameter.value;
    }
    return result;
  },
  hasControlGroup(state, getters) {
    const isSiteWhitelisted = SITE_WITH_CONTROL_GROUP.includes(parseInt(getters.currentSite.id));
    const isSiteParameterEnabled = [true, 'true', '1', 1].includes(
      getters.currentSiteParameters.dms_has_campaign_performance
    );
    return isSiteParameterEnabled || isSiteWhitelisted;
  },
  //For debbuging rights. As it relies on the currentSiteGetters instead of the URL param, it's not as instant at the getSiteId function
  currentSitePermissions(state) {
    const result = {};
    for (const permission of state.permissions) {
      result[permission.id] = permission;
    }
    return result;
  },
  //For debbuging rights.
  currentSitePermissionsMappedToAbility(state, getters) {
    const result = {};
    for (const permissionId of Object.keys(getters.currentSitePermissions)) {
      if (permissionMapping[permissionId]) result[permissionId] = permissionMapping[permissionId];
    }
    return result;
  },
};

export const actions = {
  /**
   * First
   * Authenticate the user. This send a request to Symfony in order to
   * open a v7 session for seamless navigation between the 2 versions.
   * We are not authenticated to Laravel yet.
   */
  async logIn({ dispatch, commit }, { username, password } = {}) {
    let isLogged = false;
    try {
      const loginData = new URLSearchParams();
      loginData.append('_login', username);
      loginData.append('_password', password);
      loginData.append('_redirection_url', '');
      await axiosPlatform({
        method: 'post',
        url: `/crossroad/login_check`,
        data: loginData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      isLogged = await dispatch('getJWT');
    } catch (e) {
      console.error(e);
      if (e.code === 400) {
        throw new Error('Login failed');
      } else if (e.response.data.error === 'banned') {
        throw new Error(e.response.data.error);
      } else if (e.response.data.error === 'SSO_AUTH_REQUIRED') {
        window.location.href = urlJoin(getV7Hostname(), `/saml/${e.response.data.sp}`);
      } else {
        throw new Error('Error during login', e);
      }
    }
    if (isLogged) {
      commit('setTimeout', false);
      commit('setHasSession', true);
      const user = await dispatch('init');
      return user;
    }
  },

  async forgot(_, { email } = {}) {
    let result;
    try {
      const forgotData = new URLSearchParams();
      forgotData.append('_login', email);

      result = await axiosPlatform({
        method: 'post',
        url: `/crossroad/forgot`,
        data: forgotData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
    } catch (e) {
      console.error(e);
      if (e.response.data.error === 'SSO_AUTH_REQUIRED') {
        window.location.href = urlJoin(getV7Hostname(), `/saml/${e.response.data.sp}`);
      }
      return false;
    }

    return result;
  },

  /**
   * Second
   * Get the token from Symfony so that the webapp can communicate with Laravel.
   * @returns whether it successfuly retrieved the token
   */
  async getJWT() {
    let response;
    try {
      response = await axiosPlatform({
        method: 'get',
        url: `/token`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
    } catch (e) {
      if (e.response?.status === 401) {
        // Redirect to 2FA
        router.push({ name: 'two-factor' }).catch(() => {});
        return false;
      } else if (e.response?.data?.error === 'SSO_AUTH_REQUIRED') {
        window.location.href = urlJoin(getV7Hostname(), `/saml/${e.response.data.sp}`);
        return false;
      }
    }

    setJWT(response.data?.token);
    return true;
  },

  async logOut({ commit }) {
    // In case user is connected by SSO we use V7 logout
    if (!isEmpty(state.idp)) {
      const logoutUrl = urlJoin(getV7Hostname(), `/logout`);
      removeJWT();
      commit('removeUser');
      window.location.replace(logoutUrl);
      return;
    }

    const platformPromise = axiosPlatform({
      method: 'get',
      url: `/logout`,
    });

    //TODO JWT revoke
    //const apiPromise = axiosApi.delete(`oauth/tokens/${getJWTPayload().jti}`);
    try {
      await Promise.all([platformPromise /*, apiPromise*/]);
    } catch (e) {
      if (e.response.status !== 403) {
        throw e;
      }
      // Logout redirects to an unauthorized page hence the 403
      removeJWT();
      commit('removeUser');
      router.push({ name: 'login' });
      return;
    }
    console.error('Logout failed');
  },
  // register({ commit, dispatch, getters }, { email, password } = {}) { },
  // resetPassword({ commit, dispatch, getters }, { email } = {}) { },

  /**
   * Check for the Symfony session if it's open. It's usually called from the router for each navigation.
   * The site id is sent each time if available so as to figure if there is additional step required like 2FA
   * The token also has the list of site which requires 2FA and whether the user has been authenticated with 2FA
   * @returns true if good, a string with the reason if there is a denial (2FA) or an error (Forbidden)
   */

  async checkPlatformSession({ dispatch, state, commit }) {
    const loginData = new URLSearchParams();
    loginData.append('site', getSiteId());
    try {
      const { data } = await axiosPlatform({
        method: 'post',
        url: `crossroad/check_session_valid`,
        data: loginData,
      });
      if (state.version == null && data.version) {
        state.version = data.version;
        // eslint-disable-next-line no-console
        console.log(`Commanders Act version ${data.version} loaded`);
      }
      if (state.version && state.version !== data.version) {
        // eslint-disable-next-line no-console
        console.log('New Commanders Act version detected', data.version);
        eventBus.$emit('new-webapp-version');
      }
    } catch (e) {
      if (e.response?.status === 401) {
        // Redirect to 2FA
        router.push({ name: 'two-factor', params: { site: getSiteId() } }).catch(() => {});
        return '2FA';
      } else if (e.response?.status === 403 && e.response.data.includes('>AccessDeniedException<')) {
        return 'Forbidden';
      } else if (e.response?.status === 503) {
        // FIXME: this 503 should not happen
        return 'Forbidden';
      }

      if (state.initialized) {
        dispatch('timeout');
      }
      commit('setHasSession', false);
      return false;
    }
    if (!getJWT()) {
      //We have a session but no token: Login from v7.
      await dispatch('getJWT');
    }
    await dispatch('init');
    commit('setHasSession', true);
    return true;
  },

  /**
   * 2FA sends request to v7. Retrieves a new JWT at the end which indicates that 2FA has been cleared
   * @returns
   */
  async check2FACode({ dispatch, commit }, { token } = {}) {
    const codeData = new URLSearchParams();
    codeData.append('token', token);
    try {
      await axiosPlatform({
        method: 'post',
        url: `/crossroad/two_factor/code`,
        data: codeData,
      });
    } catch (e) {
      if (e.response.data.error) {
        commit('notifications/pushDanger', e.response.data.error, { root: true });
        return e.response.data.code;
      }
      return false;
    }

    // load new token
    await dispatch('getJWT');

    commit('setTimeout', false);
    commit('setHasSession', true);
    return true;
  },

  /**
   * Request to v7 to send a 2FA code to the user
   * @returns server response or false
   */
  async generate2FACode() {
    let result;
    try {
      result = await axiosPlatform({
        method: 'get',
        url: `/crossroad/two_factor?action=resend`,
      });
    } catch (e) {
      console.error('currentUser - Generate 2FA fail', e);
      return false;
    }

    return result;
  },

  /**
   * When there is a timeout of session or JWT, this function should be called
   */
  timeout({ commit }) {
    removeJWT();
    commit('setTimeout', true);
  },

  /**
   * Utility method which init the route of the user to his/her default language and site id
   * @param {Object=sitePrefix} a route object
   */
  async navigateToSitePrefix(
    { getters, dispatch },
    route = {
      name: 'homepage',
    }
  ) {
    if (typeof route === 'string') {
      route = { path: route };
    }
    await dispatch('getJWT');
    await dispatch('init');

    if (getters.allowedSites.length === 0) {
      route.name = 'error-access-denied-platform';
      route.params = {
        error: 'notActivated',
      };
    } else {
      route.params = {
        siteId: getters.startOptions.siteId,
        locale: getters.startOptions.locale,
      };
    }
    router.push(route);
  },

  /**
   * Fourth
   * Request Laravel for the rights the user have for a given site.
   * It also initialize CASL with the rights
   * For the right mapping do check the vue/shared/authorization/permission-mapping.js file
   * @param {*} siteId
   * @returns
   */

  async loadSite({ commit, state }, siteId) {
    siteId = siteId || getSiteId();
    if (!siteId) return;
    if (!state.sitesById[siteId]) {
      throw new Error('No site access');
    }
    const url = `/me`;
    const filter = { sites: siteId };

    try {
      const promises = [
        jsonApi.findAll('site-params'),
        jsonApi.findAll('site-modules'),
        jsonApi.request(url, 'GET', {
          include: 'permissions,sites.site-parameters',
          filter,
        }),
      ];
      const [siteParams, siteModules, { data: user }] = await Promise.all(promises);
      user.sites[0].initialized = true;
      user.sites[0].siteParams = siteParams.data?.reduce((acc, s) => ({ ...acc, ...omit(s, ['id', 'type']) }), {});
      user.sites[0].siteModules = siteModules.data?.reduce((acc, s) => ({ ...acc, ...omit(s, ['id', 'type']) }), {});
      commit('updateSite', user.sites[0]);
      commit('setPermissions', user.permissions);
      ability.update(mappedToAbility(user.permissions));
      return state;
    } catch (e) {
      console.error('currentUser - Load site fail', e);
      if (e[0]?.detail !== 'Site not found.') {
        commit('notifications/pushDanger', i18n.t('errors.currentUserRetrieval'), { root: true });
      }
    }
  },
  /**
   * Third
   * Request Laravel for user information, configuration and a liste of site which he/she has access to
   * @returns the state
   */
  async loadUser({ commit }) {
    const url = urlJoin(getApiHostname(), '/v2/me');

    try {
      const { data: user } = await jsonApi.request(url, 'GET', {
        include: 'sites,start-options,sites.customers',
        ...(getAppVersion() === 'X' && { filter: { access_platform_x: true } }),
      });
      commit('setUser', user);
      return state;
    } catch (e) {
      if (e[0]?.detail !== 'Site not found.') {
        commit('notifications/pushDanger', i18n.t('errors.currentUserRetrieval'), { root: true });
      }
    }
  },
  /**
   * Init the store with the current user
   * @returns state
   */
  async init({ state, dispatch }) {
    if (state.initialized) {
      return state;
    }

    await dispatch('loadUser');
    return state;
  },
};

export const namespaced = true;
